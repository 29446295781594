import React from "react";
import ReactDOM from 'react-dom';
import { Modal, Button } from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
//component import
import * as inputFun from '../common-components/FormInputFunctions';
import { Form } from 'react-bootstrap';

export default function AddEduTrainingInfoPopup(props) {
    const [LocationAisActive, SetLocationAState] = React.useState(false);
    let instituteData =  [{"value":"Aachen University","label":"Aachen University"},
                          {"value":"Aalborg University","label":"Aalborg University"},
                          {"value":"Aarhus Business School","label":"Aarhus Business School"},
                          {"value":"Aarhus School of Business","label":"Aarhus School of Business"},
                          {"value":"Aarhus Universitet","label":"Aarhus Universitet"},
                          {"value":"Abadan Institute of Technology","label":"Abadan Institute of Technology"},
                          {"value":"Aberdeen College","label":"Aberdeen College"},
                          {"value":"Aberdeen University","label":"Aberdeen University"},
                          {"value":"Aberdeen University of Scotland","label":"Aberdeen University of Scotland"},
                          {"value":"Abilene Christian University","label":"Abilene Christian University"},
                          {"value":"B. E. College","label":"B. E. College"},
                          {"value":"B. M. S. College of Engineerin","label":"B. M. S. College of Engineerin"},
                          {"value":"B.J. Medical Colleg","label":"B.J. Medical Colleg"},
                          {"value":"B.V.B Engineering College , Hubli, Indi","label":"B.V.B Engineering College , Hubli, Indi"},
                          {"value":"B.V.M. India","label":"B.V.M. India"},
                          {"value":"Babasaheb Bhimrao Ambedkar Universit","label":"Babasaheb Bhimrao Ambedkar Universit"},
                          {"value":"Babson College","label":"Babson College"},
                          {"value":"Babson College School of Managemen","label":"Babson College School of Managemen"},
                          {"value":"Bajaj Institute of Management Studie","label":"Bajaj Institute of Management Studie"},
                          {"value":"Baker College","label":"Baker College"},
                        ];
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
          Add Education/Training Information
        </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row">
              <div className="col-sm-12">
                <div class="form-group app-ctrl ">
                  <label class="required">Institution Attended </label>
                  <div className="app-autofill-select">
                    <CreatableSelect isClearable options={instituteData} placeholder="Select Institution"/>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div class="form-group app-ctrl ">
                  <label class="">Last Year Attended/Graduated: </label>
                  <select class="form-control" >
                      <option value="0">Select Year</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                      <option value="1999">1999</option>
                      <option value="1998">1998</option>
                      <option value="1997">1997</option>
                      <option value="1996">1996</option>
                      <option value="1995">1995</option>
                      <option value="1994">1994</option>
                      <option value="1993">1993</option>
                      <option value="1992">1992</option>
                      <option value="1991">1991</option>
                      <option value="1990">1990</option>
                      <option value="1989">1989</option>
                      <option value="1988">1988</option>
                      <option value="1987">1987</option>
                      <option value="1986">1986</option>
                      <option value="1985">1985</option>
                      <option value="1984">1984</option>
                      <option value="1983">1983</option>
                      <option value="1982">1982</option>
                      <option value="1981">1981</option>
                      <option value="1980">1980</option>
                      <option value="1979">1979</option>
                      <option value="1978">1978</option>
                      <option value="1977">1977</option>
                      <option value="1976">1976</option>
                      <option value="1975">1975</option>
                      <option value="1974">1974</option>
                      <option value="1973">1973</option>
                      <option value="1972">1972</option>
                      <option value="1971">1971</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-12">
                <div class="form-group app-ctrl ">
                  <label class="">Field of Study/Degree: </label>
                  <input type="text" className="form-control" />
                  <div className="ctrl-note">
                    <span>DDS, PHD, Orthodontics, General Dentistry</span>
                  </div> 
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button type="button" className="btn app-btn lightgray-btn large-btn mr-2" onClick={props.onHide}>cancel</button>
              <button type="button" className="btn app-btn large-btn ">Save</button>
            </div>

          </form>
        </Modal.Body>

      </Modal>
    </div>
  );
}


