import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

// component imports
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import AddLocationModel from "../models/AddLocationModel";
//images import
import NoteEditIcon from "../assets/images/noteEdit-icon-black.svg";
import HIPPAlogo from "../assets/images/HIPAA-logo.png";
import TopNavBar from "../common-components/TopNavbar";

export default function HighlevelPage() {
  const [AddLocationModelShow, SetAddLocationModel] = React.useState(false);

  const [hilevelIsActive, SethilevelState] = React.useState(false);

  const [SyncPatients, SetSyncPatients] = React.useState(false);
  const [SyncAppointments, SetSyncAppointments] = React.useState(false);
  const [ApikeyEditable, SetApikeyEditable] = React.useState(false);

  function toggleApikeyEditable() {
    SetApikeyEditable(ApikeyEditable === false ? true : false);
  }
  return (
    <div className="main-wrapper">
      <AppSidebarNavbar activeTabsIntegration={true} />
      <main>
        <div className="app-container">
          <TopNavBar />
          <div className="white-container ">
            <div className="page-info">
              <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link exact to="/setting/integration">
                      Integrations{" "}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    highlevel
                  </li>
                </ol>
              </nav>
            </div>

            <div className="app-content">
              <div className="row app-box-wrapper">
                <div className="box-col col-lg-12 col-xl-6">
                  <div
                    className={`app-box h-auto gohighlevel-box p-0 ${
                      hilevelIsActive ? "" : "content-hidden"
                    }`}
                  >
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <h6 className="mb-0">GoHighLevel Integration</h6>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className="app-switch position-static"
                        defaultChecked={hilevelIsActive}
                        onChange={(e) => {
                          SethilevelState(e.target.checked);
                        }}
                      />
                    </div>
                    <div className="card-content">
                      <div className="card-body">
                        <h4>API Key</h4>
                        <div className="apikey-box form-group">
                          <input
                            type="text"
                            defaultValue="d2c09992-75a5-47ee-9fec-d6675712612e"
                            className="form-control"
                            disabled={ApikeyEditable ? "" : "disabled"}
                          />
                          <button
                            className="app-btn app-btn-clear sec-action-btn"
                            onClick={toggleApikeyEditable}
                            title="Edit"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17">
                              <path
                                fill="#707070"
                                d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="card-footer d-flex flex-wrap">
                        <Form.Check
                          type="switch"
                          id="custom-switch1"
                          className="app-switch position-static with-label mb-1"
                          defaultChecked={SyncPatients}
                          label="Sync Patients"
                          onChange={(e) => {
                            SetSyncPatients(e.target.checked);
                          }}
                        />
                        <Form.Check
                          type="switch"
                          id="custom-switch2"
                          className="app-switch position-static with-label mb-1"
                          defaultChecked={SyncAppointments}
                          label="Sync Appointments"
                          onChange={(e) => {
                            SetSyncAppointments(e.target.checked);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row app-box-wrapper mt-2">
                <div className="box-col col-xs-12 col-sm-12 col-lg-12 col-xl-9">
                  <div className="app-box info-box">
                    <div className="left-box">
                      <h2 className="info-title">Pay for an expert! </h2>
                      <p>
                        Need a little assistance with installing the integration tool? No problem!
                        We have experts who can save your time, and get you up and going quickly!
                      </p>
                    </div>
                    <div className="right-box">
                      <div className="amt-info">
                        <img src={NoteEditIcon} alt="Icon" />
                        <strong>$149</strong>
                        <span>One time</span>
                      </div>
                      <div className="btn-box">
                        <button className="btn app-btn small-btn text-uppercase">Learn More</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-col col-xs-12 col-sm-12 col-lg-12 col-xl-3">
                  <div className="hippa-img-box d-flex align-items-center justify-content-center">
                    <img src={HIPPAlogo} alt="HIPPA Logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <AddLocationModel show={AddLocationModelShow} onHide={() => SetAddLocationModel(false)} />
    </div>
  );
}
