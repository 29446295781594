import React, { useState } from "react";

import { validateForm } from "../config/commonFun";
import Api from "../API/login";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { ProductName, publicUrl } from "../config/appConstatnts";

export default function SignInPage() {
  const redirectToSignUp = () => {
    window.location.href = "/signup";
  };
  const [eyeTog2, seteyeTog2] = useState("false");

  const handleToggleEye = () => {
    textBoxType === "password" ? setTextBoxType("text") : setTextBoxType("password");
    seteyeTog2(!eyeTog2);
  };

  const [userModel, setUserModel] = useState({
    Username: "",
    Password: "",
  });

  const [errors, setErrors] = useState({
    Username: "",
    Password: "",
  });

  const [textBoxType, setTextBoxType] = useState("password");
  const [loader, setLoader] = useState(false);
  const [resMessage, setResMessage] = useState("");

  const handelChange = (event) => {
    let { name, value } = event.target;
    let errorValue = "";

    if (name != "Password") {
      value = value.trim();
    }
    // Set updated value to userModel
    setUserModel((userModel) => ({
      ...userModel,
      [name]: value,
    }));

    switch (name) {
      case "Username":
        if (value.length === 0) {
          errorValue = "Username or Email is required.";
        }
        break;
      case "Password":
        if (value.length === 0) {
          errorValue = "Password is required.";
        }
        break;
      default:
        break;
    }

    setErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;

    // Check validation
    if (userModel.Username.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Username: "Username or Email is required.",
      }));
      flagError = true;
    }
    if (userModel.Password.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Password: "Password is required.",
      }));
      flagError = true;
    }

    // Login User
    if (validateForm(errors) && !flagError) {
      setLoader(true);
      const data = {
        ...userModel,
        CompanyId: localStorage.getItem("CompanyId"),
      };
      Api.logIn(data)
        .then((res) => {
          setLoader(false);
          localStorage.setItem("token", res.data.Result.Token);
          localStorage.setItem("userData", JSON.stringify(res.data.Result));
          //const expireDate = new Date((new Date().getTime()) + 5 * 5 * 60 * 1000);
          //localStorage.setItem("SessionTimeout", expireDate)
          window.location.href = "/setting/integration";
        })
        .catch((error) => {
          setLoader(false);
          setResMessage(error.response.data.Message);
        });
    }
  };

  const [apiData, setApiData] = useState(null);
  React.useEffect(() => {
    const data =
      localStorage.getItem("CompanyDetails") !== undefined &&
      JSON.parse(localStorage.getItem("CompanyDetails")) !== null
        ? JSON.parse(localStorage.getItem("CompanyDetails"))
        : ProductName;
    setApiData(data);
  }, []);

  return (
    <section className="common-section vh-100">
      <div className="container-fluid h-100">
        <div className="main-block align-items-center justify-content-center h-100 d-flex">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>Sign in</h1>
                      <p>Welcome back! Enter your credentials to proceed </p>
                    </div>
                    <form onSubmit={handelSubmit}>
                      <div className="content">
                        <h6 className="err-message main-err-message">{resMessage}</h6>

                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="username"
                            placeholder="Username/Email*"
                            maxLength="100"
                            name="Username"
                            value={userModel.Username}
                            onChange={handelChange}
                          />
                          <span className="input-icon1">
                            {apiData && (
                              <img
                                src={`${publicUrl}/${apiData.CompanyId}/images/envelope.svg`}
                                alt=""
                                className="img-fluid"
                              />
                            )}
                          </span>
                          {errors.Username.length > 0 && (
                            <div className="err-message">{errors.Username}</div>
                          )}
                        </div>

                        <div className="form-group">
                          <span className="input-icon1">
                            {apiData && (
                              <img
                                src={`${publicUrl}/${apiData.CompanyId}/images/padlock.svg`}
                                alt=""
                                className="img-fluid"
                              />
                            )}
                          </span>

                          <input
                            type={textBoxType}
                            className="form-control light-border pass-input"
                            id="exampleInputPassword1"
                            placeholder="Password*"
                            name="Password"
                            value={userModel.Password}
                            onChange={handelChange}
                          />
                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${!eyeTog2 ? "passVisible" : ""}`}
                              onClick={handleToggleEye}
                            ></i>
                          </span>
                          {errors.Password.length > 0 && (
                            <div className="err-message">{errors.Password}</div>
                          )}
                        </div>
                        <div className="forget-link  link-purple">
                          <Link to={"/forgot-password"} className="h5 f-600">
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="submit"
                            className={`btn btn-outline-primary blue-btn-bg ${
                              loader ? "btn-loading-state" : ""
                            }`}
                          >
                            {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                            sign in
                          </button>
                        </div>
                      </div>
                      <div className="forget-link  text-right link-purple signup-link">
                        <p>
                          Don't have Account ?
                          <Link href="#" className="h5 f-600 pl-2" onClick={redirectToSignUp}>
                            Sign Up
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  <h1>Welcome</h1>
                  <p>Enter your personal information to create account</p>
                  <div className="btn-block">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={redirectToSignUp}
                    >
                      sign up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
