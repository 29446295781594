import React from "react";
import ReactDOM from 'react-dom';
import { Modal, Button } from "react-bootstrap";
//component import
import * as inputFun from '../common-components/FormInputFunctions';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
export default function AddWebsite(props) {
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Edit Website
        </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className=" col-12 ">
              <div class="form-group app-ctrl has-value">
                  <label class="">Website Url</label>
                  <input type="text" className="form-control" defaultValue="https://drdds.com/" />
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button type="button" className="btn app-btn lightgray-btn large-btn mr-2" onClick={props.onHide}>cancel</button>
              <button type="button" className="btn app-btn large-btn ">Update</button>
            </div>

          </form>
        </Modal.Body>

      </Modal>
    </div>
  );
}


