import { useState, useEffect } from "react";

const GridTable = ({ tableDataObject }) => {
  const [tableData, setTableData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);

  useEffect(() => {
    if (tableDataObject.length > 0) {
      setTableData(tableDataObject);
      setTableColumn(Object.keys(tableDataObject[0]));
    }
  }, [tableDataObject]);

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="thead-light">
          <tr>
            {tableColumn.length > 0 &&
              tableColumn.map((columnName, key) => <th key={key}>{columnName}</th>)}
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 ? (
            tableData.map((tableRow, trKey) => (
              <tr key={`Row${trKey}`}>
                {Object.keys(tableRow).map(function (key) {
                  return <td className="chk-col">{tableRow[key]}</td>;
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                No record found!
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GridTable;
