import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TopNavBar from "../common-components/TopNavbar";

// component imports
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
//images import
// import BrandImg1 from "../assets/images/brand-logo1.png";
// import BrandImg2 from "../assets/images/brand-logo2.png";
// import BrandImg3 from "../assets/images/brand-logo3.png";
// import BrandImg4 from "../assets/images/brand-logo4.png";
// import BrandImg5 from "../assets/images/brand-logo5.png";
// import BrandImg6 from "../assets/images/brand-logo6.png";
// import BrandImg7 from "../assets/images/brand-logo7.png";
// import BrandImg8 from "../assets/images/brand-logo8.png";

import HIPPAlogo from "../assets/images/HIPAA-logo.png";
import Calendly from "../app-pages/Calendly";
import AppPageLoader from "../common-components/AppPageLoader";
import Api from "../API/integration";

export default function IntegrationLocationPage() {
  const [loader, setLoader] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [integrationError, setIntegrationError] = useState("");

  useEffect(() => {
    setLoader(true);
    setIntegrations([]);
    Api.getIntegration()
      .then((resp) => {
        setLoader(false);
        if (resp.data && resp.data.Result) {
          setIntegrations(resp.data.Result);
        }
      })
      .catch((e) => {
        setLoader(false);
        setIntegrationError(e.response?.data?.Message);
      });
  }, []);
  return (
    <div className="main-wrapper">
      <AppSidebarNavbar activeTabsIntegration={true} />
      <main>
        <div className="app-container">
          <div className="app-content">
            <TopNavBar />
            <div className="white-container position-relative">
              <div className="page-info">
                <p className="header-note">
                  Connecting to your dental practice management system is easy. Simply select your
                  system below and we will walk you through it.
                </p>
              </div>
              <h6 className="text-danger text-center m-0">{integrationError}</h6>
              <div className={`row app-box-wrapper ${integrations.length > 0 ? "pt-4" : ""}`}>
                {integrations.length > 0 &&
                  integrations.map((integration) => (
                    <div className={`box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3`}>
                      <div className="app-box h-auto ">
                        <div className="logo-box d-flex align-items-center justify-content-center">
                          <img src={encodeURI(integration.ImageURL)} alt={integration.Name} />
                        </div>
                        <div className="desc-text">
                          {integration.Description}
                          {/* Dentrix connects your practice technology into one management system */}
                        </div>
                        <div className="btn-box mt-4">
                          <Link
                            exact
                            to={"/integration/location/" + integration.Id + "/" + integration.Name}
                            className={
                              integration.IsEnable
                                ? "btn app-btn midGray-btn btn-block text-uppercase my-auto"
                                : "btn app-btn skyBlue-btn btn-block text-uppercase my-auto"
                            }
                          >
                            {integration.IsEnable ? "MANAGE CONNECTION" : "Add Connection"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="row app-box-wrapper mt-5">
                <Calendly />
                <div className="box-col col-xs-12 col-sm-12 col-lg-12 col-xl-3">
                  <div className="hippa-img-box d-flex align-items-center justify-content-center">
                    <img src={HIPPAlogo} alt="HIPPA Logo" />
                  </div>
                </div>
              </div>
              {loader && <AppPageLoader></AppPageLoader>}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
