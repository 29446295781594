import React from "react";
import { RecordLincURL } from "../config/appConstatnts";

const BackgroundImage = ({ uploadIcon, SetAddBannerModal, userInfo, deleteBackgroundImage }) => {
  return (
    <div className="inner-container">
      <div className="upload-img-sec">
        <div className="sec-header ">
          <h4 className="mr-auto">
            Background Images <span>(Maximum 4 Background Images can be uploaded)</span>
          </h4>
        </div>
        <div className="image-upload-sec">
          <div className="image-upload-inner">
            <div
              className="single-img upload-img"
              style={{ backgroundImage: `url(${uploadIcon})` }}
              onClick={() => SetAddBannerModal(true)}
            ></div>
            {userInfo.lstBanner &&
              userInfo.lstBanner.length > 0 &&
              userInfo.lstBanner.map((bann, index) => (
                <div
                  key={`lstBanner-${index}`}
                  className="single-img"
                  style={{
                    backgroundImage: `url(${RecordLincURL}/Resources/Banner/${userInfo.UserId}/${bann.Path})`,
                  }}
                >
                  <img
                    className="single-img"
                    src={`${RecordLincURL}/Resources/Banner/${userInfo.UserId}/${bann.Path}`}
                    alt="BackgroundImage"
                  />
                  <button
                    onClick={() => deleteBackgroundImage(bann.BannerId)}
                    className="close-btn btn btn-secondary"
                  ></button>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackgroundImage;
