import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Api from "../API/profile";
//component import
import { FormGroup, Label } from "reactstrap";
import { toast } from "react-toastify";
import AppPageLoader from "../common-components/AppPageLoader";

export default function AddImageGallery(props) {
  const [imgSrc, setimgSrc] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const handleInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
    const file = event.target.files[0];
    const fileNameNoSpaces = file.name.replace(/ /g, "_");
    setimgSrc(fileNameNoSpaces);
    setError("");
  };
  const onModalClick = () => {
    setError("");
    setimgSrc(null);
    setSelectedFile();
    props.onHide();
  };
  const handelSubmit = (event) => {
    event.preventDefault();
    setError("");
    const data = new FormData();

    if (selectedFile && selectedFile != null && selectedFile != "" && selectedFile != undefined) {
      setLoader(true);
      data.append("file", selectedFile);
      setLoader(true);
      Api.postImageGallery(data)
        .then((res) => {
          setLoader(false);
          onModalClick();
          toast.success("Image inserted successfully!");
          props.reload();
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.data.StatusCode === 400) {
            toast.warn(error.response.data.Result[0].Message);
          } else {
            toast.error("Something went wrong");
          }
        })
        .finally(() => setLoader(false));
    } else {
      setError("Please select image!");
    }
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        isOpen={props.isModalOpen}
        toggle={onModalClick}
        centered={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Add Gallery Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          {loader && <AppPageLoader />}
          <form onSubmit={handelSubmit}>
            <div className="row ">
              <div className=" col-12 img-upload-row">
                <FormGroup>
                  <Label for="exampleFile">Gallery Image</Label>
                  <div class="custom-file mb-md-3">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="validatedCustomFile"
                      onChange={handleInputChange}
                    />
                    <label class="custom-file-label" for="validatedCustomFile">
                      Choose file...
                    </label>
                    <span className="img-file-name">{imgSrc}</span>
                  </div>
                  {error !== "" && <span className="err-message">{error}</span>}
                  {/* <Input type="file" name="file" id="exampleFile" /> */}
                </FormGroup>
                <p className="mb-4 mt-4">
                  Preferred banner width X height : 800px X 600px and image type are .jpeg, .jpg,
                  .gif, .png
                </p>
                {/* <div class="form-group app-ctrl has-value">
                  <label class="">Video Url</label>
                  <input type="text" className="form-control" defaultValue="" onChange={inputFun.checkInputHasValue}/>
                </div> */}
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={onModalClick}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn large-btn ">
                <div className="button-inner">
                  <span className="btn-text">Upload</span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
