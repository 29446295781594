import React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../API/team-members";
import AppPageLoader from "../common-components/AppPageLoader";

const RemoveMemberModal = (props) => {
  const [loader, setLoader] = React.useState(false);

  const deleteRecord = () => {
    let payLoad = { TeamMemberId: [props.memeberid] };
    setLoader(true);
    Api.removeTeamMemer(payLoad)
      .then(() => {
        toast.success("Removed successfully!");
        props.onHide(true);
        setLoader(false);
      })
      .catch(() => {
        toast.error("Something went wrong");
        setLoader(false);
      });
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Remove Team Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className=" col-12 ">
                <h4 className="mt-3 mb-4">Are you sure to remove this team member?</h4>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={() => props.onHide(false)}
              >
                cancel
              </button>
              <button type="button" className="btn app-btn large-btn " onClick={deleteRecord}>
                Yes
              </button>
            </div>
          </form>
          {loader && <AppPageLoader className={"position-fixed"} />}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default RemoveMemberModal;
