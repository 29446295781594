import React from "react";

const SyncOpportunityLayout = ({ children }) => {
  return (
    <div className="row w-100 mx-0">
      <div className={"box-col col-12 locationInfo-col"}>
        <div className="form-group">{children}</div>
      </div>
    </div>
  );
};

export default SyncOpportunityLayout;
