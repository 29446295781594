import React from "react";
import { Modal, Button } from "react-bootstrap";

//component import
import * as inputFun from "../common-components/FormInputFunctions";

export default function EditLocationProfileModel(props) {
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model location-profile-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            Edit Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Location Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Lorem Ipsum dummy text"
                    onChange={inputFun.checkInputHasValue}
                  />
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Street Address</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Lorem Ipsum dummy text"
                    onChange={inputFun.checkInputHasValue}
                  />
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">City</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Lorem Ipsum dummy text"
                    onChange={inputFun.checkInputHasValue}
                  />
                </div>
              </div>
              <div className=" col-sm-3 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">State</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Lorem Ipsum"
                    onChange={inputFun.checkInputHasValue}
                  />
                </div>
              </div>
              <div className=" col-sm-3 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Zip Code</label>
                  <input
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength="6"
                    className="form-control"
                    defaultValue="123456"
                    onChange={inputFun.checkInputHasValue}
                  />
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Lorem Ipsum dummy text"
                    onChange={inputFun.checkInputHasValue}
                  />
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Lorem Ipsum dummy text"
                    onChange={inputFun.checkInputHasValue}
                  />
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Fax Number</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Lorem Ipsum dummy text"
                    onChange={inputFun.checkInputHasValue}
                  />
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">NPI Number</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Lorem Ipsum dummy text"
                    onChange={inputFun.checkInputHasValue}
                  />
                </div>
              </div>
            </div>
            <div className="btn-box d-flex justify-content-center">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                delete
              </button>
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button type="button" className="btn app-btn large-btn ">
                Update
              </button>
              <button type="button" className="btn app-btn large-btn ">
                Make Primary
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
