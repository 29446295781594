import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../API/go-high-level";
import AppPageLoader from "../../common-components/AppPageLoader";
import { SaveButton } from "../OtherCrmCommonComponente/OtherCrmCommonComponents";

const WriteBackDefault = ({
  credentials,
  DoctorsOptions,
  WritebackSetting,
  appointmentRightBack,
  setwriteBackSettng,
}) => {
  const [writeBackSetting, setWriteBackSetting] = useState({
    AllowApptWriteBack: false,
    RLDoctorId: 0,
    Treatment: null,
  });
  const [writeBackSettingError, setWriteBackSettingError] = useState({
    AllowApptWriteBack: "",
    RLDoctorId: "",
    Treatment: "",
  });

  const [appPageLoader, setAppPageLoader] = useState(false);
  const handleSubmit = () => {
    let error = false;
    let writeBackErr = writeBackSettingError;
    if (writeBackSetting.RLDoctorId === 0) {
      writeBackErr.RLDoctorId = "Team Member is required";
      error = true;
    }
    if (writeBackSetting.Treatment === "0" || writeBackSetting.Treatment == null) {
      writeBackErr.Treatment = "Type of Treatment is required";
      error = true;
    }
    setWriteBackSettingError({ ...writeBackErr });
    if (!error) {
      const payLoad = {
        AccountId: credentials.accountId,
        LocationId: credentials.locationId,
        ...writeBackSetting,
        AllowApptWriteBack: true,
      };

      setAppPageLoader(true);
      Api.insertWritebackSetting(payLoad)
        .then((resp) => {
          toast.success("Successfully saved write back changes");
          setwriteBackSettng(writeBackSetting);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.Message ?? "Something went wrong in Saving write back changes"
          );
        })
        .finally(() => setAppPageLoader(false));
    }
  };
  const handleChange = (e, name) => {
    setWriteBackSetting({
      ...writeBackSetting,
      [name]:
        name === "AllowApptWriteBack"
          ? !writeBackSetting.AllowApptWriteBack
          : name === "RLDoctorId"
          ? Number(e.target.value)
          : e.target.value,
    });
    setWriteBackSettingError({ ...writeBackSettingError, [name]: "" });
  };
  useEffect(() => {
    setWriteBackSetting(WritebackSetting);
  }, [WritebackSetting]);

  return (
    <>
      {appPageLoader && <AppPageLoader className="position-fixed" />}
      {appointmentRightBack && (
        <div className="d-flex justify-content-start my-2 mx-auto">
          <div className="box-col col-xs-2 col-md-2 col-lg-2 col-xl-2 locationInfo-col">
            <div className="form-group">
              {<p className="p-0 mb-0 icon-label">Team Member</p>}
              <div className="w-100 multiselect-col-1">
                <select
                  className="custom-select"
                  name="RLDoctorId"
                  onChange={(e) => handleChange(e, e.target.name)}
                >
                  <option value={0}>Select Doctor</option>
                  {DoctorsOptions &&
                    DoctorsOptions.map((doctor, key1) => {
                      return (
                        <option
                          key={`defaultWritebackDoctor${key1}`}
                          value={doctor.value}
                          selected={doctor.value === writeBackSetting?.RLDoctorId}
                        >
                          {doctor.name}
                        </option>
                      );
                    })}
                </select>
                <div>
                  <p className="text-danger">{writeBackSettingError.RLDoctorId}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="box-col col-xs-2 col-md-2 col-lg-2 col-xl-2 locationInfo-col">
            <div className="form-group">
              <p className="p-0 mb-0 icon-label">Type of Treatment</p>
              <div className="w-100 multiselect-col-1">
                <select
                  className="custom-select"
                  name="Treatment"
                  onChange={(e) => handleChange(e, e.target.name)}
                >
                  <option value={0} selected={writeBackSetting?.Treatment === null}>
                    Select Treatment
                  </option>
                  <option value="Hygiene" selected={writeBackSetting?.Treatment === "Hygiene"}>
                    Hygiene
                  </option>
                  <option
                    value="Consultation"
                    selected={writeBackSetting?.Treatment === "Consultation"}
                  >
                    Consultation
                  </option>
                </select>
                <div>
                  <p className="text-danger">{writeBackSettingError.Treatment}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="box-col col-xs-2 col-md-2 col-lg-2 col-xl-2 my-auto locationInfo-col">
            <SaveButton label={"Save"} className={"my-3 py-2"} SaveBtnOnClick={handleSubmit} />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(WriteBackDefault);
