import axios from "axios";
import { API_URL, HEADER, GHL_API_URL } from "../config/appConstatnts";

class Api {
  //get MappingDetails
  static getGHLCRMMappingDetails = (data) => {
    return axios.post(API_URL + "/CRMMapping/GetCRMMappingDetails", data, HEADER);
  };

  // ================================GHL Start====================================

  static getGhlLocations = (token) => {
    return axios.get(GHL_API_URL + "/locations", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
        // Company_Id: localStorage.getItem("CompanyId"),
        // access_token: localStorage.getItem("token"),
      },
    });
  };

  // ======================================================

  static getGHLUsersList = (locationToken) => {
    return axios.get(GHL_API_URL + "/users", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${locationToken}`,
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
        // Company_Id: localStorage.getItem("CompanyId"),
        // access_token: localStorage.getItem("token"),
      },
    });
  };

  // ======================================================

  static getGHLCalendarService = (locationToken) => {
    return axios.get(GHL_API_URL + "/calendars/services", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${locationToken}`,
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
        // Company_Id: localStorage.getItem("CompanyId"),
        // access_token: localStorage.getItem("token"),
      },
    });
  };

  // ======================================================
  static getGHLPipeline = (locationToken) => {
    return axios.get(GHL_API_URL + "/pipelines/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${locationToken}`,
      },
    });
  };
  // ============================================GHL complete==================

  // Post GHL Sync Settings
  static SyncSettings = (data) => {
    return axios.post(API_URL + "/CRMMapping/SyncSetting", data, HEADER);
  };

  // Post GHL Synced Location data
  static InsertLocationMapping = (data) => {
    return axios.post(API_URL + "/CRMMapping/InsertLocationMapping", data, HEADER);
  };

  // Post GHL Synced Users data
  static InsertDoctorSync = (data) => {
    return axios.post(API_URL + "/CRMMapping/InsertDoctorSync", data, HEADER);
  };

  // Post GHL Synced Appointment data
  static InsertCalenderOpSync = (data) => {
    return axios.post(API_URL + "/CRMMapping/InsertCalenderOpSync", data, HEADER);
  };

  // Post GHL Synced Patients api/CRMMapping/UpdateSyncPatients
  static UpdateSyncPatients = (data) => {
    return axios.post(API_URL + "/CRMMapping/UpdateSyncPatients", data, HEADER);
  };

  //post opportunity
  static AddOpportunity = (data) => {
    return axios.post(API_URL + "/CRMMapping/AddOpportunity", data, HEADER);
  };

  static getIntegrationLocations = (data) => {
    return axios.post(API_URL + "/Integration/Locations", data, HEADER);
  };

  static getIntegrationKey = () => {
    return axios.get(API_URL + "/GetPMSIntegrationKey", HEADER);
  };

  static getAgencyAccountDetails = () => {
    return axios.get(API_URL + "/Integration/GetGoHighLevelAgencyDetails", HEADER);
  };

  static addUpdateAgencyKey = (data) => {
    return axios.post(API_URL + "/Integration/AddUpdateGoHighLevelAgencyKey", data, HEADER);
  };

  static removeGhlMapping = (data) => {
    return axios.post(API_URL + "/CRMMapping/GHLRemoveMapping", data, HEADER);
  };

  static getOperatoryMappingForAutomatically = (locationId) => {
    return axios.get(API_URL + "/CRMMapping/GetOperatoryMapping?LocationId=" + locationId, HEADER);
  };

  static insertWritebackSetting = (payLoad) => {
    return axios.post(API_URL + "/CRMMapping/InsertWritebackSetting", payLoad, HEADER);
  };

  //Get notes from database based on message type
  static getMessages = (messageType) => {
    return axios.get(API_URL + "/CRMMapping/GetMessages/" + messageType, HEADER);
  };

   //Insert log for appointment resync
   static appointmentResync = (payLoad) => {
    return axios.post(API_URL + "/CRMMapping/AppointmentResync/", payLoad, HEADER);
  };

  //Update Blockout Type
  static UpdateBlockoutType = (payLoad) => {
    return axios.post(API_URL + "/CRMMapping/UpdateBlockoutType/", payLoad, HEADER);
  };
}

export default Api;
