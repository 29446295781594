const SearchData = ({ sendSearchData, tableLocationDetails, handleChange, onSubmitSearch }) => {
  return (
    <div className="action-row d-flex pt-0">
      <div className="left-box d-flex">
        <div
          className="form-group app-select d-flex flex-column mr-2 mb-1"
          style={{ width: "226px" }}
        >
          <label>From Date</label>
          <input
            type="date"
            className="form-control"
            name="fromDate"
            value={sendSearchData.fromDate}
            onChange={handleChange}
            max={
              sendSearchData.toDate ? sendSearchData.toDate : new Date().toISOString().slice(0, 10)
            }
          />
        </div>
        <div
          className="form-group app-select d-flex flex-column mr-2 mb-1"
          style={{ width: "226px" }}
        >
          <label>To Date</label>
          <input
            type="date"
            className="form-control"
            name="toDate"
            value={sendSearchData.toDate}
            onChange={handleChange}
            min={sendSearchData.fromDate && sendSearchData.fromDate}
          />
        </div>
        <div
          className="form-group app-select d-flex flex-column mr-2 mb-1"
          style={{ width: "226px" }}
        >
          <label>Table Name</label>
          <select className="form-control" name="Table" onChange={handleChange}>
            {tableLocationDetails.Tables.map((table, index) => (
              <option value={table.Key} key={`${table.Key}-option-${index}-${table.Value}`}>
                {table.Value}
              </option>
            ))}
          </select>
        </div>
        {sendSearchData.Table !== "9" && sendSearchData.Table !== "10" && (
          <div
            className="form-group app-select d-flex flex-column mr-2 mb-1"
            style={{ width: "226px" }}
          >
            <label>Location</label>
            <select className="form-control" name="Location" onChange={handleChange}>
              {tableLocationDetails.Location.map((loc) => (
                <option value={loc.LocationId} key={`-option-${loc.LocationName}`}>
                  {loc.LocationName}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="form-group">
          <button className="btn btn-dark mt-3" onClick={onSubmitSearch}>
            <span>
              <i className="fa fa-search mr-2" aria-hidden="true"></i>
            </span>
            Search{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchData;
