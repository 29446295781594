import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { RecordLincURL } from "../config/appConstatnts";

const PublicProfile = ({ userInfo, SetProfileSectionModal, SetWidgetMenuModal }) => {
  return (
    <div className="col-xl-6 col-lg-12">
      <div className="advance-profile-row public-profile-sec flex-wrap mb-4">
        <div className="sec-header mb-2">
          <h4 className="mr-auto">Public profile</h4>
        </div>
        <ListGroup className="info-list w-100 mb-3 mt-2">
          <ListGroup.Item>
            <p className="mb-0">
              Url :{" "}
              <a
                href={RecordLincURL + "p/" + userInfo.PublicProfile}
                target="_blank"
                rel="noopener noreferrer"
              >
                {RecordLincURL + "p/" + userInfo.PublicProfile}
              </a>
            </p>
          </ListGroup.Item>
        </ListGroup>
        <div className="inner-profile-sec w-100">
          <div className="profile-btn-group">
            <button
              type="button"
              className="btn app-btn large-btn mb-2 mr-3"
              onClick={() => SetProfileSectionModal(true)}
            >
              Choose Your Section
            </button>
            <button
              type="button"
              className="btn app-btn large-btn mb-2"
              onClick={() => SetWidgetMenuModal(true)}
            >
              Choose Your Widget
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicProfile;
