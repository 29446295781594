import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const ChangeLocations = (props) => {
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model integration-modal locationInfo-model"
        id="drpro-select"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Change Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body px-4">
          <form>
            <div className="row ">
              <div className="col-12 locationInfo-col">
                <div className="form-group">
                  <div className="info-ctrl mb-3">
                    <p>
                      <strong className="my-2">{props.message}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="text-center">
              <Link
                className="btn app-btn large-btn"
                to={{ pathname: "/setting/teammember", state: { locationId: props.locationId } }}
              >
                Change Location
              </Link>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChangeLocations;
