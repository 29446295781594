import React, { useState } from "react";
import { Modal } from "react-bootstrap";
//component import
import { FormGroup, Label, Spinner } from "reactstrap";
import Api from "../API/profile";
import { RecordLincURL } from "../config/appConstatnts";
import { toast } from "react-toastify";

export default function AddProfileImage(props) {
  const [imgSrc, setimgSrc] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState("");
  const handleInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
    const file = event.target.files[0];
    const fileNameNoSpaces = file.name.replace(/([^a-z0-9]+)/gi, "_");
    setimgSrc(fileNameNoSpaces);
    setError("");
  };
  const onModalClick = () => {
    setError("");
    setSelectedFile();
    setimgSrc(null);
    props.onHide();
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    setError("");
    const data = new FormData();
    if (selectedFile != null && selectedFile !== "") {
      setLoader(true);
      data.append("file", selectedFile);
      Api.postProfileImage(data)
        .then((res) => {
          setSelectedFile("");
          let userData = JSON.parse(localStorage.getItem("userData"));
          userData.ImageUrl = RecordLincURL + "DentistImages/" + res.data.Result;
          localStorage.setItem("userData", JSON.stringify(userData));
          toast.success("Successfully uploaded image!");
          props.reload();
          setTimeout(() => {
            setLoader(false);
            onModalClick();
          }, 1000);
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Something went wrong!");
        });
    } else {
      setError("Please select profile picture!");
    }
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Add Profile Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form onSubmit={handelSubmit}>
            <div className="row ">
              <div className=" col-12 img-upload-row">
                <FormGroup>
                  <Label for="exampleFile">Profile Image</Label>
                  <div class="custom-file mb-md-3">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="validatedCustomFile"
                      onChange={handleInputChange}
                    />
                    <label class="custom-file-label" for="validatedCustomFile">
                      Choose file...
                    </label>
                    <span className="img-file-name">{imgSrc}</span>
                  </div>
                  {error !== "" && <div className="err-message">{error}</div>}
                </FormGroup>
                <p className="mb-4 mt-4">
                  Preferred banner width X height : 240px X 240px and image type are .jpeg, .jpg,
                  .gif, .png
                </p>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={onModalClick}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn large-btn ">
                <div className="button-inner">
                  <span className="btn-text">Upload</span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
