import React from "react";
import { Link } from "react-router-dom";

const TopNotificationMessage = () => {
  return (
    <div style={{ marginTop: "2rem", marginLeft: ".5rem" }}>
      <h5>
        There is no operatory associated with this location. You need to create new Pipeline & Stage
        from GHL Account. <br />
        Please select your location & go to{" "}
        <div className="bg-warning px-2 badge" style={{ borderRadius: ".2rem" }}>
          <Link
            to={{ pathname: "https://app.gohighlevel.com/" }}
            className="text-dark"
            target="_blank"
          >
            {"GHL Settings > Pipelines > Create New Pipeline"}
          </Link>
        </div>
      </h5>
    </div>
  );
};

export default TopNotificationMessage;
