import React, { useState, useEffect } from "react";
import Api from "../API/profile";
// component imports
import uploadIcon from "../assets/images/upload-img.svg";
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import TopNavBar from "../common-components/TopNavbar";
import AddLocationProfileModel from "../models/AddLocationProfileModel";
import EditLocationProfileModel from "../models/EditLocationProfileModel";
import EditUserProfileModel from "../models/EditUserProfileModel";
import AddBannerModal from "../models/AddBanner";
import AddImageGalleryModal from "../models/AddImageGallery";
import ProfileSectionModal from "../models/ProfileSection";
import AddEduTrainingInfoPopup from "../models/AddEduTrainingInfoPopup";
import EditEduTrainingInfoPopup from "../models/EditEduTrainingInfoPopup";
import WidgetMenuModal from "../models/WidgetMenu";
import AddWebsiteModal from "../models/AddWebsiteModal";
import { toast } from "react-toastify";
import parse from "html-react-parser";

import EditWebsiteModal from "../models/EditWebsiteModal";
import SocialMediaModal from "../models/SocialMediaModal";
import AddDescriptionModal from "../models/AddDescriptionModal";
import AddEducationInfomationModel from "../models/AddEducationInformation";
import AddMembershipModal from "../models/AddMembershipModal";
import AddProfileImageModal from "../models/AddProfileImage";
import AddInsuranceDetailModal from "../models/AddInsuranceDetailModal";
import AddProcedureDetailModel from "../models/AddProcedureDetailModal";
import AddProfessionalMembershipModal from "../models/AddProfessionalMembershipModal";
import EditProfessionalMembershipModal from "../models/EditProfessionalMembershipModal";
import AddInsuranceDetailsModal from "../models/AddInsuranceDetailsModal";
import EditSocialMediaModel from "../models/EditSocialMediaModel";
import AppPageLoader from "../common-components/AppPageLoader";

import { Input } from "reactstrap";
import { Link } from "react-router-dom";
import UserInformation from "./UserInformation";
import EducationAndTraining from "./EducationAndTraining";
import BackgroundImage from "./BackgroundImage";
import Description from "./Description";
import InsuranceDetails from "./InsuranceDetails";
import ProffMembership from "./ProffMembership";
import Website from "./Website";
import ProcedureList from "./ProcedureList";
import PublicProfile from "./PublicProfile";
import SocialMedia from "./SocialMedia";
import Gallery from "./Gallery";
//images import

export default function ProfilePage() {
  const [AddLocationModelShow, SetAddLocationModel] = React.useState(false);
  const [EditLocationModelShow, SetEditLocationModel] = React.useState(false);
  const [EditProfileModelShow, SetEditProfileModel] = React.useState(false);
  const [AddBannerModalShow, SetAddBannerModal] = React.useState(false);
  const [AddImageGalleryModalShow, SetAddImageGalleryModal] = React.useState(false);
  const [ProfileSectionModalShow, SetProfileSectionModal] = React.useState(false);
  const [WidgetMenuModalShow, SetWidgetMenuModal] = React.useState(false);
  const [AddWebsiteModalShow, SetAddWebsiteModal] = React.useState(false);
  const [EditWebsiteModalShow, SetEditWebsiteModal] = React.useState(false);
  const [SocialMediaModalShow, SetSocialMediaModal] = React.useState(false);
  const [AddDescriptionModalShow, SetAddDescriptionModal] = React.useState(false);
  const [AddEducationInfomationModelShow, SetAddEducationInfomationModel] = React.useState(false);
  const [AddMembershipModalShow, SetAddMembershipModal] = React.useState(false);
  const [AddInsuranceDetailModalShow, SetAddInsuranceDetailModal] = React.useState(false);
  const [AddProcedureDetailModelShow, SetAddProcedureDetailModel] = React.useState(false);
  const [AddEduTrainingInfoPopupShow, SetAddEduTrainingInfoPopup] = React.useState(false);
  const [EditEduTrainingInfoPopupShow, SetEditEduTrainingInfoPopup] = React.useState(false);
  const [AddProfessionalMembershipModalShow, SetAddProfessionalMembershipModal] =
    React.useState(false);
  const [EditProfessionalMembershipModalShow, SetEditProfessionalMembershipModal] =
    React.useState(false);
  const [AddInsuranceDetailsModalShow, SetAddInsuranceDetailsModal] = React.useState(false);
  const [EditSocialMediaModelShow, SetEditSocialMediaModel] = React.useState(false);

  const [loaderForProfile, SetLoaderForProfile] = useState(true);
  // const [AddWebsiteModalShow, SetAddWebsiteModal] = React.useState(false);

  const [selectedEdu, SetSelectedEdu] = useState(0);
  const [SecondaryWebsiteId, SetSecondaryWebsiteId] = useState(0);
  const [AddProfileImageModalShow, SetAddProfileImageModal] = React.useState(false);
  const oneducationLink = (id) => {
    SetSelectedEdu(id);
    SetAddEducationInfomationModel(!AddEducationInfomationModelShow);
  };

  const onClickwebsite = (id) => {
    SetSecondaryWebsiteId(id);
    SetAddWebsiteModal(!AddWebsiteModalShow);
  };
  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  const onClickSaveDes = (event) => {
    event.preventDefault();
    Api.updateDesciption(userDescription)
      .then((res) => {})
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const deleteWebSite = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      Api.deleteWebSiteDetail(id)
        .then((res) => {
          reloadPage();
          toast.success("Deleted successfully!");
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }
  };
  const deleteMembership = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      SetLoaderForProfile(true);
      Api.deleteMembership(id)
        .then((res) => {
          reloadPage();
          toast.success("Deleted successfully!");
        })
        .catch((error) => {
          toast.error("Something went wrong");
        })
        .finally(() => SetLoaderForProfile(false));
    }
  };

  const deleteGallaryImage = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      Api.deleteGallaryImage(id)
        .then((res) => {
          reloadPage();
          toast.success("Deleted successfully!");
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }
  };

  const deleteBackgroundImage = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      Api.deleteBackgroundImage(id)
        .then((res) => {
          reloadPage();
          toast.success("Deleted successfully!");
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    }
  };

  const handelDes = (event) => {
    setUserDescription(event.target.value);
  };
  const [userDescription, setUserDescription] = useState({});

  const [userInfo, setUserInfo] = useState({
    UserId: 0,
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Description: "",
    OfficeName: "",
    Title: "",
    ImageName: "",
    Email: "",
    Phone: "",
    City: "",
    State: "",
    ZipCode: "",
    DentrixProviderId: "",
    PublicProfile: "",
    WebsiteURL: "",
    PracticeName: "",
    TeamMemberUserId: 0,
    Location: "",
    IsTDO: false,
    ProfileComplete: 0,
    Profilepercentage: "",
    RemainList: "",
    Institute: "",
    MemberShip: "",
    EncryptUserId: "",
    LocationId: 0,
    Salutation: "",
    specialtyIds: "",
    GallaryPath: "",
    lstBanner: [],
    lstGallary: [],
    lstInsurance: [],
    lstDoctorAddressDetails: [],
    lstDoctorSortedAddressDetails: [],
    lstEducationandTraining: [],
    lstProfessionalMemberships: [],
    lstSpeacilitiesOfDoctor: [],
    lstProcedure: [],
    licensedetails: [],
    objLicense: {},
    ObjProfileSection: {},
    lstDoctorAddressDetailsByAddressInfoID: [],
    lstGetSocialMediaDetailByUserId: [],
    lstEducationandTrainingForDoctorById: [],
    lstProfessionalMembershipForDoctorById: [],
    lstsecondarywebsitelist: [],
    lstTimeZone: [],
    lstTeamMemberDetailsForDoctor: [],
    IsRewardPatner: false,
    isprofessional: false,
  });

  const getProfileDetails = (id) => {
    if (id > 0) {
      SetLoaderForProfile(true);
      Api.getProfile(id)
        .then((res) => {
          SetLoaderForProfile(false);
          setUserInfo(res.data);
        })
        .catch((error) => {
          SetLoaderForProfile(false);
        });
    }
  };

  const [profeMembership, setProfeMembership] = useState({
    Id: 0,
    Membership: "",
  });

  const ProfessionalMembership = (id, text) => {
    let obj = {
      Id: id,
      Membership: text,
    };
    setProfeMembership(obj);
    SetAddProfessionalMembershipModal(!AddProfessionalMembershipModalShow);
  };

  const reloadPage = () => {
    getProfileDetails(user.UserId);
  };

  useEffect(() => {
    setUserDescription(userInfo.Description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.UserId]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userData")));
    if (user.UserId > 0) {
      getProfileDetails(user.UserId);
    }
  }, [user.UserId]);

  return (
    <div className="main-wrapper">
      <AppSidebarNavbar />
      <main>
        <div className="app-container">
          <div className="app-content">
            <TopNavBar />
            <div className="profile-page">
              <div className="white-container ">
                <div className="d-flex">
                  <div className="user-information">
                    <UserInformation
                      SetEditProfileModel={SetEditProfileModel}
                      SetAddProfileImageModal={SetAddProfileImageModal}
                      userInfo={userInfo}
                    />
                    <EducationAndTraining userInfo={userInfo} oneducationLink={oneducationLink} />
                    <BackgroundImage
                      uploadIcon={uploadIcon}
                      SetAddBannerModal={SetAddBannerModal}
                      userInfo={userInfo}
                      deleteBackgroundImage={deleteBackgroundImage}
                    />
                  </div>
                  <div className="advance-profile">
                    <div className="inner-container pr-4">
                      <div className="row">
                        <Description
                          handelDes={handelDes}
                          onClickSaveDes={onClickSaveDes}
                          userDescription={userDescription}
                        />
                        <InsuranceDetails
                          SetAddInsuranceDetailsModal={SetAddInsuranceDetailsModal}
                          userInfo={userInfo}
                        />
                      </div>

                      <div className="row">
                        <ProffMembership
                          ProfessionalMembership={ProfessionalMembership}
                          userInfo={userInfo}
                          deleteMembership={deleteMembership}
                        />
                        <Website
                          onClickwebsite={onClickwebsite}
                          userInfo={userInfo}
                          deleteWebSite={deleteWebSite}
                        />
                        <ProcedureList
                          SetAddProcedureDetailModel={SetAddProcedureDetailModel}
                          userInfo={userInfo}
                        />
                        <PublicProfile
                          userInfo={userInfo}
                          SetProfileSectionModal={SetProfileSectionModal}
                          SetWidgetMenuModal={SetWidgetMenuModal}
                        />
                        <SocialMedia
                          SetEditSocialMediaModel={SetEditSocialMediaModel}
                          userInfo={userInfo}
                        />
                      </div>
                      <Gallery
                        SetAddImageGalleryModal={SetAddImageGalleryModal}
                        userInfo={userInfo}
                        deleteGallaryImage={deleteGallaryImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loaderForProfile && <AppPageLoader className={"position-fixed"} />}
          </div>
        </div>
      </main>
      <AddLocationProfileModel
        show={AddLocationModelShow}
        onHide={() => SetAddLocationModel(false)}
      />
      <EditLocationProfileModel
        show={EditLocationModelShow}
        onHide={() => SetEditLocationModel(false)}
      />
      <EditUserProfileModel
        show={EditProfileModelShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetEditProfileModel(false)}
      />
      <AddBannerModal
        show={AddBannerModalShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetAddBannerModal(false)}
      />

      <AddImageGalleryModal
        show={AddImageGalleryModalShow}
        reload={() => reloadPage()}
        onHide={() => SetAddImageGalleryModal(false)}
      />
      <ProfileSectionModal
        show={ProfileSectionModalShow}
        userData={userInfo.ObjProfileSection}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetProfileSectionModal(false)}
      />
      <WidgetMenuModal
        show={WidgetMenuModalShow}
        profileName={userInfo.PublicProfile}
        reload={() => reloadPage()}
        onHide={() => SetWidgetMenuModal(false)}
      />
      <AddWebsiteModal
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        show={AddWebsiteModalShow}
        SecondaryWebsiteId={SecondaryWebsiteId}
        onHide={() => SetAddWebsiteModal(false)}
      />
      <EditWebsiteModal show={EditWebsiteModalShow} onHide={() => SetEditWebsiteModal(false)} />
      <SocialMediaModal show={SocialMediaModalShow} onHide={() => SetSocialMediaModal(false)} />
      <AddDescriptionModal
        show={AddDescriptionModalShow}
        onHide={() => SetAddDescriptionModal(false)}
      />
      <AddEducationInfomationModel
        selectedEdu={selectedEdu}
        reload={() => reloadPage()}
        show={AddEducationInfomationModelShow}
        onHide={() => SetAddEducationInfomationModel(false)}
      />
      <AddMembershipModal
        show={AddMembershipModalShow}
        onHide={() => SetAddMembershipModal(false)}
      />
      <AddInsuranceDetailModal
        show={AddInsuranceDetailModalShow}
        onHide={() => SetAddInsuranceDetailModal(false)}
      />
      <AddProcedureDetailModel
        show={AddProcedureDetailModelShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetAddProcedureDetailModel(false)}
      />
      <AddEduTrainingInfoPopup
        show={AddEduTrainingInfoPopupShow}
        onHide={() => SetAddEduTrainingInfoPopup(false)}
      />
      <EditEduTrainingInfoPopup
        show={EditEduTrainingInfoPopupShow}
        onHide={() => SetEditEduTrainingInfoPopup(false)}
      />
      <AddProfessionalMembershipModal
        show={AddProfessionalMembershipModalShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        profeMembership={profeMembership}
        onHide={() => SetAddProfessionalMembershipModal(false)}
      />
      <EditProfessionalMembershipModal
        show={EditProfessionalMembershipModalShow}
        onHide={() => SetEditProfessionalMembershipModal(false)}
      />
      <AddInsuranceDetailsModal
        show={AddInsuranceDetailsModalShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetAddInsuranceDetailsModal(false)}
      />
      <EditSocialMediaModel
        show={EditSocialMediaModelShow}
        UserId={userInfo.UserId}
        reload={() => reloadPage()}
        onHide={() => SetEditSocialMediaModel(false)}
      />
      <AddProfileImageModal
        show={AddProfileImageModalShow}
        reload={() => reloadPage()}
        onHide={() => SetAddProfileImageModal(false)}
      />
    </div>
  );
}
