import React from "react";

export default function AppPageLoader(props) {
  return (
    <div
      className={`loader-wrapper align-items-center justify-content-center ${props.className}`}
      id="appLoadingMain"
      style={props.style}
    >
      <div id="loading-bar-spinner" className="spinner">
        <div className="spinner-icon"></div>
      </div>
    </div>
  );
}
