import React, { useState } from "react";
import { publicUrl } from "../../config/appConstatnts";
import { Tooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { goToSignin, validateForm } from "../../config/commonFun";
import { Spinner } from "reactstrap";
import {
  validCharRegex,
  validEmailRegex,
  validPasswordRegex,
  validPhoneRegex,
} from "../../config/appRegex";
import Api from "../../API/signup";

const Step1 = ({ nextPage, postData, setPostData, apiData }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [cpasswordType, setCPasswordType] = useState("password");
  const [eyeTog2, seteyeTog2] = useState("false");
  const changePasswordType = () => {
    passwordType === "password" ? setPasswordType("text") : setPasswordType("password");
    seteyeTog2(!eyeTog2);
  };
  const [eyeTog3, seteyeTog3] = useState("false");
  const changeConPasswordType = () => {
    cpasswordType === "password" ? setCPasswordType("text") : setCPasswordType("password");
    seteyeTog3(!eyeTog3);
  };

  const [loader, setLoader] = useState(false);
  const [userModel, setUserModel] = useState(postData);
  const [userModelErrors, setUserModelErrors] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Password: "",
    ConPassword: "",
  });

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [resMessage, setResMessage] = useState("");

  //   ==============handle events =====================
  const handelUserModelChange = (event) => {
    event.persist();
    let { name, value } = event.target;
    if (name !== "Password" && name !== "ConPassword") {
      value = value.trim();
    }
    let errorValue = "";

    setUserModel((userModel) => ({
      ...userModel,
      [name]: value,
    }));

    switch (name) {
      case "FirstName":
        if (value.length === 0) {
          errorValue = "First Name is required.";
        } else {
          errorValue = validCharRegex.test(value) ? "" : "Enter only characters.";
        }
        break;
      case "LastName":
        if (value.length === 0) {
          errorValue = "Last Name is required.";
        } else {
          errorValue = validCharRegex.test(value) ? "" : "Enter only characters.";
        }
        break;
      case "Email":
        if (value.length === 0) {
          errorValue = "Email is required.";
        } else {
          errorValue = validEmailRegex.test(value) ? "" : "Enter valid email.";
        }
        break;
      case "Phone":
        if (value.length === 0) {
          errorValue = "Phone number is required.";
        } else {
          errorValue = validPhoneRegex.test(value) ? "" : "Enter valid phone number.";
        }
        break;
      case "Password":
        if (value.length === 0) {
          errorValue = "Password is required.";
        } else {
          errorValue = validPasswordRegex.test(value) ? "" : "Enter valid Password.";
        }
        break;
      case "ConPassword":
        if (value.length === 0) {
          errorValue = "Password is required.";
        } else {
          errorValue = userModel.Password === value ? "" : "Password don't match.";
        }
        break;
      default:
        break;
    }
    setUserModelErrors((userModelErrors) => ({
      ...userModelErrors,
      [name]: errorValue,
    }));
  };

  const onSecondPage = (event) => {
    event.preventDefault();
    let flagError = false;

    if (userModel.FirstName.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        FirstName: "FirstName is required.",
      }));
      flagError = true;
    }
    if (userModel.LastName.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        LastName: "Last Name is required.",
      }));
      flagError = true;
    }
    if (userModel.Email.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        Email: "Email is required.",
      }));
      flagError = true;
    }
    if (userModel.Phone.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        Phone: "Phone is required.",
      }));
      flagError = true;
    }
    if (userModel.Password.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        Password: "Password is required.",
      }));
      flagError = true;
    }
    if (userModel.ConPassword.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ConPassword: "Password is required.",
      }));
      flagError = true;
    }

    if (validateForm(userModelErrors) && !flagError) {
      setLoader(true);
      Api.getCheckEmail(userModel.Email)
        .then((res) => {
          setLoader(false);
          if (res.data.Result !== -1)
            setUserModelErrors((userModelErrors) => ({
              ...userModelErrors,
              Email: res.data.Message,
            }));
          else {
            setPostData(userModel);
            nextPage();
          }
        })
        .catch((error) => {
          setLoader(false);
          setResMessage(error.response.data.Message);
        });
    }
  };

  return (
    <>
      <div className="inner-block text-center sign-up">
        <div className="block-1">
          <h1>Sign up</h1>
          <p className="mb-2">Enter your personal information </p>
        </div>
        <form>
          <div className="content">
            <div className="form-group">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control light-border"
                  id="firstName"
                  name="FirstName"
                  maxLength="20"
                  placeholder="First Name*"
                  value={userModel.FirstName}
                  onChange={handelUserModelChange}
                />
                <span className="input-icon1">
                  {apiData && (
                    <img
                      src={`${publicUrl}/${apiData.CompanyId}/images/user.svg`}
                      alt=""
                      className="img-fluid"
                    />
                  )}
                </span>
              </div>
              <div className="err-message">{userModelErrors.FirstName}</div>
            </div>
            <div className="form-group">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control light-border"
                  id="lastName"
                  name="LastName"
                  maxLength="20"
                  placeholder="Last Name*"
                  value={userModel.LastName}
                  onChange={handelUserModelChange}
                />
                <span className="input-icon1">
                  {apiData && (
                    <img
                      src={`${publicUrl}/${apiData.CompanyId}/images/user.svg`}
                      alt=""
                      className="img-fluid"
                    />
                  )}
                </span>
              </div>
              <div className="err-message">{userModelErrors.LastName}</div>
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control light-border"
                id="email"
                name="Email"
                maxLength="100"
                placeholder="Email Address*"
                value={userModel.Email}
                onChange={handelUserModelChange}
              />
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/envelope.svg`}
                    alt=""
                    className="img-fluid"
                  />
                )}
              </span>
            </div>
            <div className="err-message">{userModelErrors.Email}</div>
            <div className="form-group">
              <input
                type="text"
                className="form-control light-border"
                id="phoneNumber"
                placeholder="Phone Number*"
                name="Phone"
                value={userModel.Phone}
                onChange={handelUserModelChange}
              />
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/phone-call.svg`}
                    alt=""
                    className="img-fluid"
                  />
                )}
              </span>
            </div>
            <div className="err-message">{userModelErrors.Phone}</div>
            <div className="form-group" id="TooltipExample">
              <Tooltip
                placement="bottom"
                isOpen={tooltipOpen}
                target="TooltipExample"
                toggle={toggleTooltip}
              >
                Password must be a minimum of 8 characters long and contain an upper and lower case
                letter, a number and a symbol.
              </Tooltip>
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/padlock.svg`}
                    alt=""
                    className="img-fluid"
                  />
                )}
              </span>
              <input
                type={passwordType}
                className="form-control light-border pass-input"
                id="password"
                maxLength="50"
                placeholder="Password*"
                name="Password"
                value={userModel.Password}
                onChange={handelUserModelChange}
              />

              <span className="input-icon">
                <i
                  className={`passTogEye icon-eye ${!eyeTog2 ? "passVisible" : ""}`}
                  onClick={changePasswordType}
                ></i>
              </span>
            </div>
            <div className="err-message">{userModelErrors.Password}</div>
            <div className="form-group">
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/padlock.svg`}
                    alt=""
                    className="img-fluid"
                  />
                )}
              </span>
              <input
                type={cpasswordType}
                className="form-control light-border pass-input"
                id="conPassword"
                maxLength="50"
                placeholder="Confirm Password*"
                name="ConPassword"
                value={userModel.ConPassword}
                onChange={handelUserModelChange}
              />

              <span className="input-icon">
                <i
                  className={`passTogEye icon-eye ${!eyeTog3 ? "passVisible" : ""}`}
                  onClick={changeConPasswordType}
                ></i>
              </span>
            </div>
            <div className="err-message">{userModelErrors.ConPassword}</div>
          </div>
          <div className="account-btn-block">
            <div className="btn-block">
              <button
                type="button"
                className={`btn btn-outline-primary blue-btn-bg ${
                  loader ? "btn-loading-state" : ""
                }`}
                onClick={onSecondPage}
              >
                <div className="button-inner">
                  {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                  <span className="btn-text">next</span>
                </div>
              </button>
            </div>
          </div>
          <div className="forget-link  text-right link-purple signup-link">
            <p>
              Already have Account?
              <Link href="#" className="h5 f-600 pl-2" onClick={goToSignin}>
                Sign in
              </Link>
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step1;
