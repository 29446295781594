import React from "react";

const UserInformation = ({ SetEditProfileModel, SetAddProfileImageModal, userInfo }) => {
  return (
    <div className="inner-container mb-4">
      <div className="sec-header">
        <h4 className="mr-auto">User Information</h4>
        <button
          className="app-btn app-btn-clear sec-action-btn "
          onClick={() => SetEditProfileModel(true)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17">
            <path
              fill="#707070"
              d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
            />
          </svg>{" "}
          Edit
        </button>
      </div>
      <div className="user-img-wrapper">
        <div className="user-img" onClick={() => SetAddProfileImageModal(true)}>
          <img className="user-img" src={encodeURI(userInfo.ImageName)} alt="ProfileImage" />
          <div className="upload-btn">
            <svg
              width={35}
              height={35}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 36.174 36.174"
            >
              <path d="M23.921 20.528c0 3.217-2.617 5.834-5.834 5.834s-5.833-2.617-5.833-5.834 2.616-5.834 5.833-5.834 5.834 2.618 5.834 5.834zm12.253-8.284v16.57a4 4 0 01-4 4H4a4 4 0 01-4-4v-16.57a4 4 0 014-4h4.92V6.86a3.5 3.5 0 013.5-3.5h11.334a3.5 3.5 0 013.5 3.5v1.383h4.92c2.209.001 4 1.792 4 4.001zm-9.253 8.284c0-4.871-3.963-8.834-8.834-8.834-4.87 0-8.833 3.963-8.833 8.834s3.963 8.834 8.833 8.834c4.871 0 8.834-3.963 8.834-8.834z" />
            </svg>
          </div>
        </div>
        <h4 className="user-name">
          {userInfo.Salutation + " " + userInfo.FirstName + " " + userInfo.LastName}
        </h4>
        <h4 className="user-prof">
          {userInfo.lstSpeacilitiesOfDoctor &&
            userInfo.lstSpeacilitiesOfDoctor.length > 0 &&
            userInfo.lstSpeacilitiesOfDoctor.map((Speacilty, i, arr) => (
              <span>
                {Speacilty.SpecialtyDescription}
                {i !== arr.length - 1 ? ", " : ""}
              </span>
            ))}
        </h4>
      </div>
      <div className="user-basic-info">
        <div className="info-row">
          <span>First Name</span>
          <label>{userInfo.FirstName} </label>
        </div>
        <div className="info-row">
          <span>Last Name</span>
          <label>{userInfo.LastName} </label>
        </div>
        <div className="info-row">
          <span>Email</span>
          <label> {userInfo.Email} </label>
        </div>
        <div className="info-row">
          <span>Phone No</span>
          <label>{userInfo.Phone}</label>
        </div>
        <div className="info-row">
          <span>Practice Name</span>
          <label>{userInfo.OfficeName}</label>
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
