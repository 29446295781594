import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { toast } from "react-toastify";

import Api from "../../API/go-high-level";
import { removeDeletedGhlId } from "../../config/commonFun";
// component imports

import {
  CommonNotificationBlue,
  CommonNotificationGreen,
  ErrorMessage,
  SaveButton,
  SectionWithCheckbox,
  SyncUserLabel,
} from "../OtherCrmCommonComponente/OtherCrmCommonComponents";
import { localStorageUserData } from "../../config/appConstatnts";
import AppPageLoader from "../../common-components/AppPageLoader";

const SyncUser = ({
  IsAgencyKeyError,
  credentials,
  LocationSyncId,
  DoctorMapping,
  ghlUsers,
  IsSyncUser,
  userToggle,
}) => {
  // states ===========
  const [IsSyncUsers, setIsSyncUsers] = useState(false);
  const [RLUsers, setRLUsers] = useState([]);
  const [RLGHLUsersResponse, setRLGHLUsersResponse] = useState([]);
  const [IsDisableButton, setIsDisableButton] = useState(false);
  const [sectionLoader, setSectionLoader] = useState(false);

  // handle change users=======================
  const handleChange = (e, key) => {
    RLGHLUsersResponse[key].SyncId = e.target.value;
    RLGHLUsersResponse[key].User =
      e.target.value === "0" || e.target.value === "1"
        ? ""
        : ghlUsers.find((k) => k.id === e.target.value);
    setRLGHLUsersResponse(RLGHLUsersResponse);
    setIsDisableButton(false);
  };

  // handle submit users=======================
  const submitSelectedRlGhlUser = () => {
    const payLoad = {
      LocationId: credentials.locationId,
      CRMType: credentials.CRMType,
      UserId: localStorageUserData.UserId,
      Sync: RLGHLUsersResponse,
    };

    let error = false;
    for (let i = 0; i < RLGHLUsersResponse.length; i++) {
      if (RLGHLUsersResponse[i].SyncId.length > 1) {
        for (let j = 0; j < RLGHLUsersResponse.length; j++) {
          if (j !== i) {
            if (RLGHLUsersResponse[i].SyncId === RLGHLUsersResponse[j].SyncId) {
              toast.error(
                "Please select appropriate option for each RL user to save user mapping settings."
              );
              return (error = true);
            }
          }
        }
      }
    }
    if (error === false) {
      setIsDisableButton(true);
      setSectionLoader(true);
      Api.InsertDoctorSync(payLoad)
        .then((res) => {
          if (res.data.IsSuccess === true) {
            toast.success("CRM user mapping saved successfully.");
            let valueChange = RLUsers;
            valueChange.map((v, index) =>
              v.DoctorId === payLoad.Sync[index].DoctorId
                ? (v.SyncId = payLoad.Sync[index].SyncId)
                : (v.SyncId = "")
            );
            setRLUsers([...valueChange]);
          }
          setSectionLoader(false);
        })
        .catch((error) => {
          toast.error(error.response.data.Message);
          setIsDisableButton(false);
          setSectionLoader(false);
        });
    }
  };

  // remove deleted calendarId api call==========
  const removeGhlApiCallUsers = (apiPayload) => {
    Api.removeGhlMapping(apiPayload); // at this moment we are not doing anything with this return
  };

  useEffect(() => {
    const doctorMappingSyncIdList = DoctorMapping.map((doctorId) => doctorId.SyncId);
    const ghlUsersList = ghlUsers.map((ghlUsers) => ghlUsers.id);
    const pushDataIntoArray = removeDeletedGhlId(doctorMappingSyncIdList, ghlUsersList);
    const responseDataArray = [];
    for (const key in pushDataIntoArray) {
      const subjectData = pushDataIntoArray[key];
      for (const optmapKey in DoctorMapping) {
        if (DoctorMapping[optmapKey].SyncId === subjectData) {
          responseDataArray.push(DoctorMapping[optmapKey]);
        }
      }
    }
    if (pushDataIntoArray.length > 0 && responseDataArray.length > 0) {
      const apiPayload = {
        AccountId: credentials.accountId,
        RLLocationId: credentials.locationId,
        Mapping: responseDataArray.map(({ DoctorId, SyncId }) => ({
          CRMSyncedId: SyncId,
          RecordlincId: [DoctorId],
        })),
        TableId: 2,
      };
      responseDataArray.forEach((element) => {
        element.SyncId = "1";
      });
      removeGhlApiCallUsers(apiPayload);
    }

    const checkData = [];
    const data = [...DoctorMapping];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (data[j].SyncId === "Not Sync" || data[j].SyncId === "") data[j].SyncId = "1";
        !checkData.map((v) => v.DoctorId).includes(data[j].DoctorId) && checkData.push(data[j]);
      }
    }
    setRLUsers(checkData);

    let rlGhlUserResponse = DoctorMapping.map(({ DoctorId, SyncId }) => ({
      DoctorId,
      SyncId,
      User: "",
    }));
    setRLGHLUsersResponse(rlGhlUserResponse);
  }, [DoctorMapping, ghlUsers, credentials]);

  useEffect(() => {
    setIsSyncUsers(IsSyncUser);
  }, [IsSyncUser]);

  return (
    <>
      <SectionWithCheckbox
        className={"mt-0"}
        title={"Sync User "}
        formDisable={IsAgencyKeyError}
        onChangeCheckbox={userToggle}
        checked={IsSyncUsers}
        switchId={"custom-switch-user"}
      >
        <b>Note : </b>
        <span>
          If you want to assign a user/doctor to contact/patient in other CRM, you have to sync
          users. User Sync is not mandatory, you can still transfer patients and appointments to
          other CRM.
        </span>
      </SectionWithCheckbox>

      <Collapse className="panel-accordion additionalClassForContent" isOpen={IsSyncUsers}>
        <div className="collapse-content-scroll">
          {LocationSyncId === "1" || RLUsers.length === 0 ? (
            <div style={{ marginLeft: ".5rem" }}>
              <h5>
                There is no user/doctor associated with this location. Please create a new doctor
                for this location.
              </h5>
            </div>
          ) : (
            LocationSyncId && (
              <form>
                {RLUsers &&
                  RLUsers.length > 0 &&
                  RLUsers.map((doctors, key) => (
                    <div className="row w-100 mx-0" key={key}>
                      <div className="box-col col-xs-6 col-md-6 col-lg-6 col-xl-6 locationInfo-col">
                        <div className="form-group">
                          {key === 0 && <SyncUserLabel label={"RL User List"} />}
                          {doctors.ResponseError !== "" && (
                            <ErrorMessage>{doctors.ResponseError}</ErrorMessage>
                          )}
                          <div className="w-100 multiselect-row pb-0 mb-3">
                            <div className=" multiselect-col-1">
                              <select
                                disabled
                                selected={RLUsers[key].Name}
                                className="form-control my-auto"
                                name="RLUsers"
                              >
                                <option selected={RLUsers[key].Name}>{RLUsers[key].Name}</option>
                              </select>
                            </div>
                            <div className="sync-text ">
                              <p>Sync To</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="box-col col-xs-6 col-md-6 col-lg-6 col-xl-6 locationInfo-col">
                        <div className="form-group">
                          {key === 0 && <SyncUserLabel label={"CRM Users List"} />}
                          <div className="w-100 multiselect-row pb-0 mb-3">
                            <div className=" multiselect-col-1">
                              <select
                                disabled={doctors.SyncId === "1" ? false : true}
                                className="form-control my-auto"
                                name="GHLUsers"
                                onChange={(e) => handleChange(e, key)}
                              >
                                <option value={"0"} selected={doctors.SyncId === "0"}>
                                  Create New
                                </option>
                                <option
                                  value={"1"}
                                  selected={doctors.SyncId === "1" ? true : false}
                                >
                                  Do Not Sync
                                </option>

                                {ghlUsers.map((users) => (
                                  <option
                                    key={users.id}
                                    value={users.id}
                                    selected={doctors.SyncId === users.id ? true : false}
                                  >
                                    {users.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {RLUsers.some((rlUser) => rlUser.SyncId === "1") &&
                              key === RLUsers.length - 1 && (
                                <SaveButton
                                  isDisabled={IsDisableButton}
                                  SaveBtnOnClick={submitSelectedRlGhlUser}
                                />
                              )}
                            <div className="sync-text "></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {RLUsers.length !== 0 &&
                !RLUsers.every((rlUser) => rlUser.SyncId === "1") &&
                RLUsers.every((rlUser) => rlUser.SyncId.length !== 0) ? (
                  RLUsers.some((rlUser) => rlUser.SyncId === "0") ? (
                    <CommonNotificationGreen>
                      We are creating a new user in CRM, you will be notified once it is done. Once
                      the user is ready, you will be able to sync appointment.
                    </CommonNotificationGreen>
                  ) : (
                    <CommonNotificationBlue>
                      User mapping cannot be changes once it is setup.
                    </CommonNotificationBlue>
                  )
                ) : (
                  ""
                )}
              </form>
            )
          )}
        </div>
        {sectionLoader && <AppPageLoader className="position-fixed" />}
      </Collapse>
    </>
  );
};
export default SyncUser;
