import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Pagination = ({ pages, onPageClickFunction, totalRecords, itemsPerPage, parentFilters }) => {
  const [filters, setFilters] = useState({
    PageIndex: 1,
    PageSize: itemsPerPage,
    totalRecords,
    totalPages: pages,
  });

  const setCurrentPage = (event, index) => {
    event.preventDefault();
    setFilters((filters) => ({
      ...filters,
      PageIndex: index,
    }));
    onPageClickFunction(index, itemsPerPage);
  };

  const ShowMore = (
    <li key={"...start"} className="page-item">
      <Link to={"/#"} className="page-link" aria-label="...">
        <span aria-hidden="true">...</span>
      </Link>
    </li>
  );
  const getPagination = (activePage) => {
    let list = [];

    const showLinks = (showMoreStart, value, showMoreEnd) => {
      if (showMoreStart) list.push(ShowMore);

      value === 1
        ? list.push(
            <li key={`Tab-${value + 1}`} className="page-item">
              <Link
                to={"/#"}
                className="page-link"
                aria-label={value + 1}
                onClick={(e) => setCurrentPage(e, value + 1)}
              >
                <span aria-hidden="true">{value + 1}</span>
              </Link>
            </li>
          )
        : value - 1 === 1
        ? list.push(
            <li key={`Tab-${value}`} className="page-item disabled">
              <Link
                to={"/#"}
                className="page-link bg-dark text-light "
                aria-label={value}
                onClick={(e) => setCurrentPage(e, value)}
              >
                <span aria-hidden="true">{value}</span>
              </Link>
            </li>
          ) &&
          list.push(
            <li key={`Tab-${value + 1}`} className="page-item">
              <Link
                to={"/#"}
                className="page-link"
                aria-label={value + 1}
                onClick={(e) => setCurrentPage(e, value + 1)}
              >
                <span aria-hidden="true">{value + 1}</span>
              </Link>
            </li>
          )
        : value + 1 === pages
        ? list.push(
            <li key={`Tab-${value - 1}`} className="page-item">
              <Link
                to={"/#"}
                className="page-link"
                aria-label={value - 1}
                onClick={(e) => setCurrentPage(e, value - 1)}
              >
                <span aria-hidden="true">{value - 1}</span>
              </Link>
            </li>
          ) &&
          list.push(
            <li key={`Tab-${value}`} className="page-item disabled">
              <Link
                to={"/#"}
                className="page-link bg-dark text-light "
                aria-label={value}
                onClick={(e) => setCurrentPage(e, value)}
              >
                <span aria-hidden="true">{value}</span>
              </Link>
            </li>
          )
        : value === pages
        ? list.push(
            <li key={`Tab-${value - 1}`} className="page-item">
              <Link
                to={"/#"}
                className="page-link"
                aria-label={value - 1}
                onClick={(e) => setCurrentPage(e, value - 1)}
              >
                <span aria-hidden="true">{value - 1}</span>
              </Link>
            </li>
          )
        : list.push(
            <li key={`Tab-${value - 1}`} className="page-item">
              <Link
                to={"/#"}
                className="page-link"
                aria-label={value - 1}
                onClick={(e) => setCurrentPage(e, value - 1)}
              >
                <span aria-hidden="true">{value - 1}</span>
              </Link>
            </li>
          ) &&
          list.push(
            <li key={`Tab-${value}`} className="page-item disabled">
              <Link
                to={"/#"}
                className="page-link bg-dark text-light "
                aria-label={value}
                onClick={(e) => setCurrentPage(e, value)}
              >
                <span aria-hidden="true">{value}</span>
              </Link>
            </li>
          ) &&
          list.push(
            <li key={`Tab-${value + 1}`} className="page-item">
              <Link
                to={"/#"}
                className="page-link"
                aria-label={value + 1}
                onClick={(e) => setCurrentPage(e, value + 1)}
              >
                <span aria-hidden="true">{value + 1}</span>
              </Link>
            </li>
          );

      showMoreEnd && list.push(ShowMore);
    };

    if (pages > 5) {
      if (activePage < 4) showLinks(false, activePage, true);
      if (pages - activePage < 3) showLinks(true, activePage, false);
      if (activePage >= 4 && activePage <= pages - 3) showLinks(true, activePage, true);
    } else {
      for (let index = 2; index < pages; index++) {
        list.push(
          <li
            key={`Tab-${index}`}
            className={activePage === index ? "page-item disabled" : "page-item"}
          >
            <Link
              to={"/#"}
              className={activePage === index ? "page-link bg-dark text-light" : "page-link"}
              aria-label={index}
              onClick={(e) => setCurrentPage(e, index)}
            >
              <span aria-hidden="true">{index}</span>
            </Link>
          </li>
        );
      }
    }

    return (
      <ul className="pagination my-2 mx-auto">
        <li key={`Tab-First`} className={activePage > 1 ? "page-item" : "page-item disabled"}>
          <Link
            to={"/#"}
            className="page-link"
            aria-label="First"
            onClick={(e) => setCurrentPage(e, 1)}
          >
            <span aria-hidden="true">&laquo;</span>
          </Link>
        </li>
        <li key={`Tab-Prev`} className={activePage > 1 ? "page-item" : "disabled page-item"}>
          <Link
            to={"/#"}
            className="disabled page-link"
            aria-label="Prev"
            onClick={(e) => setCurrentPage(e, activePage - 1)}
          >
            <span aria-hidden="true">Prev</span>
          </Link>
        </li>
        <li key={`Tab-First-1`} className={activePage === 1 ? "page-item disabled" : "page-item"}>
          <Link
            to={"/#"}
            className={
              pages > 1
                ? activePage === 1
                  ? "page-link bg-dark text-light"
                  : "page-link"
                : "d-none"
            }
            aria-label="First"
            onClick={(e) => setCurrentPage(e, 1)}
          >
            <span aria-hidden="true">1</span>
          </Link>
        </li>

        {list}

        <li
          key={`Tab-Last-${pages}`}
          className={activePage === pages ? "page-item bg-dark text-light disabled" : "page-item"}
        >
          <Link
            to={"/#"}
            className={activePage === pages ? "page-link bg-dark text-light" : "page-link"}
            aria-label={pages}
            onClick={(e) => setCurrentPage(e, pages)}
          >
            <span aria-hidden="true">{pages}</span>
          </Link>
        </li>
        <li key={`Tab-Next`} className={activePage !== pages ? "page-item" : "disabled page-item"}>
          <Link
            to={"/#"}
            className="page-link"
            aria-label="Next"
            onClick={(e) => setCurrentPage(e, activePage + 1)}
          >
            <span aria-hidden="true">Next</span>
          </Link>
        </li>
        <li key={`Tab-Last`} className={activePage !== pages ? "page-item" : "disabled page-item"}>
          <Link
            to={"/#"}
            className="page-link"
            aria-label="Last"
            onClick={(e) => setCurrentPage(e, pages)}
          >
            <span aria-hidden="true">&raquo;</span>
          </Link>
        </li>
      </ul>
    );
  };

  const showingData = (pageIndex, totalRecords, pageSize) => {
    return (
      <h6>
        Showing {(Number(pageIndex) - 1) * Number(pageSize) + 1} to{" "}
        {pageIndex * pageSize <= totalRecords ? pageIndex * pageSize : totalRecords} of{" "}
        {totalRecords} records
      </h6>
    );
  };

  useEffect(() => {
    let newFilterData = {
      PageIndex: parentFilters?.PageIndex ?? 1,
      PageSize: parentFilters?.PageSize ??50,
      totalRecords,
      totalPages: pages,
    };
    setFilters({ ...newFilterData });
  }, [parentFilters, totalRecords, pages]);

  return (
    <div className="d-flex justify-content-between">
      <div className="col-6 my-auto">
        {showingData(filters.PageIndex, filters.totalRecords, filters.PageSize)}
      </div>
      {pages !== 1 && (
        <div className="col-6">
          <nav className="d-flex ml-0" aria-label="Page navigation example">
            {getPagination(filters.PageIndex)}
          </nav>
        </div>
      )}
    </div>
  );
};

export default Pagination;
