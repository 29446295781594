import React from "react";
import { components, default as ReactSelect } from "react-select";

const RowSection = ({
  appointmentRightBack,
  IsOPSyncAutomatically,
  handleRemoveRow,
  handleDoctorProcedure,
  submitCalendarOpt,
  initialOperatoryOptionLength,
  doctorTreatmentRightback,
  DoctorsOptions,
  OptOptions,
  CalendarOptMapData,
  PreviouslySavedData,
  allOptions,
  handleChangeOperatorys,
  errorMessageTag,
  handleChangeCalendar,
  GhlCalendarList,
}) => {
  return OptOptions.map((selectBox, key) => (
    <div className="row w-100 mx-0" key={key} id={`row-${key}`}>
      <div
        className={
          appointmentRightBack
            ? "box-col col-xs-3 col-md-3 col-lg-3 col-xl-3 locationInfo-col"
            : "box-col col-xs-6 col-md-6 col-lg-6 col-xl-6 locationInfo-col"
        }
      >
        <div className="form-group">
          {key === 0 && <p className="p-0 icon-label">Select Operatory</p>}
          <div className="w-100 multiselect-row pb-0 mb-3 ">
            <div className={appointmentRightBack ? "w-75 multiselect-col-1" : "multiselect-col-1"}>
              <ReactSelect
                placeholder={"Select Operatory"}
                className="my-2"
                isDisabled={
                  key >= initialOperatoryOptionLength ||
                  OptOptions[key]?.OperatoryOptions?.length === 0 ||
                  (!IsOPSyncAutomatically &&
                    CalendarOptMapData.length === 1 &&
                    !CalendarOptMapData[key]?.Operatorys?.some((v) => v.IsSync === true) &&
                    PreviouslySavedData === null)
                    ? false
                    : true
                }
                options={allOptions}
                name="Operatorys"
                value={allOptions.filter((obj) =>
                  OptOptions[key].OperatoryOptions.includes(obj.value)
                )}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                onChange={(e, name) => handleChangeOperatorys(e, key, name)}
                allowSelectAll={true}
                isMulti={true}
              />
            </div>

            <div className={`sync-text text-center ${appointmentRightBack ? "w-25" : ""}`}>
              <p>Sync To</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          appointmentRightBack
            ? "box-col col-xs-2 col-md-2 col-lg-2 col-xl-2 locationInfo-col"
            : "box-col col-xs-6 col-md-6 col-lg-6 col-xl-6 locationInfo-col"
        }
      >
        <div className="form-group">
          {key === 0 && <p className="p-0 icon-label">CRM Calendar List</p>}
          {selectBox.ResponseError !== "" && errorMessageTag(selectBox.ResponseError)}
          <div className="w-100 multiselect-row pb-0 mb-3">
            <div className={`multiselect-col-1 ${appointmentRightBack ? "w-100" : ""}`}>
              <select
                className="form-control my-auto pr-1"
                name="CalendarId"
                onChange={(e) => handleChangeCalendar(e, key, e.target.name)}
                disabled={
                  submitCalendarOpt[key]?.CalendarId === "1" || key >= initialOperatoryOptionLength
                    ? false
                    : true
                }
              >
                <option value={0} selected={selectBox?.CalendarId === "0"}>
                  Create New
                </option>
                <option value={1} selected={selectBox?.CalendarId === "1"}>
                  Do Not Sync
                </option>

                {GhlCalendarList &&
                  GhlCalendarList.map((ghlcalendar, key) => {
                    return (
                      <option
                        className="d-block"
                        key={`GHLCalendar-${key}`}
                        value={ghlcalendar.id}
                        selected={ghlcalendar.id === selectBox.CalendarId ? true : false}
                      >
                        {ghlcalendar.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            {!appointmentRightBack && !IsOPSyncAutomatically && (
              <button type="button" className="btn" onClick={(e) => handleRemoveRow(e, key)}>
                &#10006;
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className={
          appointmentRightBack
            ? "box-col col-xs-2 col-md-2 col-lg-2 col-xl-2 locationInfo-col"
            : "d-none"
        }
      >
        <div className="form-group">
          {key === 0 && <p className="p-0 icon-label">Team Member</p>}
          <div className="w-100 multiselect-row pb-0 mb-3">
            <div className="w-100 multiselect-col-1">
              <select
                className="form-control my-auto pr-1"
                name="Doctors"
                onChange={(e) => handleDoctorProcedure(e, key, e.target.name)}
                disabled={
                  submitCalendarOpt[key]?.RLDoctorId === 0 || key >= initialOperatoryOptionLength
                    ? false
                    : true
                }
              >
                <option
                  key={key}
                  value={0}
                  selected={
                    doctorTreatmentRightback[key]?.RLDoctorId === 0 ||
                    doctorTreatmentRightback[key]?.RLDoctorId === "0"
                      ? true
                      : false
                  }
                >
                  Select Doctor
                </option>
                {DoctorsOptions &&
                  DoctorsOptions.map((doctor, key1) => {
                    return (
                      <option
                        key={`${key}${key1}`}
                        value={doctor.value}
                        selected={doctorTreatmentRightback[key]?.RLDoctorId === doctor.value}
                      >
                        {doctor.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          appointmentRightBack
            ? "box-col col-xs-2 col-md-2 col-lg-2 col-xl-2 locationInfo-col"
            : "d-none"
        }
      >
        <div className="form-group">
          {key === 0 && <p className="p-0 icon-label">Type of Treatment</p>}
          <div className="w-100 multiselect-row pb-0 mb-3">
            <div className="w-100 multiselect-col-1">
              <select
                className="form-control my-auto pr-1"
                name="Treatment"
                onChange={(e) => handleDoctorProcedure(e, key, e.target.name)}
                disabled={
                  submitCalendarOpt[key]?.Treatment === "" || key >= initialOperatoryOptionLength
                    ? false
                    : true
                }
              >
                <option value="" selected={doctorTreatmentRightback[key]?.Treatment === ""}>
                  Select Treatment
                </option>
                <option
                  value="Hygiene"
                  selected={doctorTreatmentRightback[key]?.Treatment === "Hygiene"}
                >
                  Hygiene
                </option>

                <option
                  value="Consultation"
                  selected={doctorTreatmentRightback[key]?.Treatment === "Consultation"}
                >
                  Consultation
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          appointmentRightBack
            ? "box-col col-xs-2 col-md-2 col-lg-2 col-xl-2 locationInfo-col"
            : "d-none"
        }
      >
        <div className="form-group">
          {key === 0 && <p className="p-0 icon-label">Sync appointments to PMS</p>}

          <div className="w-100 multiselect-row pb-0 mb-3">
            <div className="w-100 multiselect-col-1 d-flex justify-content-center">
              <input
                type="checkbox"
                style={{ width: "1.2rem" }}
                className="form-control my-auto pr-1 "
                id="primaryLocation"
                name="ApptWriteBackCheckbox"
                checked={OptOptions[key].AllowApptWriteBack}
                onChange={(e) => handleDoctorProcedure(e, key, e.target.name)}
              />
              {!IsOPSyncAutomatically && (
                <button type="button" className="btn ml-3" onClick={(e) => handleRemoveRow(e, key)}>
                  &#10006;
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} /> <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
export default React.memo(RowSection);
