import React, { useState, useEffect } from "react";
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import RemoveMemberModal from "../models/RemoveMemberModal";
import TopNavBar from "../common-components/TopNavbar";
import { Row, Col } from "reactstrap";
import AppPageLoader from "../common-components/AppPageLoader";

import Api from "../API/team-members";
import Pagination from "../common-components/Pagination";
import TeamMemberTableTopSection from "./TeamMemberTableTopSection";
import TableTopSection from "./TableTopSection";
import TeamMemberTable from "./TeamMemberTable";
import AddEditMemberModal from "../models/AddEditMemberModal";

const TeamMember = () => {
  //get location id from state using useLocation hook
  const location = useLocation();
  const [showAddEditMemberModal, setShowAddEditMemberModal] = React.useState(false);
  const [removeMemberModalShow, setRemoveMemberModal] = React.useState(false);
  const [teamMeambers, setTeamMeambers] = React.useState([]);

  const [loader, setLoader] = React.useState(true);
  const [isAllSelected, setIsAllSelected] = React.useState(false);

  const [searchString, setSearchString] = React.useState("");

  const [filters, setFilters] = React.useState({
    UserId: 0,
    PageIndex: 1,
    PageSize: 10,
    SearchText: "",
    SortColumn: 0,
    SortDirection: 0,
    LocationBy: 0,
    ProviderTypefilter: 0,
    IncludeUser: true,
  });

  const [pages, setPages] = React.useState(0);

  const [sortingColumn, setSortingColumn] = React.useState({
    lastName: { id: 2, sortDir: 0 },
    firstName: { id: 1, sortDir: 0 },
    email: { id: 3, sortDir: 0 },
    speciality: { id: 4, sortDir: 0 },
    location: { id: 2, sortDir: 0 },
  });

  const [addresses, setAddresses] = useState({
    State: {
      Id: 0,
      Name: "",
      Code: "",
    },
    Country: {
      Id: 0,
      Name: "",
      Code: "",
      ISOCode: "",
    },
    Id: 0,
    Name: "",
    Address1: "",
    Address2: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    ContactType: 0,
    NPINumber: "",
  });

  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const addEditBtnClicked = (id, type) => {
    setSelectedMemberId(type === "edit" ? id : 0);
    setShowAddEditMemberModal(true);
  };
  const deletBtnClicked = (id) => {
    setSelectedMemberId(id);
    setRemoveMemberModal(true);
  };

  const setCurrentSortColumn = (column) => {
    if (column) {
      let data = {
        lastName: { id: 2, sortDir: 0 },
        firstName: { id: 1, sortDir: 0 },
        email: { id: 3, sortDir: 0 },
        speciality: { id: 4, sortDir: 0 },
        location: { id: 2, sortDir: 0 },
      };

      if (sortingColumn[column].sortDir === 2 || sortingColumn[column].sortDir === 0) {
        data[column].sortDir = 1;
      } else {
        data[column].sortDir = 2;
      }

      const payLoadData = {
        ...filters,
        SortColumn: data[column].id,
        SortDirection: data[column].sortDir,
      };
      setFilters((filters) => ({
        ...filters,
        SortColumn: data[column].id,
        SortDirection: data[column].sortDir,
      }));
      setSortingColumn(data);
      GetTeamMemberApiCall(payLoadData);
    }
  };

  const onEditMemberModal = (isRelod) => {
    setSelectedMemberId(null);
    setShowAddEditMemberModal(false);
    if (isRelod) {
      getTeamMembers(filters.PageIndex, filters.PageSize);
    }
  };

  const onRemoveMemberModal = (isRelod) => {
    setSelectedMemberId(null);
    setRemoveMemberModal(false);
    if (isRelod) {
      getTeamMembers(filters.PageIndex, filters.PageSize);
    }
  };

  const locationChange = (event) => {
    event.preventDefault();
    setFilters({ ...filters, PageIndex: 1, LocationBy: event.target.value });
    const payLoad = { ...filters, PageIndex: 1, LocationBy: event.target.value };
    GetTeamMemberApiCall(payLoad);
  };

  const memberTypeChange = (event) => {
    event.preventDefault();
    setFilters({
      ...filters,
      PageIndex: 1,
      ProviderTypefilter: parseInt(event.target.value),
    });
    const payLoad = {
      ...filters,
      PageIndex: 1,
      ProviderTypefilter: parseInt(event.target.value),
    };
    GetTeamMemberApiCall(payLoad);
  };

  const searchStringChange = (event) => {
    event.preventDefault();
    setSearchString(event.target.value);
  };

  const searchForTextValue = (event) => {
    event.preventDefault();
    setFilters({
      ...filters,
      PageIndex: 1,
      ProviderTypefilter: 0,
      LocationBy: 0,
      SearchText: searchString,
    });
    const payLoad = {
      ...filters,
      PageIndex: 1,
      ProviderTypefilter: 0,
      LocationBy: 0,
      SearchText: searchString,
    };
    GetTeamMemberApiCall(payLoad);
  };

  const getAddress = (id) => {
    if (id > 0) {
      Api.getAddress(id)
        .then((res) => {
          let result = res.data.Result;
          if (result.length > 0) {
            result.sort((a, b) => b.ContactType - a.ContactType);
          }
          setAddresses(result);
        })
        .catch((error) => {});
    }
  };

  const [totalRecords, setTotalRecords] = useState(0);

  const getTeamMembers = (PageIndex, itemsPerPage) => {
    const payLoad = {
      ...filters,
      PageIndex: !PageIndex ? 1 : PageIndex,
      PageSize: !itemsPerPage ? 10 : itemsPerPage,
    };
    setFilters({ ...filters, PageIndex });
    GetTeamMemberApiCall(payLoad);
  };

  const GetTeamMemberApiCall = (payLoad) => {
    setLoader(true);
    setTeamMeambers([]);
    setIsAllSelected(false);
    Api.getTeamMembers(payLoad)
      .then((resp) => {
        setTeamMeambers(resp.data);
        let totalNumberOfRecord = resp.data[0].TotalCount;
        setTotalRecords(totalNumberOfRecord);
        let pages = 0;
        if (totalNumberOfRecord) {
          pages = Math.ceil(totalNumberOfRecord / filters.PageSize);
        }
        setPages(pages);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const selectUnSelectAllRecords = (event) => {
    let newTeamMembers = teamMeambers.map((element) => ({
      ...element,
      isSelected: event.target.checked,
    }));
    setTeamMeambers(newTeamMembers);
    setIsAllSelected(event.target.checked);
  };

  const selectUnSelectRecords = (index) => {
    let TeamMemberIds = [];

    let data = [...teamMeambers];
    data[index].isSelected = !data[index].isSelected;
    setTeamMeambers(data);

    for (let index = 0; index < teamMeambers.length; index++) {
      const element = teamMeambers[index];
      if (element.isSelected) {
        TeamMemberIds.push(element.TeamMemberId);
      }
    }

    if (teamMeambers.length === TeamMemberIds.length) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  };

  const deleteSelectedRecords = () => {
    let TeamMemberIds = [];
    for (let index = 0; index < teamMeambers.length; index++) {
      const element = teamMeambers[index];
      if (element.isSelected) {
        TeamMemberIds.push(element.TeamMemberId);
      }
    }
    if (TeamMemberIds.length > 0) {
      let payLoad = { TeamMemberId: TeamMemberIds };
      Api.removeTeamMemer(payLoad)
        .then(() => {
          toast.success("Remove successfully!");
          getTeamMembers();
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    } else {
      toast.info("Select at least team member!");
    }
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userData")));
    if (user.UserId !== 0) {
      getAddress(user.UserId);
      setFilters({
        ...filters,
        UserId: user.UserId,
        LocationBy: location.state && location.state.locationId ? location.state.locationId : 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.UserId, location]);

  useEffect(() => {
    getTeamMembers(1, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = [];
    for (let index = 0; index < teamMeambers.length; index++) {
      let item = teamMeambers[index];
      if (isAllSelected) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      data.push(item);
    }
    setTeamMeambers(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllSelected]);

  return (
    <div className="main-wrapper setting-wrapper ">
      <AppSidebarNavbar />
      <main>
        <div className="app-container">
          <div className="app-content">
            <TopNavBar />
            <div className="setting-page team-member-page position-relative">
              <div className="white-container coming-soon">
                <div className="w-100 setting-menu">
                  <Row>
                    <Col sm="12">
                      <div className="billing-page">
                        <div className="grid-section">
                          <div className="white-container p-0">
                            <TeamMemberTableTopSection
                              filters={filters}
                              locationChange={locationChange}
                              addresses={addresses}
                              memberTypeChange={memberTypeChange}
                              searchForTextValue={searchForTextValue}
                              searchString={searchString}
                              searchStringChange={searchStringChange}
                            />

                            <TableTopSection
                              pages={pages}
                              filters={filters}
                              addEditBtnClicked={addEditBtnClicked}
                              teamMeambers={teamMeambers}
                              deleteSelectedRecords={deleteSelectedRecords}
                            />

                            <TeamMemberTable
                              selectUnSelectAllRecords={selectUnSelectAllRecords}
                              isAllSelected={isAllSelected}
                              sortingColumn={sortingColumn}
                              setCurrentSortColumn={setCurrentSortColumn}
                              teamMeambers={teamMeambers}
                              selectUnSelectRecords={selectUnSelectRecords}
                              addEditBtnClicked={addEditBtnClicked}
                              deletBtnClicked={deletBtnClicked}
                            />

                            {pages !== 0 && teamMeambers?.length > 0 && (
                              <Pagination
                                parentFilters={filters}
                                totalRecords={totalRecords}
                                pages={pages}
                                onPageClickFunction={getTeamMembers}
                                itemsPerPage={10}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              {loader && <AppPageLoader />}
            </div>
          </div>
        </div>
      </main>

      <AddEditMemberModal
        show={showAddEditMemberModal}
        memeberid={selectedMemberId}
        onHide={onEditMemberModal}
      />

      <RemoveMemberModal
        show={removeMemberModalShow}
        memeberid={selectedMemberId}
        onHide={onRemoveMemberModal}
      />
    </div>
  );
};
export default TeamMember;
