import React, { useState, useEffect } from "react";

import classnames from "classnames";
import { TabContent } from "reactstrap";
import { validateForm, goToSignin } from "../config/commonFun";
import Api from "../API/setting";
import { toast } from "react-toastify";
import { validEmailRegex, validPasswordRegex } from "../config/appRegex";
import { localStorageUserData } from "../config/appConstatnts";
import ChangePassword from "./ChangePassword";
import UpdateAccount from "./UpdateAccount";
import AccountSettingsNavTab from "./AccountSettingsNavTab";
import AccountSettingsLayout from "./AccountSettingsLayout";

const AccountSetting = () => {
  const [curPasswordShown, setCurPasswordShown] = useState(false);
  const [conPasswordShown, setConPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  function eyeToggleNew(event) {
    setNewPasswordShown(newPasswordShown ? false : true);
  }
  function eyeToggleCur(event) {
    setCurPasswordShown(curPasswordShown ? false : true);
  }
  function eyeToggleCon(event) {
    setConPasswordShown(conPasswordShown ? false : true);
  }

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [btnLoader, setBtnLoader] = useState(false);
  const [userPassword, setUserPassword] = useState({
    CurrentPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  });
  const [passErrors, setPassErrors] = useState({
    CurrentPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  });

  const [loaderForProfile, setLoaderForProfile] = useState(false);
  const handelPasswordChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    let errorValue = "";
    setUserPassword((userPassword) => ({
      ...userPassword,
      [name]: value,
    }));
    switch (name) {
      case "CurrentPassword":
        if (value.trim().length === 0) {
          errorValue = "Old password is required.";
        }
        break;
      case "NewPassword":
        if (value.trim().length === 0) {
          errorValue = "Password is required.";
        } else {
          errorValue = !validPasswordRegex.test(value)
            ? "Password must be a minimum of 8 characters long and contain an upper and lower case letter, a number, and a symbol. "
            : "";
        }
        break;
      case "ConfirmPassword":
        if (value.trim().length === 0) {
          errorValue = "Confirm Password is required.";
        } else {
          errorValue =
            userPassword.NewPassword !== value
              ? "Confirm password doesn't match with password"
              : "";
        }
        break;
      default:
        break;
    }
    setPassErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelCPasswordSubmit = (event) => {
    let flagError = false;
    if (userPassword.CurrentPassword.trim().length === 0) {
      flagError = true;
      setPassErrors((errors) => ({
        ...errors,
        CurrentPassword: "Old password is required.",
      }));
    }
    if (userPassword.NewPassword.trim().length === 0) {
      flagError = true;

      setPassErrors((errors) => ({
        ...errors,
        NewPassword: "Password is required.",
      }));
    } else if (!validPasswordRegex.test(userPassword.NewPassword)) {
      flagError = true;

      setPassErrors((errors) => ({
        ...errors,
        NewPassword:
          "Password must be a minimum of 8 characters long and contain an upper and lower case letter, a number, and a symbol.",
      }));
    }
    if (userPassword.ConfirmPassword.trim().length === 0) {
      flagError = true;
      setPassErrors((errors) => ({
        ...errors,
        ConfirmPassword: "Confirm Password is required.",
      }));
    } else if (userPassword.NewPassword !== userPassword.ConfirmPassword) {
      flagError = true;
      setPassErrors((errors) => ({
        ...errors,
        ConfirmPassword: "Confirm password doesn't match with password.",
      }));
    }
    event.preventDefault();
    if (validateForm(passErrors) && !flagError) {
      setBtnLoader(true);
      setLoaderForProfile(true);
      Api.postChangePassword(userPassword)
        .then((res) => {
          setBtnLoader(false);
          toast.success(res.data.Message);
          localStorage.removeItem("token");
          localStorage.removeItem("userData");
          setTimeout(() => {
            goToSignin();
          }, 2000);
        })
        .catch((error) => {
          setBtnLoader(false);
          if (error.response) {
            toast.error(error.response.data.Message);
          }
        })
        .finally(() => setLoaderForProfile(false));
    }
  };

  const [userInformation, setUserInformation] = useState({
    UserId: 0,
    UserName: "",
    FirstName: "",
    LastName: "",
    Email: "",
    SecondaryEmail: "",
  });

  const [updatedUserInformation, setUpdatedUserInformation] = useState({
    UserId: 0,
    UserName: "",
    FirstName: "",
    LastName: "",
    Email: "",
    SecondaryEmail: "",
  });

  const [userInformationError, setUserInformationError] = useState({
    UserName: "",
    FirstName: "",
    LastName: "",
    Email: "",
    SecondaryEmail: "",
  });

  const resetInformation = () => {
    setUpdatedUserInformation({ ...userInformation });
  };

  const [userDetailsError, setUserDetailsError] = useState("");
  const getUserDetails = (id) => {
    setLoaderForProfile(true);
    Api.getUserDetails(id)
      .then((res) => {
        setLoaderForProfile(false);
        if (res.status === 200) {
          setUserInformation(res.data.Result);
          setUpdatedUserInformation({ ...res.data.Result });
        }
      })
      .catch((err) => {
        setLoaderForProfile(false);
        setUserDetailsError(err.response.data.Message);
      });
  };

  const handelAccountSettingSubmit = (event) => {
    event.preventDefault();
    let errorFlag = false;
    if (updatedUserInformation.UserName.length === 0) {
      setUserInformationError((error) => ({
        ...error,
        UserName: "User Name is required.",
      }));
      errorFlag = true;
    }

    if (updatedUserInformation.FirstName.length === 0) {
      setUserInformationError((error) => ({
        ...error,
        FirstName: "First Name is required.",
      }));
      errorFlag = true;
    }
    if (updatedUserInformation.LastName.length === 0) {
      setUserInformationError((error) => ({
        ...error,
        LastName: "Last Name is required.",
      }));
      errorFlag = true;
    }
    if (updatedUserInformation.Email.length === 0) {
      setUserInformationError((error) => ({
        ...error,
        Email: "Primary Email is required.",
      }));
      errorFlag = true;
    }

    if (!validEmailRegex.test(updatedUserInformation.Email)) {
      setUserInformationError((error) => ({
        ...error,
        Email: "Enter valid primary email.",
      }));
      errorFlag = true;
    }

    if (
      updatedUserInformation.SecondaryEmail.length > 0 &&
      !validEmailRegex.test(updatedUserInformation.SecondaryEmail)
    ) {
      setUserInformationError((error) => ({
        ...error,
        SecondaryEmail: "Enter valid email.",
      }));
      errorFlag = true;
    }

    if (validateForm(userInformationError) && !errorFlag) {
      setBtnLoader(true);
      setLoaderForProfile(true);
      Api.postUserDetails(updatedUserInformation)
        .then((res) => {
          let user = JSON.parse(localStorage.getItem("userData"));
          user.FirstName = updatedUserInformation.FirstName;
          user.LastName = updatedUserInformation.LastName;
          user.Username = updatedUserInformation.UserName;
          user.Email = updatedUserInformation.Email;
          localStorage.setItem("userData", JSON.stringify(user));
          setBtnLoader(false);
          toast.success(res.data.Message);
        })
        .catch((error) => {
          setBtnLoader(false);
          if (error.response) {
            toast.error(error.response.data.Message);
          }
        })
        .finally(() => setLoaderForProfile(false));
    }
  };

  const handelAccountSettingChangeEvent = (event) => {
    event.persist();
    var { name, value } = event.target;
    value = value.trim();
    setUpdatedUserInformation((updatedUserInformation) => ({
      ...updatedUserInformation,
      [name]: value,
    }));

    var errorMsg = "";
    switch (name) {
      case "UserName":
        if (value.length === 0) {
          errorMsg = "User Name is required.";
        }
        break;
      case "FirstName":
        if (value.length === 0) {
          errorMsg = "First Name is required.";
        }
        break;
      case "LastName":
        if (value.length === 0) {
          errorMsg = "Last Name is required.";
        }
        break;
      case "Email":
        if (value.length === 0) {
          errorMsg = "Primary Email is required.";
        } else if (!validEmailRegex.test(value)) {
          errorMsg = "Enter valid primary email.";
        }
        break;
      case "SecondaryEmail":
        if (value.length > 0 && !validEmailRegex.test(value)) {
          errorMsg = "Enter valid email.";
        }
        break;
      default:
        break;
    }

    setUserInformationError((userInformationError) => ({
      ...userInformationError,
      [name]: errorMsg,
    }));
  };

  useEffect(() => {
    if (
      localStorageUserData != null &&
      localStorageUserData.UserId &&
      localStorageUserData.UserId > 0
    ) {
      setUserInformation((userInformation) => ({
        ...userInformation,
        UserId: localStorageUserData.UserId,
      }));
    }
  }, []);

  useEffect(() => {
    if (userInformation.UserId && userInformation.UserId > 0) {
      getUserDetails(userInformation.UserId);
    }
  }, [userInformation.UserId]);

  return (
    <AccountSettingsLayout>
      <AccountSettingsNavTab classnames={classnames} activeTab={activeTab} toggle={toggle} />
      <TabContent activeTab={activeTab}>
        <h6 className="text-danger">{userDetailsError}</h6>
        <UpdateAccount
          handelAccountSettingSubmit={handelAccountSettingSubmit}
          updatedUserInformation={updatedUserInformation}
          handelAccountSettingChangeEvent={handelAccountSettingChangeEvent}
          userInformationError={userInformationError}
          resetInformation={resetInformation}
          btnLoader={btnLoader}
          loaderForProfile={loaderForProfile}
        />

        <ChangePassword
          handelCPasswordSubmit={handelCPasswordSubmit}
          curPasswordShown={curPasswordShown}
          handelPasswordChange={handelPasswordChange}
          userPassword={userPassword}
          eyeToggleCur={eyeToggleCur}
          passErrors={passErrors}
          newPasswordShown={newPasswordShown}
          eyeToggleNew={eyeToggleNew}
          conPasswordShown={conPasswordShown}
          eyeToggleCon={eyeToggleCon}
          btnLoader={btnLoader}
        />
      </TabContent>
    </AccountSettingsLayout>
  );
};

export default AccountSetting;
