import React from "react";
import ReactDOM from 'react-dom';
import { Modal, Button } from "react-bootstrap";
import TextareaCounter from 'react-textarea-counter';

//component import
import * as inputFun from '../common-components/FormInputFunctions';
import { Form } from 'react-bootstrap';

export default function EditProfessionalMembershipModal(props) {
    const [LocationAisActive, SetLocationAState] = React.useState(false);
    
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Edit Professional Memberships
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row">
              
              <div className="col-sm-12">
                <div class="form-group app-ctrl ">
                  <label class="required">After saving this association, click add button to enter new association </label>
                  <div className="app-textcounter">
                    <TextareaCounter countLimit={272} showCount={true} initialValue="American Geriatric Society" rows={4} />
                  </div>
                </div>
              </div>
              
            </div>
            <p className="text-danger note-text mb-2">Type your own association if it is not listed in auto complete. e.g.: American Geriatric Society.</p>
            <div className="btn-box d-flex">
              <button type="button" className="btn app-btn lightgray-btn large-btn mr-2" onClick={props.onHide}>cancel</button>
              <button type="button" className="btn app-btn large-btn ">Update</button>
            </div>

          </form>
        </Modal.Body>

      </Modal>
    </div>
  );
}


