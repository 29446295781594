import { history } from "../history";
// Check is error or not
export const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

// Redirect to forgot password
export const gotoForgotPassword = () => {
  history.push("/forgotpassword");
};
// Redirect to sign up
export const gotoSignUp = () => {
  history.push("/signup");
};
// Redirect to dashboard
export const gotoDashboard = () => {
  history.push("/dashboard");
};

// redirect to signup
export const goToSignin = () => {
  history.push("/signin");
};

// capitalizeWord
export const capitalizedWord = (CompanyName) =>
  CompanyName.charAt(0).toUpperCase() + CompanyName.slice(1);

// give difference of two date
export const getDifferenceInDays = (startDate, endDate) => {
  const diffInMs = Math.abs(startDate - endDate);
  return diffInMs / (1000 * 60 * 60 * 24);
};

// for start date and end date for months
export const defaultFromDate = () => {
  let today = new Date();
  return (
    today.getFullYear() +
    "-" +
    ("0" + today.getMonth()).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2)
  );
};

export const defaultToDate = () => {
  let today = new Date();
  return (
    today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2)
  );
};

export const removeDeletedGhlId = (rlMappedId, ghlId) => {
  const pushDataIntoArray = [];
  if (rlMappedId && rlMappedId.length > 0 && ghlId.length > 0) {
    for (const calendaroptidKey in rlMappedId) {
      const element = rlMappedId[calendaroptidKey];
      if (
        ghlId.length > 0 &&
        element !== "Not Sync" &&
        element !== "0" &&
        element !== "1" &&
        element.length > 0
      ) {
        !ghlId.includes(element) && pushDataIntoArray.push(element);
      }
    }
  }
  return pushDataIntoArray;
};
