import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";

import { validateForm } from "../config/appRegex";
//component import
import Api from "../API/profile";
import { toast } from "react-toastify";
import AppPageLoader from "../common-components/AppPageLoader";

export default function AddEducationInfomationModel(props) {
  const [loader, setLoader] = useState(false);
  const [university, setUniversity] = useState([]);
  const [emptyRecordMsg, setEmptyRecordMsg] = useState("Please enter 1 or more characters");
  const [education, setEducation] = useState({
    Id: 0,
    Institute: "",
    Specialisation: "",
    YearAttended: new Date().getFullYear(),
  });
  const [errors, setErrors] = useState({
    Institute: "",
    Specialisation: "",
    YearAttended: "",
  });

  const [preEdu, setPreEdu] = useState("");
  const year = new Date().getFullYear();
  const years = Array.from(new Array(50), (val, index) => year - index);
  useEffect(() => {
    if (props.selectedEdu > 0) {
      Api.getEducation(props.selectedEdu)
        .then((res) => {
          setEducation(res.data);
          Api.getSearchUniversityNames(res.data.Institute)
            .then((res) => {
              //setUniversity(res.data);
              if (res != null && res.data != null && res.data.length > 0) {
                let insuData = [];
                for (let i = 0; i < res.data.length; i++) {
                  insuData.push({
                    value: res.data[i].id,
                    label: res.data[i].text,
                  });
                }
                setUniversity(insuData);
                setPreEdu(insuData);
              }
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    } else {
      setPreEdu("");
      setEducation({
        Id: 0,
        Institute: "",
        Specialisation: "",
        YearAttended: new Date().getFullYear(),
      });
    }
  }, [props.selectedEdu]);

  const handelSearch = (searchText) => {
    if (searchText.length > 0) {
      setEmptyRecordMsg("No options available");
      Api.getSearchUniversityNames(searchText)
        .then((res) => {
          let insuData = [];
          for (let i = 0; i < res.data.length; i++) {
            insuData.push({
              value: res.data[i].id,
              label: res.data[i].text,
            });
          }
          setUniversity(insuData);
        })
        .catch((error) => {});
    } else {
      setEmptyRecordMsg("Please enter 1 or more characters");
    }
  };
  const onSelect = (seleList) => {
    setPreEdu(seleList);
    setEducation((education) => ({
      ...education,
      Institute: seleList.value,
    }));
    setErrors((errors) => ({
      ...errors,
      Institute: "",
    }));
  };

  const handelChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    let errorValue = "";
    setEducation((education) => ({
      ...education,
      [name]: value,
    }));

    switch (name) {
      case "Specialisation":
        errorValue = value.length === 0 ? "Specialisation is required." : "";
        break;
      default:
        break;
    }
    setErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelSubmit = (event) => {
    let flagError = false;
    if (education.Institute.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Institute: "Institute is required.",
      }));
      flagError = true;
    }
    if (education.Specialisation.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Specialisation: "Specialisation is required.",
      }));
      flagError = true;
    }
    event.preventDefault();
    if (validateForm(errors) && !flagError) {
      setLoader(true);
      Api.postEducation(education)
        .then((res) => {
          props.onHide();
          if (education.Id > 0) {
            toast.success("Education updated successfully!");
          } else {
            toast.success("Education inserted Successfully!");
          }
          props.reload();
        })
        .catch((error) => {
          toast.error("Something went wrong");
        })
        .finally(() => setLoader(false));
    }
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            {props.selectedEdu > 0 ? "Edit" : "Add"} Education/Training Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form onSubmit={handelSubmit}>
            <div className="row ">
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <div className="app-custom-select">
                    <label class="required">Institution Attended</label>
                    <Select
                      options={university}
                      placeholder="Search Institution"
                      onChange={onSelect}
                      onInputChange={handelSearch}
                      value={preEdu}
                    />
                  </div>

                  {errors.Institute.length > 0 && (
                    <span className="err-message">{errors.Institute}</span>
                  )}
                </div>
              </div>
              <div className=" col-md-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Field of Study/degree</label>
                  <input
                    type="text"
                    className="form-control"
                    value={education.Specialisation}
                    name="Specialisation"
                    onChange={handelChange}
                  />
                  {errors.Specialisation.length > 0 && (
                    <span className="err-message">{errors.Specialisation}</span>
                  )}
                </div>
              </div>
              <div className=" col-12 setting-menu">
                <div className="form-group app-ctrl app-select">
                  <label className="required">Last Year Attended</label>
                  <select
                    className="form-control light-border"
                    value={education.YearAttended}
                    name="YearAttended"
                    onChange={handelChange}
                  >
                    {years.map((year, index) => {
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn large-btn ">
                <div className="button-inner">
                  {loader && <AppPageLoader className={"position-fixed"} />}
                  <span className="btn-text">Save</span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
