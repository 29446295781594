import React from "react";

import NoteEditIcon from "../assets/images/noteEdit-icon-black.svg";

export default function Calendly() {
  return (
    <div className="box-col col-xs-12 col-sm-12 col-lg-12 col-xl-9">
      <div className="app-box h-auto info-box">
        <div className="left-box">
          <h2 className="info-title">Pay for an expert! </h2>
          <p>
            Need a little assistance with installing the integration tool? No problem! We have
            experts who can save your time, and get you up and going quickly!
          </p>
        </div>

        <div className="right-box">
          <div className="amt-info">
            <img src={NoteEditIcon} alt="Icon" />
            <strong>$49</strong>
            <span>One time</span>
          </div>
          <div className="btn-box">
            <a
              href="https://calendly.com/drdds/paidintegration"
              target="_blank"
              rel="noopener noreferrer"
              className="btn app-btn small-btn text-uppercase"
            >
              Schedule Your Full Service Integration Here
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
