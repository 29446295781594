import axios from "axios";
import { API_URL, HEADER, HEADERMultipart } from "../config/appConstatnts";

class Api {
  // Api fro user profile information.
  static getProfile = (userId) => {
    return axios.get(API_URL + "/Profile/Get?UserId=" + userId, HEADER);
  };

  // Api fro user profile information.
  static getProfileDetails = (userId) => {
    return axios.get(API_URL + "/Userprofile/Get/" + userId, HEADER);
  };

  // Api for specialitys list.
  static getSpecialities = () => {
    return axios.get(API_URL + "/Verident/GetSpecialities?IsSignUP=false", HEADER);
  };
  // updateDesciption
  static updateDesciption = (data) => {
    let model = {
      Text: data,
    };
    return axios.post(API_URL + "/Profile/AddDescription", model, HEADER);
  };

  // Api for get user speciality list.
  static getSelectedSpecialty = (userId) => {
    return axios.get(API_URL + "/Specialty/Get/" + userId, HEADER);
  };

  // Api for get user addressies.
  static getAddress = (userId) => {
    return axios.get(API_URL + "/Address/Get/" + userId, HEADER);
  };

  static getTimeZone = () => {
    return axios.get(API_URL + "/Address/GetTimeZone", HEADER);
  };

  static postAddress = (model) => {
    let data = {
      Timezone: model.Timezone,
      State: model.State.Code,
      Country: "US",
      Id: model.Id,
      Name: model.Name,
      Address1: model.Address1,
      Address2: model.Address2,
      City: model.City,
      ZipCode: model.ZipCode,
      Email: model.Email,
      Phone: model.Phone,
      Fax: model.Fax,
      ContactType: model.ContactType,
      NPINumber: model.NPINumber,
    };
    return axios.post(API_URL + "/Address/Post/", data, HEADER);
  };

  // Api for save User Data.
  static postUserData = (model) => {
    return axios.post(API_URL + "/Userprofile/Post", model, HEADER);
  };

  // Api for save PublicProfileSection.
  static PublicProfileSection = (model) => {
    return axios.post(API_URL + "/Profile/PublicProfileSection", model, HEADER);
  };

  // Get State
  static getState = (countryId) => {
    return axios.get(API_URL + "/State/Get/" + countryId, HEADER);
  };

  //  post image
  static postProfileImage = (data) => {
    return axios.post(API_URL + "/UserProfile/UploadProfileImage", data, HEADERMultipart);
  };

  static getSearchUniversityNames = (search) => {
    return axios.get(API_URL + "/Profile/SearchUniversityNames?UniversityNames=" + search, HEADER);
  };

  static getEducation = (id) => {
    return axios.get(API_URL + "/Profile/GetEducationDetails?id=" + id, HEADER);
  };

  static postEducation = (model) => {
    let obj = {
      Id: model.Id,
      Institute: model.Institute,
      Specialisation: model.Specialisation,
      YearAttended: model.YearAttended,
    };
    return axios.post(API_URL + "/Profile/AddEducationAndTraining", obj, HEADER);
  };

  static postImageGallery = (data) => {
    return axios.post(API_URL + "/database/UploadGalleryImage", data, HEADERMultipart);
  };

  static postImageBackGround = (data) => {
    return axios.post(API_URL + "/database/UploadBackGroundImage", data, HEADERMultipart);
  };

  static postProfileImage = (data) => {
    return axios.post(API_URL + "/UserProfile/UploadProfileImage", data, HEADERMultipart);
  };

  static getWebSiteDetail = (id) => {
    return axios.get(API_URL + "/Profile/GetWebSiteDetail?Id=" + id, HEADER);
  };

  static deleteWebSiteDetail = (id) => {
    return axios.post(API_URL + "/Profile/RemoveWebsite?id=" + id, "", HEADER);
  };

  static deleteEducation = (id) => {
    return axios.post(API_URL + "/Profile/RemoveEduction?id=" + id, "", HEADER);
  };
  static postWebSiteDetail = (data) => {
    let model = {
      SecondaryWebsiteId: data.SecondaryWebsiteId,
      SecondaryWebsiteurl: data.SecondaryWebsiteurl,
    };
    return axios.post(API_URL + "/Profile/AddWebSite", model, HEADER);
  };

  static postMembership = (data) => {
    return axios.post(API_URL + "/database/ManageProfessionalMembership", data, HEADER);
  };

  static deleteMembership = (id) => {
    return axios.post(
      API_URL + "/database/DeleteProfessionalMembership",
      {
        Id: id,
      },
      HEADER
    );
  };

  static getProcedure = () => {
    return axios.get(API_URL + "/Integration/Procedures", HEADER);
  };
  static postProcedure = (data) => {
    return axios.post(API_URL + "/Integration/Procedures/Update", data, HEADER);
  };

  static deleteGallaryImage = (id) => {
    return axios.post(API_URL + "/database/deleteGallaryImage?Id=" + id, "", HEADER);
  };

  static deleteBackgroundImage = (id) => {
    return axios.post(API_URL + "/database/deleteBackgroundImage?Id=" + id, "", HEADER);
  };
  // static getProcedure = () => {
  //   return axios.get(API_URL + "/database/GetProcedures", HEADER);
  // };
  static getSocialMedia = () => {
    return axios.get(API_URL + "/Profile/GetSocialMedia", HEADER);
  };

  static postSocialMedia = (model) => {
    let obj = {
      FacebookUrl: model.FacebookUrl,
      LinkedinUrl: model.LinkedinUrl,
      TwitterUrl: model.TwitterUrl,
      GoogleplusUrl: model.GoogleplusUrl,
      YoutubeUrl: model.YoutubeUrl,
      PinterestUrl: model.PinterestUrl,
      BlogUrl: model.BlogUrl,
      InstagramUrl: model.InstagramUrl,
      YelpUrl: model.YelpUrl,
    };
    return axios.post(API_URL + "/Profile/AddSocialMedia", obj, HEADER);
  };

  static getInsurance = () => {
    return axios.get(API_URL + "/database/GetInsurance", HEADER);
  };

  static postInsurance = (data) => {
    return axios.post(API_URL + "/database/MemberInsurance", data, HEADER);
  };

  static removeLocation = (id) => {
    return axios.post(API_URL + `/Profile/RemoveAddress?Id=${id}`, HEADER);
  };
}

export default Api;
