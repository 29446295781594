import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import Api from "../API/profile";
import { Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const ProfileSection = (props) => {
  const [loader, setLoader] = React.useState(false);

  const [postUserSection, setPostUserSection] = React.useState({
    SectionId: 0,
    UserId: 0,
    PatientForms: false,
    SpecialOffers: false,
    ReferPatient: false,
    Reviews: false,
    AppointmentBooking: false,
    PatientLogin: false,
    CreatedBy: 0,
    ModifiedBy: 0,
  });
  useEffect(() => {
    let data = props.userData;
    let fillData = postUserSection;
    fillData.PatientForms = data.PatientForms;
    fillData.SpecialOffers = data.SpecialOffers;
    fillData.ReferPatient = data.ReferPatient;
    fillData.Reviews = data.Reviews;
    fillData.AppointmentBooking = data.AppointmentBooking;
    fillData.PatientLogin = data.PatientLogin;
    fillData.SectionId = data.SectionID;
    fillData.UserId = props.UserId;
    fillData.CreatedBy = props.UserId;
    fillData.ModifiedBy = props.UserId;
    setPostUserSection(fillData);
  }, [props.userData, props.UserId, postUserSection]);

  const handelChange = (event) => {
    event.persist();
    let { name, checked } = event.target;
    setPostUserSection((userData) => ({
      ...userData,
      [name]: checked,
    }));
  };
  const handelSubmit = (event) => {
    event.preventDefault();
    if (props.UserId > 0) {
      setLoader(true);
      Api.PublicProfileSection(postUserSection)
        .then((res) => {
          setLoader(false);
          props.onHide();
          toast.success("Public Profile Section updated .");
          props.reload();
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Something went wrong!");
        });
    }
  };
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Public Profile Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form onSubmit={handelSubmit}>
            <div className="row ">
              <div className=" col-12 ">
                <p>Choose your section you would like to display on your profile</p>
                <div className="section-list mt-4 mb-3">
                  <div className="list head-list">
                    <span>Section Name</span>
                    <span>Visible at public profile?</span>
                  </div>
                  <div className="list">
                    <span>New Patients</span>
                    <span>
                      <Form.Check
                        type="switch"
                        id="new-patients-switch"
                        className="app-switch"
                        name="PatientForms"
                        defaultChecked={postUserSection.PatientForms}
                        onChange={handelChange}
                      />
                    </span>
                  </div>
                  <div className="list">
                    <span>Appointment</span>
                    <span>
                      <Form.Check
                        type="switch"
                        id="new-AppointmentBooking-switch"
                        className="app-switch"
                        name="AppointmentBooking"
                        defaultChecked={postUserSection.AppointmentBooking}
                        onChange={handelChange}
                      />
                    </span>
                  </div>
                  <div className="list">
                    <span>Patient Login</span>
                    <span>
                      <Form.Check
                        type="switch"
                        id="new-PatientLogin-switch"
                        className="app-switch"
                        name="PatientLogin"
                        defaultChecked={postUserSection.PatientLogin}
                        onChange={handelChange}
                      />
                    </span>
                  </div>
                  <div className="list">
                    <span>Refer a Patient</span>
                    <span>
                      <Form.Check
                        type="switch"
                        id="new-ReferPatient-switch"
                        className="app-switch"
                        name="ReferPatient"
                        defaultChecked={postUserSection.ReferPatient}
                        onChange={handelChange}
                      />
                    </span>
                  </div>
                  <div className="list">
                    <span>Special Offers</span>
                    <span>
                      <Form.Check
                        type="switch"
                        id="new-SpecialOffers-switch"
                        className="app-switch"
                        name="SpecialOffers"
                        defaultChecked={postUserSection.SpecialOffers}
                        onChange={handelChange}
                      />
                    </span>
                  </div>

                  <div className="list">
                    <span>Reviews</span>
                    <span>
                      <Form.Check
                        type="switch"
                        id="new-Reviews-switch"
                        name="Reviews"
                        className="app-switch"
                        defaultChecked={postUserSection.Reviews}
                        onChange={handelChange}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn large-btn ">
                <div className="button-inner">
                  {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                  <span className="btn-text">Save</span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ProfileSection;
