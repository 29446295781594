import React from "react";

const CustomeLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        height: "100%",
        width: "100%",
        top: "0",
        left: "0",
        backgroundColor: "rgba(0, 0, 0, 0.37)",
        zIndex: "9999",
      }}
    >
      <div>
        <div
          style={{
            width: "70px",
            height: "70px",
            border: "solid 5px transparent",
            borderTopColor: "#00c8b1 !important",
            borderLeftColor: "#00c8b1 !important",
          }}
        >
          <h5>Loading...</h5>
        </div>
      </div>
    </div>
  );
};

export default CustomeLoader;
