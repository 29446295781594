import React from "react";
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
const TableTopSection = ({
  pages,
  filters,
  addEditBtnClicked,
  deleteSelectedRecords,
  teamMeambers,
}) => {
  return (
    <div className="select-action-row d-flex">
      {pages !== 0 && teamMeambers?.length > 0 && (
        <div className="d-flex align-item-center my-auto">
          <h6>
            Showing: {filters.PageIndex}/{pages} Pages
          </h6>
        </div>
      )}
      <Navbar color="light ml-auto" light expand="md">
        <Collapse className="show" navbar>
          <Nav className="mr-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => addEditBtnClicked(0, "add")}>Add Member</DropdownItem>
                <DropdownItem onClick={deleteSelectedRecords}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default TableTopSection;
