import React, { useState, useEffect } from "react";
import Api from "../API/integration";
import { toast } from "react-toastify";
import { RecordLincURL } from "../config/appConstatnts";
import AppPageLoader from "../common-components/AppPageLoader";

const CrmSyncCountComponent = (props) => {
  const [selectedData, setselectedData] = useState(null);
  const [loader, setLoader] = useState(false);

  const getCRMMappingExcel = (SectionId, Status, LocationId) => {
    let data = {
      Section: SectionId,
      Status: Status,
      LocationId: LocationId,
    };
    setLoader(true);

    Api.getCRMMappingExcel(data)
      .then((resp) => {
        if (resp.data.Result) {
          window.location.href = RecordLincURL + "IntegrationExcelFiles\\" + resp.data.Result;
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            toast.info("Record not found!");
          } else {
            toast.error("Something went wrong.");
          }
        } else {
          toast.error("Something went wrong.");
        }
      })
      .finally(() => setLoader(false));
  };

  function ConvertFromUTC(date)
  {
    var date = new Date(date);
    let systemTime = date.toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    return systemTime;
  }

  const getPageDetails = (crmSyncCounts, locationId) => {
    if (crmSyncCounts === 0) {
      setselectedData({
        LocationId: locationId,
        Location: { IsSync: 0, IsNotSync: 0 },
        Patient: { IsSync: 0, IsNotSync: 0 },
        User: { IsSync: 0, IsNotSync: 0 },
        Service: { IsSync: 0, IsNotSync: 0 },
        Appointment: { IsSync: 0, IsNotSync: 0 },
      });
    } else {
      let s = crmSyncCounts.find((v) => v.LocationId === locationId);
      setselectedData(s);
    }
  };
  useEffect(() => {
    getPageDetails(props.CrmSyncCountData, props.LocationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.CrmSyncCountData, props.LocationId]);

  return (
    <>
      {selectedData && (
        <div className="status-list">
          <div className="status-inner">
            <div className="single-status">
              {selectedData.Patient &&
                selectedData.Appointment &&
                selectedData.Location &&
                selectedData.User &&
                selectedData.Service && (
                  <>
                    <table
                      className="mb-2"
                      style={{
                        width: "100%",
                        display: "table",
                        textIndent: "initial",
                        borderSpacing: "2px",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Already Synced</th>
                          <th scope="col">Not synced yet</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Patient <span className="ml-1"><i className="fa fa-info-circle" title={`Last Sync: ${ConvertFromUTC(selectedData.Patient.LastSyncUTCDateTime)}`}></i></span></th>
                          <td>
                            {selectedData.Patient.IsSync}
                            {parseInt(selectedData.Patient.IsSync) > 0 && (
                              <span className="file-btn">
                                <button
                                  className="btn small-ico-btn copy-btn"
                                  onClick={() => getCRMMappingExcel(1, 0, selectedData.LocationId)}
                                >
                                  <i
                                    className=" fa fa-file-excel-o mx-2 mt-0"
                                    style={{
                                      font: "normal normal normal 14px/1 FontAwesome",
                                    }}
                                  ></i>
                                </button>
                              </span>
                            )}
                          </td>
                          <td>
                            {selectedData.Patient.IsNotSync}
                            {parseInt(selectedData.Patient.IsNotSync) > 0 && (
                              <span className="file-btn">
                                <button
                                  className="btn small-ico-btn copy-btn"
                                  onClick={() => getCRMMappingExcel(1, 1, selectedData.LocationId)}
                                >
                                  <i
                                    className=" fa fa-file-excel-o mx-2 mt-0"
                                    style={{
                                      font: "normal normal normal 14px/1 FontAwesome",
                                    }}
                                  ></i>
                                </button>
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Appointment <span className="ml-1"><i className="fa fa-info-circle" title={`Last Sync: ${ConvertFromUTC(selectedData.Appointment.LastSyncUTCDateTime)}`}></i></span></th>
                          <td>
                            {selectedData.Appointment.IsSync}
                            {parseInt(selectedData.Appointment.IsSync) > 0 && (
                              <span className="file-btn">
                                <button
                                  className="btn small-ico-btn copy-btn"
                                  onClick={() => getCRMMappingExcel(2, 0, selectedData.LocationId)}
                                >
                                  <i
                                    className=" fa fa-file-excel-o mx-2 mt-0"
                                    style={{
                                      font: "normal normal normal 14px/1 FontAwesome",
                                    }}
                                  ></i>
                                </button>
                              </span>
                            )}
                          </td>
                          <td>
                            {selectedData.Appointment.IsNotSync}
                            {parseInt(selectedData.Appointment.IsNotSync) > 0 && (
                              <span className="file-btn">
                                <button
                                  className="btn small-ico-btn copy-btn"
                                  onClick={() => getCRMMappingExcel(2, 1, selectedData.LocationId)}
                                >
                                  <i
                                    className=" fa fa-file-excel-o mx-2 mt-0"
                                    style={{
                                      font: "normal normal normal 14px/1 FontAwesome",
                                    }}
                                  ></i>
                                </button>
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Location</th>
                          <td>
                            {selectedData.Location.IsSync}
                            {parseInt(selectedData.Location.IsSync) > 0 && (
                              <span className="file-btn">
                                <button
                                  className="btn small-ico-btn copy-btn"
                                  onClick={() => getCRMMappingExcel(9, 0, selectedData.LocationId)}
                                >
                                  <i
                                    className=" fa fa-file-excel-o mx-2 mt-0"
                                    style={{
                                      font: "normal normal normal 14px/1 FontAwesome",
                                    }}
                                  ></i>
                                </button>
                              </span>
                            )}
                          </td>
                          <td>
                            {selectedData.Location.IsNotSync}
                            {parseInt(selectedData.Location.IsNotSync) > 0 && (
                              <span className="file-btn">
                                <button
                                  className="btn small-ico-btn copy-btn"
                                  onClick={() => getCRMMappingExcel(9, 1, selectedData.LocationId)}
                                >
                                  <i
                                    className=" fa fa-file-excel-o mx-2 mt-0"
                                    style={{
                                      font: "normal normal normal 14px/1 FontAwesome",
                                    }}
                                  ></i>
                                </button>
                              </span>
                            )}
                          </td>
                          {/* <td><span><i className="fa fa-info-circle" title={selectedData.Location.LastSyncDateTime}></i></span></td> */}
                        </tr>
                        <tr>
                          <th scope="row">User</th>
                          <td>
                            {selectedData.User.IsSync}
                            {parseInt(selectedData.User.IsSync) > 0 && (
                              <span className="file-btn">
                                <button
                                  className="btn small-ico-btn copy-btn"
                                  onClick={() => getCRMMappingExcel(10, 0, selectedData.LocationId)}
                                >
                                  <i
                                    className=" fa fa-file-excel-o mx-2 mt-0"
                                    style={{
                                      font: "normal normal normal 14px/1 FontAwesome",
                                    }}
                                  ></i>
                                </button>
                              </span>
                            )}
                          </td>
                          <td>
                            {selectedData.User.IsNotSync}
                            {parseInt(selectedData.User.IsNotSync) > 0 && (
                              <span className="file-btn">
                                <button
                                  className="btn small-ico-btn copy-btn"
                                  onClick={() => getCRMMappingExcel(10, 1, selectedData.LocationId)}
                                >
                                  <i
                                    className=" fa fa-file-excel-o mx-2 mt-0"
                                    style={{
                                      font: "normal normal normal 14px/1 FontAwesome",
                                    }}
                                  ></i>
                                </button>
                              </span>
                            )}
                          </td>
                          {/* <td><span><i className="fa fa-info-circle" title={selectedData.User.LastSyncDateTime}></i></span></td> */}
                        </tr>
                        <tr>
                          <th scope="row">Service</th>
                          <td>
                            {selectedData.Service.IsSync}
                            {parseInt(selectedData.Service.IsSync) > 0 && (
                              <span className="file-btn">
                                <button
                                  className="btn small-ico-btn copy-btn"
                                  onClick={() => getCRMMappingExcel(11, 0, selectedData.LocationId)}
                                >
                                  <i
                                    className=" fa fa-file-excel-o mx-2 mt-0"
                                    style={{
                                      font: "normal normal normal 14px/1 FontAwesome",
                                    }}
                                  ></i>
                                </button>
                              </span>
                            )}
                          </td>
                          <td>
                            {selectedData.Service.IsNotSync}
                            {parseInt(selectedData.Service.IsNotSync) > 0 && (
                              <span className="file-btn">
                                <button
                                  className="btn small-ico-btn copy-btn"
                                  onClick={() => getCRMMappingExcel(11, 1, selectedData.LocationId)}
                                >
                                  <i
                                    className=" fa fa-file-excel-o mx-2 mt-0"
                                    style={{
                                      font: "normal normal normal 14px/1 FontAwesome",
                                    }}
                                  ></i>
                                </button>
                              </span>
                            )}
                          </td>
                          {/* <td><span><i className="fa fa-info-circle" title={selectedData.Service.LastSyncDateTime}></i></span></td> */}
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              {loader && <AppPageLoader></AppPageLoader>}
            </div>
          </div>
        </div>
      )}
      {selectedData == null && (
        <div className="status-list">
          <div className="status-inner">
            <div className="single-status">
              <table
                className="mb-2"
                style={{
                  width: "100%",
                  display: "table",
                  textIndent: "initial",
                  borderSpacing: "2px",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Already Synced</th>
                    <th scope="col">Not synced yet</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Patient</th>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th scope="row">Appointment</th>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th scope="row">Location</th>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th scope="row">User</th>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th scope="row">Service</th>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CrmSyncCountComponent;
