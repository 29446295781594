import React from "react";

const SyncPatientLayout = ({ children }) => {
  return (
    <div className="row w-100 mx-0">
      <div className="box-col col-xs-12 locationInfo-col">
        <div className="form-group">
          <div className="d-flex justify-content-start flex-sm-row flex-column align-items-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyncPatientLayout;
