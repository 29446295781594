import React from "react";
import { Link } from "react-router-dom";

const SocialMedia = ({ SetEditSocialMediaModel, userInfo }) => {
  return (
    <div className="col-xl-6 col-lg-12">
      <div className="edu-section mb-4">
        <div className="sec-header mb-2">
          <h4 className="mr-auto  ">Social Media</h4>
          <button
            className="app-btn app-btn-clear sec-action-btn"
            onClick={() => SetEditSocialMediaModel(true)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17">
              <path
                fill="#707070"
                d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
              />
            </svg>{" "}
            Edit
          </button>
        </div>
        <p className="social-icons-list">
          {userInfo.lstGetSocialMediaDetailByUserId &&
            userInfo.lstGetSocialMediaDetailByUserId.length > 0 &&
            userInfo.lstGetSocialMediaDetailByUserId[0].FacebookUrl &&
            userInfo.lstGetSocialMediaDetailByUserId[0].FacebookUrl.length > 0 && (
              <Link
                to={{
                  pathname: userInfo.lstGetSocialMediaDetailByUserId[0].FacebookUrl,
                }}
                target="_blank"
                title="Facebook"
              >
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </Link>
            )}

          {userInfo.lstGetSocialMediaDetailByUserId &&
            userInfo.lstGetSocialMediaDetailByUserId.length > 0 &&
            userInfo.lstGetSocialMediaDetailByUserId[0].LinkedinUrl &&
            userInfo.lstGetSocialMediaDetailByUserId[0].LinkedinUrl.length > 0 && (
              <Link
                to={{
                  pathname: userInfo.lstGetSocialMediaDetailByUserId[0].LinkedinUrl,
                }}
                target="_blank"
                title="Linkedin"
              >
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </Link>
            )}

          {userInfo.lstGetSocialMediaDetailByUserId &&
            userInfo.lstGetSocialMediaDetailByUserId.length > 0 &&
            userInfo.lstGetSocialMediaDetailByUserId[0].TwitterUrl &&
            userInfo.lstGetSocialMediaDetailByUserId[0].TwitterUrl.length > 0 && (
              <Link
                to={{
                  pathname: userInfo.lstGetSocialMediaDetailByUserId[0].TwitterUrl,
                }}
                target="_blank"
                title="Twitter"
              >
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </Link>
            )}

          {userInfo.lstGetSocialMediaDetailByUserId &&
            userInfo.lstGetSocialMediaDetailByUserId.length > 0 &&
            userInfo.lstGetSocialMediaDetailByUserId[0].GoogleplusUrl &&
            userInfo.lstGetSocialMediaDetailByUserId[0].GoogleplusUrl.length > 0 && (
              <Link
                to={{
                  pathname: userInfo.lstGetSocialMediaDetailByUserId[0].GoogleplusUrl,
                }}
                target="_blank"
                title="Google plus"
              >
                <i className="fa fa-google-plus" aria-hidden="true"></i>
              </Link>
            )}

          {userInfo.lstGetSocialMediaDetailByUserId &&
            userInfo.lstGetSocialMediaDetailByUserId.length > 0 &&
            userInfo.lstGetSocialMediaDetailByUserId[0].YoutubeUrl &&
            userInfo.lstGetSocialMediaDetailByUserId[0].YoutubeUrl.length > 0 && (
              <Link
                to={{
                  pathname: userInfo.lstGetSocialMediaDetailByUserId[0].YoutubeUrl,
                }}
                target="_blank"
                title="Youtube"
              >
                <i className="fa fa-youtube" aria-hidden="true"></i>
              </Link>
            )}

          {userInfo.lstGetSocialMediaDetailByUserId &&
            userInfo.lstGetSocialMediaDetailByUserId.length > 0 &&
            userInfo.lstGetSocialMediaDetailByUserId[0].PinterestUrl &&
            userInfo.lstGetSocialMediaDetailByUserId[0].PinterestUrl.length > 0 && (
              <Link
                to={{
                  pathname: userInfo.lstGetSocialMediaDetailByUserId[0].PinterestUrl,
                }}
                target="_blank"
                title="Pinterest"
              >
                <i className="fa fa-pinterest" aria-hidden="true"></i>
              </Link>
            )}

          {userInfo.lstGetSocialMediaDetailByUserId &&
            userInfo.lstGetSocialMediaDetailByUserId.length > 0 &&
            userInfo.lstGetSocialMediaDetailByUserId[0].BlogUrl &&
            userInfo.lstGetSocialMediaDetailByUserId[0].BlogUrl.length > 0 && (
              <Link
                to={{
                  pathname: userInfo.lstGetSocialMediaDetailByUserId[0].BlogUrl,
                }}
                target="_blank"
                title="Blog"
              >
                <i className="fa fa-rss-square" aria-hidden="true"></i>
              </Link>
            )}
          {userInfo.lstGetSocialMediaDetailByUserId &&
            userInfo.lstGetSocialMediaDetailByUserId.length > 0 &&
            userInfo.lstGetSocialMediaDetailByUserId[0].InstagramUrl &&
            userInfo.lstGetSocialMediaDetailByUserId[0].InstagramUrl.length > 0 && (
              <Link
                to={{
                  pathname: userInfo.lstGetSocialMediaDetailByUserId[0].InstagramUrl,
                }}
                target="_blank"
                title="Instagram"
              >
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </Link>
            )}

          {userInfo.lstGetSocialMediaDetailByUserId &&
            userInfo.lstGetSocialMediaDetailByUserId.length > 0 &&
            userInfo.lstGetSocialMediaDetailByUserId[0].YelpUrl &&
            userInfo.lstGetSocialMediaDetailByUserId[0].YelpUrl.length > 0 && (
              <Link
                to={{
                  pathname: userInfo.lstGetSocialMediaDetailByUserId[0].YelpUrl,
                }}
                target="_blank"
                title="Yelp"
              >
                <i className="fa fa-yelp" aria-hidden="true"></i>
              </Link>
            )}
        </p>
      </div>
    </div>
  );
};

export default SocialMedia;
