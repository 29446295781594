import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import Select from "react-select";
//component import

import Api from "../API/profile";
import { toast } from "react-toastify";
import AppPageLoader from "../common-components/AppPageLoader";

export default function AddInsuranceDetailsModal(props) {
  const [loader, setLoader] = useState(false);
  const [insurance, setInsurance] = useState([
    {
      InsuranceId: 0,
      Name: "",
      IsActiveted: false,
      LocationId: 0,
    },
  ]);

  const [insuranceData, setInsuranceData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [selInsuranceData, setSelInsuranceData] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  useEffect(() => {
    Api.getInsurance()
      .then((res) => {
        setInsurance(res.data.Result);
        let insuData = [];
        let selInsuData = [];
        if (
          res != null &&
          res.data != null &&
          res.data.Result != null &&
          res.data.Result.length > 0
        ) {
          for (let i = 0; i < res.data.Result.length; i++) {
            insuData.push({
              value: res.data.Result[i].InsuranceId,
              label: res.data.Result[i].Name,
            });

            if (res.data.Result[i].IsActiveted) {
              selInsuData.push({
                value: res.data.Result[i].InsuranceId,
                label: res.data.Result[i].Name,
              });
            }
          }
          setInsuranceData(insuData);
          setSelInsuranceData(selInsuData);
        }
      })
      .catch((error) => {});
  }, [props.UserId]);

  const onChange = (value, { action, removedValue }) => {
    setSelInsuranceData(value);
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    setInsurance(
      insurance.map((item, index) => {
        item.IsActiveted = false;
        return item;
      })
    );
    if (selInsuranceData.length > 0) {
      for (let i = 0; i < selInsuranceData.length; i++) {
        insurance.find((a) => a.InsuranceId == selInsuranceData[i].value).IsActiveted = true;
      }
    }
    setLoader(true);
    Api.postInsurance(insurance)
      .then((res) => {
        setLoader(false);
        toast.success("Insurance updated successfully");
        props.reload();
        props.onHide();
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.data.StatusCode === 400) {
          toast.warn(error.response.data.Result);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Add Insurance Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          {loader && <AppPageLoader className={"position-fixed"} />}
          <form onSubmit={handelSubmit}>
            <div className="row">
              <div className="col-sm-12">
                <div class="form-group app-ctrl ">
                  <div className="app-custom-select">
                    <Select
                      options={insuranceData}
                      placeholder="Select Insurance Details"
                      isMulti
                      onChange={onChange}
                      value={selInsuranceData}
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn large-btn ">
                <div className="button-inner">
                  <span className="btn-text">Save</span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
