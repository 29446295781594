const DownloadBtn = ({ downloadExcelFile }) => {
  return (
    <div className="select-action-row d-flex">
      <div className="right-box ml-auto d-flex align-items-start">
        <button className="btn app-btn large-btn active" onClick={() => downloadExcelFile()}>
          Download Excel{" "}
          <span>
            <i className="fa fa-download" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export default DownloadBtn;
