import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import TopNavBar from "../common-components/TopNavbar";

const Layout = ({ children }) => {
  return (
    <div className="main-wrapper setting-wrapper ">
      <AppSidebarNavbar />
      <main>
        <div className="app-container">
          <div className="app-content">
            <TopNavBar />
            <div className="setting-page team-member-page position-relative">
              <div className="white-container coming-soon">
                <div className="w-100 setting-menu">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Layout;
