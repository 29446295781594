import axios from "axios";
import { API_URL, HEADER } from "../config/appConstatnts";
// main api class
class Api {
  //Get User details
  static getUserDetails = (id = 0) => {
    return axios.get(API_URL + "/database/UserDetails?UserId=" + id, HEADER);
  };

  //Post User details
  static postUserDetails = (data) => {
    return axios.post(API_URL + "/database/UpdateUserDetails", data, HEADER);
  };

  // Change password
  static postChangePassword = (data) => {
    let obj = {
      CurrentPassword: data.CurrentPassword,
      NewPassword: data.NewPassword,
    };
    return axios.post(API_URL + "/UserProfile/ChangePassword", obj, HEADER);
  };
}
export default Api;
