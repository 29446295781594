import React,{useState} from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReSyncAppointmentModal from '../../models/ReSyncAppointmentModal';

export const SyncUserLabel = ({ label }) => {
  return (
    <p className="icon-label">
      <svg
        className="info-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="18.358"
        height="22.004"
        viewBox="0 0 18.358 22.004"
      >
        <g id="user_16_" data-name="user (16)" transform="translate(0 0)">
          <path
            id="Path_9231"
            data-name="Path 9231"
            d="M92.331,10.6a5.128,5.128,0,0,0,3.747-1.553A5.128,5.128,0,0,0,97.631,5.3a5.129,5.129,0,0,0-1.553-3.747,5.3,5.3,0,0,0-7.494,0A5.128,5.128,0,0,0,87.032,5.3a5.128,5.128,0,0,0,1.553,3.747A5.129,5.129,0,0,0,92.331,10.6Zm0,0"
            transform="translate(-83.292 0)"
            fill="#0A2753"
          />
          <path
            id="Path_9232"
            data-name="Path 9232"
            d="M18.313,253.607a13.09,13.09,0,0,0-.178-1.39,10.954,10.954,0,0,0-.342-1.4,6.9,6.9,0,0,0-.575-1.3,4.92,4.92,0,0,0-.867-1.129,3.821,3.821,0,0,0-1.245-.782,4.3,4.3,0,0,0-1.589-.288,1.613,1.613,0,0,0-.861.365c-.258.168-.56.363-.9.578a5.141,5.141,0,0,1-1.161.512,4.508,4.508,0,0,1-2.84,0,5.129,5.129,0,0,1-1.16-.511c-.334-.213-.636-.408-.9-.579a1.611,1.611,0,0,0-.861-.365,4.3,4.3,0,0,0-1.589.288A3.818,3.818,0,0,0,2,248.386a4.92,4.92,0,0,0-.866,1.129,6.918,6.918,0,0,0-.575,1.3,10.98,10.98,0,0,0-.342,1.4,13.044,13.044,0,0,0-.178,1.391c-.029.421-.044.858-.044,1.3a3.654,3.654,0,0,0,1.085,2.764,3.906,3.906,0,0,0,2.8,1.02H14.476a3.905,3.905,0,0,0,2.8-1.02,3.652,3.652,0,0,0,1.085-2.764c0-.443-.015-.881-.044-1.3Zm0,0"
            transform="translate(0 -236.688)"
            fill="#0A2753"
          />
        </g>
      </svg>
      {label}
    </p>
  );
};

export const SyncLocationLabel = ({ label }) => (
  <p className="icon-label">
    <svg className="info-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 19">
      <path d="M7 0c3.86 0 7 3.162 7 7.05 0 5.525-6.342 11.23-6.612 11.469a.583.583 0 01-.776 0C6.342 18.28 0 12.576 0 7.052 0 3.162 3.14 0 7 0zm0 10.889A3.893 3.893 0 0010.889 7 3.893 3.893 0 007 3.111 3.893 3.893 0 003.111 7 3.893 3.893 0 007 10.889z" />
    </svg>
    {label}
  </p>
);

export const SectionWithCheckbox = ({
  className,
  title,
  children,
  formDisable,
  onChangeCheckbox,
  checked,
  switchId,
  IsSyncButton,
  LocationId,
  UpdateCalendarOperatoryList
}) => {
  const [ReSyncAppointmentModalShow, SetReSyncAppointmentModal] = useState(false);
  return (
    <div className={`accordion-head additionalClassForHead ${className} `}>
      <div className="box-col col-xs-12 ">
        <h2 className="info-title-blue mb-2">{title} {IsSyncButton && <span className="ml-3 icon-hover" onClick={() => SetReSyncAppointmentModal(true)}><i title="Re-Sync future appointments" className="fa fa-rotate-right"></i></span>} </h2>

        <div className="box-col col-xs-12 locationInfo-col my-3">
          <p
            className="location-note text-info"
            style={{
              fontWeight: "normal",
              lineHeight: "20px",
              wordSpacing: ".50px",
              textAlign: "justify",
            }}
          >
            {children}
          </p>
        </div>
      </div>
      <Form.Check
        disabled={formDisable}
        type="switch"
        id={switchId}
        className="app-switch"
        onChange={onChangeCheckbox}
        checked={checked}
      />
      <ReSyncAppointmentModal LocationId = {LocationId} show={ReSyncAppointmentModalShow} onHide={() => SetReSyncAppointmentModal(false)} 
        UpdateCalendarOperatoryList = {UpdateCalendarOperatoryList}/>
    </div>
  );
};

export const SaveButton = ({ isDisabled, SaveBtnOnClick, label, style, className }) => {
  return (
    <div className="sync-text ">
      <button
        style={style}
        type="button"
        disabled={isDisabled}
        className={`"btn app-btn large-btn ml-4" + ${className} `}
        onClick={SaveBtnOnClick}
      >
        {label || "Save"}
      </button>
    </div>
  );
};

export const NotificationMessage = ({ children, className }) => {
  return (
    <div className="box-col col-xs-12 locationInfo-col mt-4">
      <p
        className={`location-note ${className} `}
        style={{
          fontWeight: "normal",
          lineHeight: "20px",
          wordSpacing: ".50px",
          textAlign: "justify",
        }}
      >
        {children}
      </p>
    </div>
  );
};

export const ExternalLink = ({ title, linkPath, children }) => {
  return (
    <Link
      title={title}
      to={{
        pathname: { linkPath },
      }}
      target="_blank"
    >
      {children}
    </Link>
  );
};

export const CommonNotificationGreen = ({ children }) => {
  return (
    <div className="box-col col-xs-12 locationInfo-col mt-4">
      <p
        className="location-note badge badge-success"
        style={{
          fontWeight: "normal",
          lineHeight: "20px",
          wordSpacing: ".50px",
          textAlign: "justify",
        }}
      >
        {children}
      </p>
    </div>
  );
};

export const CommonNotificationRed = ({ children }) => {
  return (
    <div className="box-col col-xs-12 locationInfo-col mt-4">
      <p
        className="location-note badge badge-danger"
        style={{
          fontWeight: "normal",
          lineHeight: "20px",
          wordSpacing: ".50px",
          textAlign: "justify",
        }}
      >
        {children}
      </p>
    </div>
  );
};

export const CommonNotificationBlue = ({ children }) => (
  <div className="box-col col-xs-12 locationInfo-col mt-4">
    <p
      className="location-note badge badge-primary"
      style={{
        fontWeight: "normal",
        lineHeight: "20px",
        wordSpacing: ".50px",
        textAlign: "justify",
      }}
    >
      {children} Please{" "}
      <Link
        className="text-white"
        title="Go to Support Link"
        to={{
          pathname:
            "https://recordlinc.atlassian.net/servicedesk/customer/user/login?destination=portals",
        }}
        target="_blank"
      >
        contact our support team
      </Link>{" "}
      and we will resolve this for you.
    </p>
  </div>
);

export const ErrorMessage = ({ children }) => (
  <div className="box-col col-xs-12 locationInfo-col">
    <div className=" multiselect-col-1">
      <p className="location-note text-danger ml-2" style={{ fontWeight: "normal" }}>
        {children}
      </p>
    </div>
  </div>
);
