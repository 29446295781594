import React from "react";
import { Modal } from "react-bootstrap";
//component import
export default function SocialMediaModal(props) {
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Add Social Media
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Facebook</label>
                  <input type="text" className="form-control" defaultValue="" />
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Twitter</label>
                  <input type="text" className="form-control" defaultValue="" />
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Instagram</label>
                  <input type="text" className="form-control" defaultValue="" />
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Linkedin</label>
                  <input type="text" className="form-control" defaultValue="" />
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Google Plus</label>
                  <input type="text" className="form-control" defaultValue="" />
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Pinterest</label>
                  <input type="text" className="form-control" defaultValue="" />
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Youtube</label>
                  <input type="text" className="form-control" defaultValue="" />
                </div>
              </div>
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Blog</label>
                  <input type="text" className="form-control" defaultValue="" />
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button type="button" className="btn app-btn large-btn ">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
