import React from "react";
import { Modal, Button } from "react-bootstrap";

//component import
import * as inputFun from '../common-components/FormInputFunctions';

export default function AddLocationProfileModel(props) {
  return (
    <div className="maplayout-page">
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="app-model location-profile-model"
      id="drpro-select">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
          Add New Location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="app-model-body">
      <form>
            <div className="row ">
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl">
                  <label class="required">Location Name</label>
                  <input type="text" className="form-control" defaultValue="" onChange={inputFun.checkInputHasValue}/>
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl">
                  <label class="required">Street Address</label>
                  <input type="text" className="form-control" defaultValue="" onChange={inputFun.checkInputHasValue}/>
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl">
                  <label class="required">City</label>
                  <input type="text" className="form-control" defaultValue="" onChange={inputFun.checkInputHasValue}/>
                </div>
              </div>
              <div className=" col-sm-3 ">
                <div class="form-group app-ctrl">
                  <label class="required">State</label>
                  <input type="text" className="form-control" defaultValue="" onChange={inputFun.checkInputHasValue}/>
                </div>
              </div>
              <div className=" col-sm-3 ">
                <div class="form-group app-ctrl">
                  <label class="required">Zip Code</label>
                  <input type="text" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                    }}} maxLength="6" className="form-control" defaultValue="123456" onChange={inputFun.checkInputHasValue}/>
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl">
                  <label class="required">Email</label>
                  <input type="text" className="form-control" defaultValue=""/>
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl">
                  <label class="">Phone Number</label>
                  <input type="text" className="form-control" defaultValue=""/>
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl">
                  <label class="">Fax Number</label>
                  <input type="text" className="form-control" defaultValue=""/>
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl">
                  <label class="required">NPI Number</label>
                  <input type="text" className="form-control" defaultValue=""/>
                </div>
              </div>
              
            </div>
              <div className="btn-box d-flex justify-content-center">
                {/* <button type="button" className="btn app-btn lightgray-btn large-btn mr-2"  onClick={props.onHide}>delete</button> */}
                <button type="button" className="btn app-btn lightgray-btn large-btn mr-2"  onClick={props.onHide}>cancel</button>
                <button type="button" className="btn app-btn large-btn ">Add location</button>
                <button type="button" className="btn app-btn large-btn ">Make Primary</button>
              </div>
            
          </form>

      </Modal.Body>
     
    </Modal>
    </div>
  );
}


