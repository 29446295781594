import React from "react";

const ProcessType = ({ IsOPSyncAutomatically, onChangeProcessType }) => {
  return (
    <div className={"d-flex mb-2 w-50"}>
      <div
        className="mx-2 custom-control custom-checkbox app-checkbox primaryLocation-chk"
        style={{
          fontSize: "16px",
          lineHeight: "20px",
          wordSpacing: ".50px",
          textAlign: "justify",
        }}
      >
        <input
          type="radio"
          id="automaticProcessType"
          name="processName"
          // value={true}
          className="custom-control-input"
          checked={IsOPSyncAutomatically}
          onChange={() => onChangeProcessType(true)}
        />
        <label
          className="custom-control-label my-2"
          htmlFor="automaticProcessType"
          style={{ fontWeight: "normal" }}
        >
          <span style={{ fontWeight: "bold" }}>Sync Appointment Automatically</span>{" "}
        </label>
      </div>
      <div
        className="mx-2 custom-control custom-checkbox app-checkbox primaryLocation-chk"
        style={{
          fontSize: "16px",
          lineHeight: "20px",
          wordSpacing: ".50px",
          textAlign: "justify",
        }}
      >
        <input
          type="radio"
          id="manualProcessType"
          name="processName"
          // value={false}
          className="custom-control-input"
          onChange={() => onChangeProcessType(false)}
          checked={!IsOPSyncAutomatically}
        />
        <label
          className="custom-control-label my-2"
          htmlFor="manualProcessType"
          style={{ fontWeight: "normal" }}
        >
          <span style={{ fontWeight: "bold" }}>Sync Appointment Manually</span>{" "}
        </label>
      </div>
    </div>
  );
};

export default React.memo(ProcessType);
