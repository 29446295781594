import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";

import { toast } from "react-toastify";
import Api from "../../API/go-high-level";
import {
  SaveButton,
  SectionWithCheckbox,
} from "../OtherCrmCommonComponente/OtherCrmCommonComponents";
import SyncPatientLayout from "./SyncPatientLayout";
import AppPageLoader from "../../common-components/AppPageLoader";

const SyncPatients = ({
  IsAgencyKeyError,
  credentials,
  LocationSyncId,
  AllowDuplicate,
  IsSyncPatient,
  patientToggle,
}) => {
  // states ============
  const [allowDuplicate, setAllowDuplicate] = useState(AllowDuplicate);
  const [isSyncPatient, setIsSyncPatient] = useState(false);
  const [IsDisableButton, setIsDisableButton] = useState(false);
  const [loader, setLoader] = useState(false);
  // handle change patient========
  const handleChange = () => {
    setAllowDuplicate(!allowDuplicate);
    setIsDisableButton(false);
  };

  // handle submit patient=========
  const submitCheckboxValue = () => {
    const data = {
      AccountId: credentials.accountId,
      LocationId: credentials.locationId,
      AllowDuplicatePatient: allowDuplicate,
      OnPageLoad: false,
    };
    setIsDisableButton(true);
    setLoader(true);
    Api.UpdateSyncPatients(data)
      .then((res) => {
        if (res.data.StatusCode === 200 && res.data.IsSuccess) {
          toast.success("Patient sync settings are saved successfully");
          setAllowDuplicate(data.AllowDuplicatePatient);
        } else {
          toast.error(res.data.Message);
        }
      })
      .catch((error) => toast.error("Something went wrong."))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    setIsSyncPatient(IsSyncPatient);
    setAllowDuplicate(AllowDuplicate);
  }, [IsSyncPatient, AllowDuplicate]);

  return (
    <>
      {loader && <AppPageLoader className={"position-fixed"} />}
      <SectionWithCheckbox
        className="mt-2"
        title={"Sync Patient "}
        formDisable={IsAgencyKeyError}
        onChangeCheckbox={patientToggle}
        checked={isSyncPatient}
        switchId={"custom-switch-patient"}
      >
        The Custom CRM identifies unique records based on email or phone number. We default the
        system to "Allow Duplicates" because a family will often use the same email or phone number.
        There may be certain use cases where you do not want to alow duplicates so we give you the
        option.
      </SectionWithCheckbox>

      <Collapse className="panel-accordion additionalClassForContent" isOpen={isSyncPatient}>
        {LocationSyncId === "1" ? (
          <div>
            <h5>Please Select CRM location to select allow duplicate</h5>
          </div>
        ) : (
          <SyncPatientLayout>
            <div className="form-group app-ctrl circle-check mb-0">
              <div className="custom-control custom-checkbox app-checkbox syncPatient-chk">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="syncPatient"
                  checked={allowDuplicate}
                  onChange={handleChange}
                />
                <label className="custom-control-label" htmlFor="syncPatient">
                  <span> Allow Duplicates</span> (unique records are identified by email or phone)
                </label>
              </div>
            </div>
            <SaveButton isDisabled={IsDisableButton} SaveBtnOnClick={submitCheckboxValue} />
          </SyncPatientLayout>
        )}
      </Collapse>
    </>
  );
};

export default SyncPatients;
