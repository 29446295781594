import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Spinner } from "reactstrap";

const RemoveLocation = (props) => {
  const [loader, setLoader] = useState(false);

  const handleOk = () => {
    setLoader(true);
    let location = props.location;
    props.onHandleOK(location);
    props.onHide();
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model integration-modal locationInfo-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Remove Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className="col-12 locationInfo-col">
                <div className="form-group">
                  <div className="info-ctrl mb-3">
                    <p>
                      <strong className="my-2">
                        Are you sure, you want to remove this location?
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={() => props.onHide()}
              >
                No
              </button>
              <button type="button" className="btn app-btn large-btn " onClick={handleOk}>
                {loader && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2 location-spinner"
                  />
                )}
                Yes
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default RemoveLocation;
