import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../API/go-high-level";
import AppPageLoader from "../common-components/AppPageLoader";
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import TopNavBar from "../common-components/TopNavbar";
import SyncLocation from "./SyncLocation/SyncLocation";
import SyncOpportunity from "./SyncOpportunity/SyncOpportunity";
import SyncPatients from "./SyncPatients/SyncPatients";
import SyncUser from "./SyncUsers/SyncUser";
import TimeZoneSection from "./BreadCrumb/TimeZoneSection";
import { localStorageUserData } from "../config/appConstatnts";
import SyncAppointment from "./SyncAppointment/SyncAppointment";
import SyncBlockout from "./SyncBlockout/SyncBlockout";
import SyncDentrixEvent from "./SyncDentrixEvent/SyncDentrixEvent";
import SyncPatientModal from "../models/SyncPatientModal";

const OtherCrmMappingPage = () => {
  // constant data==========================
  const { id, location } = useParams();
  const accountId = localStorageUserData.AccountId;
  const locationId = parseInt(location);
  const CRMType = "GHL";

  const credentials = {
    id,
    accountId,
    locationId,
    CRMType,
  };

  const initialCalendarOptions = [
    {
      value: "0",
      label: "Create New",
    },
    {
      value: 1,
      label: "Do Not Sync",
    },
  ];

  //  states ==============
  const [calendarOptions, setCalendarOptions] = useState(initialCalendarOptions);

  const [IsSyncOpportunity, setIsSyncOpportunity] = useState(false);
  const [IsOPSyncAutomatically, setIsOPSyncAutomatically] = useState(false);
  const [IsSyncUsers, setIsSyncUsers] = useState(false);
  const [IsSyncAppointment, setIsSyncAppointment] = useState(false);
  const [IsSyncPatient, setIsSyncPatient] = useState(false);
  const [IsSyncBlockoutTypes, setIsSyncBlockoutTypes] = useState(false);
  const [IsSyncDentrixEvents, setIsSyncDentrixEvents] = useState(false);

  const [LocationSyncId, setLocationSyncId] = useState("1");
  const [LocationMappingData, setLocationMappingData] = useState("");
  const [doctorMapping, setDoctorMapping] = useState(null);
  const [opportunityMonitoryValueMapping, setOpportunityMonitoryValueMapping] = useState({
    Treatement: 0,
  });
  const [pmsDoctorsList, setPmsDoctorsList] = useState([]);
  const [CalendarOptMappingData, setCalendarOptMappingData] = useState(null);

  const [loader, setLoader] = useState(false);
  const [AllowApptWriteBack, setAllowApptWriteBack] = useState(false);

  const [GHLUsers, setGHLUsers] = useState([]);
  const [BlockoutTypeList, setBlockoutTypeList] = useState([]);
  const [GHLLocationList, setGHLLocationList] = useState([]);
  const [IsAgencyKeyError, setIsAgencyKeyError] = useState(false);
  const [GhlCalendarList, setGhlCalendarList] = useState(null);

  const [AgencyKey, setAgencyKey] = useState("");
  const [allowDuplicate, setAllowDuplicate] = useState(false);
  const [MappedConnectors, setMappedConnectors] = useState([]);

  const [MappedDentrix, setMappedDentrix] = useState(false);
  const [MappedOpenDental, setMappedOpenDental] = useState(false);

  const [TimeZoneData, setTimeZoneData] = useState({
    TimeZoneId: 0,
    TimeZoneText: "",
  });

  const [GHLLocationApiKey, setGHLLocationApiKey] = useState({
    OldlocationApiKey: "",
  });

  const [commonError, setCommonError] = useState({
    doctorMappingError: "",
    calendarOptMappingError: "",
    AgencyApiKeyError: "",
  });

  const [ShowSyncPatientModal, SetShowSyncPatientModal] = useState(false);

  // sync setting Api Calls================
  const globalSyncSettingPayLoad = {
    AccountId: accountId,
    LocationId: locationId,
    CRMType: CRMType,
    IsSyncUser: IsSyncUsers,
    IsSyncAppointment: IsSyncAppointment,
    IsSyncPatient: IsSyncPatient,
    IsSyncOpportunity: IsSyncOpportunity,
    IsOPSyncAutomatically: IsOPSyncAutomatically,
    IsSyncBlockoutType: IsSyncBlockoutTypes,
    IsSyncDentrixEvent: IsSyncDentrixEvents
  };

  const SyncSettingApiCall = (payLoad) => {
    Api.SyncSettings(payLoad)
      .then((res) => {
        if (res.data.IsSuccess === true) {
          setIsSyncAppointment(payLoad.IsSyncAppointment);
          setIsSyncPatient(payLoad.IsSyncPatient);
          setIsSyncUsers(payLoad.IsSyncUser);
          setIsSyncOpportunity(payLoad.IsSyncOpportunity);
          setIsOPSyncAutomatically(payLoad.IsOPSyncAutomatically);
          setIsSyncBlockoutTypes(payLoad.IsSyncBlockoutType)
          setIsSyncDentrixEvents(payLoad.IsSyncDentrixEvent)
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => toast.error("Something went wrong"));
  };

  const opportunityMonitoryToggle = () => {
    const payLoad = { ...globalSyncSettingPayLoad, IsSyncOpportunity: !IsSyncOpportunity };
    SyncSettingApiCall(payLoad);
  };

  const appointmentToggle = () => {
    const payLoad = { ...globalSyncSettingPayLoad };
    if (IsSyncPatient === false && IsSyncAppointment === false) {
      toast.error(
        "You must sync patients in order to sync appointments. We have enabled sync patients for you."
      );
      payLoad.IsSyncAppointment = !IsSyncAppointment;
      payLoad.IsSyncPatient = !IsSyncPatient;
      SyncSettingApiCall(payLoad);
    }
    if (IsSyncAppointment === true) {
      payLoad.IsSyncAppointment = !IsSyncAppointment;
      SyncSettingApiCall(payLoad);
    }
    if (IsSyncPatient === true && IsSyncAppointment === false) {
      payLoad.IsSyncAppointment = !IsSyncAppointment;
      SyncSettingApiCall(payLoad);
    }
  };

  const syncPatientToGHL = (isSync) => {
    const payLoad = { ...globalSyncSettingPayLoad };
    if (isSync) {
      setIsSyncAppointment(true);
      setIsSyncPatient(true);
      payLoad.IsSyncAppointment = true;
      payLoad.IsSyncPatient = true;
    }
    else{
      setIsSyncAppointment(true);
      setIsSyncPatient(false);
      payLoad.IsSyncAppointment = true;
      payLoad.IsSyncPatient = false;
    }
    SyncSettingApiCall(payLoad);
  }

  const patientToggle = () => {
    if (IsSyncAppointment === true && IsSyncPatient === true) {
      toast.error("Patient sync must be enables in order to sync appointments.");
    } else {
      const payLoad = { ...globalSyncSettingPayLoad, IsSyncPatient: !IsSyncPatient };
      SyncSettingApiCall(payLoad);
    }
  };

  const userToggle = () => {
    const payLoad = { ...globalSyncSettingPayLoad, IsSyncUser: !IsSyncUsers };
    SyncSettingApiCall(payLoad);
  };

  const blockoutToggle = () => {
    const payLoad = { ...globalSyncSettingPayLoad, IsSyncBlockoutType: !IsSyncBlockoutTypes };
    SyncSettingApiCall(payLoad);
  };

  const dentrixEventToggle = () => {
    const payLoad = { ...globalSyncSettingPayLoad, IsSyncDentrixEvent: !IsSyncDentrixEvents };
    SyncSettingApiCall(payLoad);
  };

  // crmmapping details checkError in page====================
  const checkError = (data) => {
    if (data.CalendarOptMapping.length !== 0) {
      data.CalendarOptMapping.some((opt) =>
        opt.SyncId !== "" && opt.ResponseError !== ""
          ? setCommonError({
            ...commonError,
            calendarOptMappingError: "Error In CalendarOpt Mapping",
          })
          : null
      );
    }
    if (data.DoctorMapping !== null) {
      data.DoctorMapping.some((doctor) =>
        doctor.SyncId !== "" && doctor.ResponseError !== ""
          ? setCommonError({
            ...commonError,
            doctorMappingError: "Error In Doctor Mapping",
          })
          : null
      );
    }
  };

  // AgencyKey ============================
  const getAgencyKeyData = () => {
    setLoader(true);
    Api.getAgencyAccountDetails()
      .then((resp) => {
        setLoader(false);
        if (resp.data.IsSuccess === true && resp.data.Result.AgencyKey) {
          setAgencyKey(resp.data.Result.AgencyKey);
          getGHLLocationList(resp.data.Result.AgencyKey);
        } else {
          setCommonError({
            ...commonError,
            AgencyApiKeyError: "Error in Agency Api Key",
          });
          setDoctorMapping([]);
          setCalendarOptMappingData([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        setCommonError({
          ...commonError,
          AgencyApiKeyError: "Error in Agency Api Key",
        });
        setDoctorMapping([]);
        setCalendarOptMappingData([]);
      })
      .finally(() => setLoader(false));
  };

  // ghl locations ========================
  const getGHLLocationList = (token) => {
    setLoader(true);
    Api.getGhlLocations(token)
      .then((res) => {
        setLoader(false);
        if (res.status === 200 && res.data.locations.length > 0) {
          setGHLLocationList(res.data.locations);
          getGHLCRMMappingDetails(res.data.locations, token);
        }
      })
      .catch((e) => {
        setLoader(false);
        setIsAgencyKeyError(true);
        setCommonError({
          ...commonError,
          AgencyApiKeyError: "Error in Agency Api Key",
        });
      })
      .finally(() => setLoader(false));
  };

  const [AutoCalendarOptMapping, setAutoCalendarOptMapping] = useState([]);
  const [writebackSetting, setWritebackSetting] = useState(null);

  // crmMappingDetails api============================

  const simplifyingCRMMappingResponseData = (responseData) => {
    const checkResponseError = (error) => {
      let newError = error.includes("<html>") ? "Something went wrong in GHL" : error;
      return newError;
    };
    if (responseData)
      return responseData?.map((autoCal) => ({
        ...autoCal,
        ResponseError: checkResponseError(autoCal.ResponseError),
      }));
  };

  const checkIsDuplicateChangeRequired = (syncedLoaction, ghlLocations, allowDuplicateFlag) => {
    if (syncedLoaction && ghlLocations) {
      const checkIt = ghlLocations.find((location) => location.id === syncedLoaction).settings
        .allowDuplicateContact;
      if (checkIt !== allowDuplicateFlag) {
        const data = {
          AccountId: credentials.accountId,
          LocationId: credentials.locationId,
          AllowDuplicatePatient: checkIt,
          OnPageLoad: true,
        };
        Api.UpdateSyncPatients(data)
          .then((res) => {
            if (res.data.StatusCode === 200 && res.data.IsSuccess) {
              setAllowDuplicate(data.AllowDuplicatePatient);
            }
          })
          .catch((error) => toast.error("Something went wrong."));
      } else {
        setAllowDuplicate(allowDuplicateFlag);
      }
    } else {
      setAllowDuplicate(allowDuplicateFlag);
    }
  };
  const getGHLCRMMappingDetails = (ghlLocationList, agencyToken) => {
    const data = {
      AccountId: accountId,
      LocationId: locationId,
    };
    setLoader(true);
    Api.getGHLCRMMappingDetails(data)
      .then((res) => {
        setLoader(false);
        if (res.data.IsSuccess === true) {
          checkError(res.data.Result);

          res.data.Result.TimeZoneId !== null && res.data.Result.TimeZoneText !== null
            ? setTimeZoneData({
              TimeZoneId: res.data.Result.TimeZoneId,
              TimeZoneText: res.data.Result.TimeZoneText,
            })
            : setTimeZoneData({
              TimeZoneId: 0,
              TimeZoneText: "",
            });

          res.data.Result.GHLLocationKey !== null && res.data.Result.GHLLocationKey.length > 0
            ? setGHLLocationApiKey({
              OldlocationApiKey: res.data.Result.GHLLocationKey,
            })
            : setGHLLocationApiKey({
              OldlocationApiKey: "",
            });

          res.data.Result.LocationMapping !== null
            ? setLocationMappingData(res.data.Result.LocationMapping)
            : setLocationMappingData("");

          res.data.Result.LocationMapping !== null
            ? setLocationSyncId(res.data.Result.LocationMapping.SyncId)
            : setLocationSyncId("1");

          res.data.Result.DoctorMapping !== null
            ? setDoctorMapping(simplifyingCRMMappingResponseData(res.data.Result.DoctorMapping))
            : setDoctorMapping([]);

          res.data.Result.PMSDoctors !== null
            ? setPmsDoctorsList(res.data.Result.PMSDoctors)
            : setPmsDoctorsList([]);

          res.data.Result.CalendarOptMapping.length > 0
            ? setCalendarOptMappingData(
              simplifyingCRMMappingResponseData(res.data.Result.CalendarOptMapping)
            )
            : setCalendarOptMappingData([]);

          res.data.Result.BlockoutType.length > 0
            ? setBlockoutTypeList(res.data.Result.BlockoutType)
            : setBlockoutTypeList([]);

          res.data.Result.CRMOpportunity
            ? setOpportunityMonitoryValueMapping(res.data.Result.CRMOpportunity)
            : setOpportunityMonitoryValueMapping({ Treatement: 0 });

          res.data.Result.AllowApptWriteBack !== null
            ? setAllowApptWriteBack(res.data.Result.AllowApptWriteBack)
            : setAllowApptWriteBack(false);

          res.data.Result.AutoCalendarOptMapping.length > 0
            ? setAutoCalendarOptMapping(res.data.Result.AutoCalendarOptMapping)
            : setAutoCalendarOptMapping([]);

          if (res.data.Result.MappedDentrixConnector.length > 0) {
            setMappedConnectors(res.data.Result.MappedDentrixConnector);
            setMappedDentrix(res.data.Result.MappedDentrixConnector.filter(x => x.IntegrationId == 1).length > 0 ? true : false);
            setMappedOpenDental(res.data.Result.MappedDentrixConnector.filter(x => x.IntegrationId == 5).length > 0 ? true : false);
          }
          else {
            setMappedConnectors([]);
            setMappedDentrix(false);
            setMappedOpenDental(false)
          }

          res.data.Result.WritebackSetting !== null
            ? setWritebackSetting(res.data.Result.WritebackSetting)
            : setWritebackSetting({ AllowApptWriteBack: false, RLDoctorId: 0, Treatment: null });
          setIsSyncUsers(res.data.Result.SyncSettings.IsSyncUser);
          setIsSyncAppointment(res.data.Result.SyncSettings.IsSyncAppointment);
          setIsSyncPatient(res.data.Result.SyncSettings.IsSyncPatient);
          setIsSyncOpportunity(res.data.Result.SyncSettings.IsSyncOpportunity);
          setIsSyncBlockoutTypes(res.data.Result.SyncSettings.IsSyncBlockoutType);
          setIsSyncDentrixEvents(res.data.Result.SyncSettings.IsSyncDentrixEvent);
          // setAllowDuplicate(res.data.Result.AllowDuplicate);
          checkIsDuplicateChangeRequired(
            res.data.Result.LocationMapping?.SyncId,
            ghlLocationList,
            res.data.Result.AllowDuplicate
          );
          setIsOPSyncAutomatically(res.data.Result.SyncSettings.IsOPSyncAutomatically);

          if (
            res.data.Result.LocationMapping !== null &&
            res.data.Result.LocationMapping.SyncId !== "0" &&
            ghlLocationList.length > 0
          ) {
            let syncedGhlLocationId = res.data.Result.LocationMapping.SyncId;
            let locationApiKey = ghlLocationList.find((v) => v.id === syncedGhlLocationId);
            getGHLCalendarServiceList(locationApiKey.apiKey);
            getGHLUsersList(locationApiKey.apiKey, agencyToken);
          }
        } else {
          setIsAgencyKeyError(true);
        }
      })
      .catch((e) => {
        setLoader(false);
        setIsAgencyKeyError(true);
        setCalendarOptMappingData([]);
        setDoctorMapping([]);
      })
      .finally(() => setLoader(false));
  };

  // ghl Users ============================
  const getGHLUsersList = (locationApiToken) => {
    setLoader(true);
    Api.getGHLUsersList(locationApiToken)
      .then((res) => {
        setLoader(false);
        if (res.status === 200 && res.data.users.length > 0) {
          setGHLUsers(res.data.users);
        }
      })
      .catch((e) => {
        setLoader(false);
        setGHLUsers([]);
      })
      .finally(() => setLoader(false));
  };

  // ghl calendar===================
  const getGHLCalendarServiceList = (locationKeyToken) => {
    setLoader(true);
    Api.getGHLCalendarService(locationKeyToken)
      .then((res) => {
        setLoader(false);
        if (res.status === 200 && res.data.services.length > 0) {
          res.data.services.map(({ id, name }) =>
            calendarOptions.push({
              value: id,
              label: name,
            })
          );
          setCalendarOptions(calendarOptions);
          setGhlCalendarList(res.data.services);
        }
      })
      .catch((error) => {
        setLoader(false);
        setGhlCalendarList([]);
        setCalendarOptions([]);
      })
      .finally(() => setLoader(false));
  };

  const setFunction = (val) => {
    setLocationSyncId(val.SyncId);
    if (val.SyncId !== null) {
      let locationApiKey = GHLLocationList.filter((v) => v.id === val.SyncId);
      if (locationApiKey.length > 0) {
        getGHLCalendarServiceList(locationApiKey[0].apiKey);
        getGHLUsersList(locationApiKey[0].apiKey);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAgencyKeyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-wrapper">
      <AppSidebarNavbar activeTabsIntegration={true} />
      <main>
        <div className="app-container">
          <div className="app-content mapping">
            <TopNavBar />
            <TimeZoneSection
              TimeZoneData={TimeZoneData}
              LocationMappingData={LocationMappingData}
              commonError={commonError}
            />

            <SyncLocation
              IsAgencyKeyError={IsAgencyKeyError}
              credentials={credentials}
              LocationSyncId={LocationSyncId}
              LocationMappingData={LocationMappingData}
              GhlLocationList={GHLLocationList}
              AgencyKey={AgencyKey}
              setFunction={setFunction}
              GHLLocationApiKey={GHLLocationApiKey}
            />

            <div>
              <div className="row app-box-wrapper">
                <div className="box-col col-xs-12 ">
                  {AgencyKey &&
                    (loader || doctorMapping === null || CalendarOptMappingData === null) && (
                      <AppPageLoader className={"position-fixed"} />
                    )}

                  {doctorMapping && (
                    <SyncUser
                      IsAgencyKeyError={IsAgencyKeyError}
                      credentials={credentials}
                      LocationSyncId={LocationSyncId}
                      DoctorMapping={doctorMapping}
                      ghlUsers={GHLUsers}
                      IsSyncUser={IsSyncUsers}
                      userToggle={userToggle}
                    />
                  )}

                  <SyncPatients
                    IsAgencyKeyError={IsAgencyKeyError}
                    credentials={credentials}
                    LocationSyncId={LocationSyncId}
                    AllowDuplicate={allowDuplicate}
                    IsSyncPatient={IsSyncPatient}
                    patientToggle={patientToggle}
                  />

                  {CalendarOptMappingData && (
                    <SyncAppointment
                      IsAgencyKeyError={IsAgencyKeyError}
                      credentials={credentials}
                      LocationSyncId={LocationSyncId}
                      GhlCalendarList={GhlCalendarList}
                      pmsDoctorsList={pmsDoctorsList}
                      AllowApptWriteBack={AllowApptWriteBack}
                      appointmentToggle={()=> IsSyncAppointment? appointmentToggle() :SetShowSyncPatientModal(true)}
                      CalendarOptMapData={CalendarOptMappingData}
                      isSyncAppointment={IsSyncAppointment}
                      isOPSyncAutomatically={IsOPSyncAutomatically}
                      SetIsOPSyncAutomatically={setIsOPSyncAutomatically}
                      AutoCalendarOptMapData={AutoCalendarOptMapping}
                      WritebackSetting={writebackSetting}
                    />
                  )}

                  <SyncOpportunity
                    opportunityMonitorValueToggle={opportunityMonitoryToggle}
                    IsSyncOpportunity={IsSyncOpportunity}
                    LocationSyncId={LocationSyncId}
                    OpportunityMonitoryValueMapping={opportunityMonitoryValueMapping}
                    IsAgencyKeyError={IsAgencyKeyError}
                    credentials={credentials}
                  />

                  <SyncDentrixEvent IsAgencyKeyError={IsAgencyKeyError}
                    credentials={credentials}
                    LocationSyncId={LocationSyncId}
                    IsSyncDentrixEvents={IsSyncDentrixEvents}
                    dentrixEventToggle={dentrixEventToggle}
                    MappedConnectors={MappedConnectors}
                    MappedDentrix={MappedDentrix}
                  />

                  <SyncBlockout IsAgencyKeyError={IsAgencyKeyError}
                    credentials={credentials}
                    LocationSyncId={LocationSyncId}
                    IsSyncBlockoutType={IsSyncBlockoutTypes}
                    blockoutToggle={blockoutToggle}
                    BlockoutTypeList={BlockoutTypeList}
                    MappedConnectors={MappedConnectors}
                    MappedOpenDental={MappedOpenDental}
                  />

                </div>
              </div>
            </div>
            {loader && <AppPageLoader style={{ position: "fixed" }} />}
            <SyncPatientModal
              show={ShowSyncPatientModal}
              onHide={() => SetShowSyncPatientModal(false)} onSuccess={syncPatientToGHL} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default OtherCrmMappingPage;
