import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";

const Website = ({ onClickwebsite, userInfo, deleteWebSite }) => {
  return (
    <div className="col-xl-6 col-lg-12">
      <div className="edu-section">
        <div className="sec-header mb-0">
          <h4 className="mr-auto  mb-0">Website</h4>
          <button
            className="app-btn app-btn-clear sec-action-btn "
            onClick={() => onClickwebsite(0)}
          >
            <i className="fa fa-plus"></i> Add
          </button>
        </div>
        <div className="user-basic-info pt-3">
          <div className="basic-info-scroll-wrapper">
            <ListGroup className="info-list">
              {userInfo.lstsecondarywebsitelist &&
                userInfo.lstsecondarywebsitelist.length > 0 &&
                userInfo.lstsecondarywebsitelist.map((web, index) => (
                  <ListGroup.Item key={`lstsecondarywebsitelist-${index}`}>
                    <Link to={{ pathname: `https://${web.SecondaryWebsiteurl}` }} target="_blank">
                      {web.SecondaryWebsiteurl}
                    </Link>
                    <button
                      className="app-btn app-btn-clear action-btn edit-action-btn"
                      onClick={() => onClickwebsite(web.SecondaryWebsiteId)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17">
                        <path
                          fill="#707070"
                          d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                        />
                      </svg>
                    </button>
                    <button
                      className="app-btn app-btn-clear action-btn delete-action-btn"
                      onClick={() => deleteWebSite(web.SecondaryWebsiteId)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="181.5 181.5 64 64">
                        <path d="M220.428 233.321V206.6a1.414 1.414 0 112.827 0v26.721a1.414 1.414 0 11-2.827 0zm-15.269 1.414c.781 0 1.414-.634 1.414-1.414V206.6a1.415 1.415 0 00-2.828 0v26.721c0 .781.633 1.414 1.414 1.414zm28.842-33.448v34.834c0 2.058-.756 3.991-2.074 5.379a6.968 6.968 0 01-5.052 2.185h-26.75a6.968 6.968 0 01-5.052-2.185c-1.318-1.388-2.074-3.321-2.074-5.379v-34.834a5.402 5.402 0 011.385-10.621h7.239v-1.767a5.556 5.556 0 015.598-5.585h12.555a5.556 5.556 0 015.599 5.585v1.767h7.239a5.4 5.4 0 015.354 4.702 5.398 5.398 0 01-3.967 5.919zm-29.549-10.621h18.097v-1.767a2.724 2.724 0 00-2.772-2.756h-12.555a2.72 2.72 0 00-2.771 2.756v1.767zm26.721 10.745h-35.346v34.71c0 2.659 1.88 4.736 4.298 4.736h26.75c2.417 0 4.298-2.077 4.298-4.736v-34.71zm3.986-5.372a2.544 2.544 0 00-2.544-2.544h-38.23a2.545 2.545 0 100 5.089h38.23a2.544 2.544 0 002.544-2.545zM213.5 234.735c.78 0 1.414-.634 1.414-1.414V206.6a1.415 1.415 0 00-2.828 0v26.721a1.415 1.415 0 001.414 1.414z" />
                      </svg>
                    </button>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Website;
