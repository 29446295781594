import React from "react";
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import TopNavBar from "../common-components/TopNavbar";
import { Row, Col } from "reactstrap";

const AccountSettingsLayout = ({ children }) => {
  return (
    <div className="main-wrapper setting-wrapper ">
      <AppSidebarNavbar />
      <main>
        <div className="app-container">
          <div className="app-content">
            <TopNavBar />
            <div className="setting-page">
              <div className="white-container coming-soon">
                <div className="w-100 setting-menu">
                  <Row>
                    <Col sm="12">{children}</Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AccountSettingsLayout;
