import React from "react";

import { TabPane, Row, Form, Col, Spinner } from "reactstrap";

const ChangePassword = ({
  handelCPasswordSubmit,
  curPasswordShown,
  handelPasswordChange,
  userPassword,
  eyeToggleCur,
  passErrors,
  newPasswordShown,
  eyeToggleNew,
  conPasswordShown,
  eyeToggleCon,
  btnLoader,
}) => {
  return (
    <TabPane tabId="2">
      <Form className="password-tab" onSubmit={handelCPasswordSubmit}>
        <div className="step-block">
          <Row>
            <Col md={12} xl={6}>
              <div className="form-group app-ctrl">
                <label className="required">Current Password</label>
                <div className="with-icon">
                  <input
                    type={curPasswordShown ? "text" : "password"}
                    className="form-control"
                    defaultValue=""
                    placeholder="Enter old password"
                    onChange={handelPasswordChange}
                    value={userPassword.CurrentPassword}
                    name="CurrentPassword"
                  />
                  <span className="input-icon">
                    <i
                      className={`passTogEye icon-eye ${curPasswordShown ? "passVisible" : ""}`}
                      onClick={eyeToggleCur}
                    ></i>
                  </span>
                  <div className="err-message pl-0 pt-0">{passErrors.CurrentPassword}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={6}>
              <div className="form-group app-ctrl">
                <label className="required">New Password</label>
                <div className="with-icon">
                  <input
                    type={newPasswordShown ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter new password"
                    onChange={handelPasswordChange}
                    value={userPassword.NewPassword}
                    name="NewPassword"
                  />
                  <span className="input-icon">
                    <i
                      className={`passTogEye icon-eye ${newPasswordShown ? "passVisible" : ""}`}
                      onClick={eyeToggleNew}
                    ></i>
                  </span>
                  <div className="err-message pl-0 pt-0">{passErrors.NewPassword}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={6}>
              <div className="form-group app-ctrl">
                <label className="required">Confirm New Password</label>
                <div className="with-icon">
                  <input
                    type={conPasswordShown ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter confirm password"
                    onChange={handelPasswordChange}
                    value={userPassword.ConfirmPassword}
                    name="ConfirmPassword"
                  />
                  <span className="input-icon">
                    <i
                      className={`passTogEye icon-eye ${conPasswordShown ? "passVisible" : ""}`}
                      onClick={eyeToggleCon}
                    ></i>
                  </span>
                  <div className="err-message pl-0 pt-0">{passErrors.ConfirmPassword}</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={12} xl={6}>
            <div className="btn-box d-flex ">
              <button
                type="submit"
                className={`btn app-btn large-btn ${btnLoader ? "btn-loading-state" : ""}`}
              >
                <div className="button-inner">
                  {btnLoader && <Spinner style={{ verticalAlign: "middle" }} />}
                  <span className="btn-text">Update</span>
                </div>
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </TabPane>
  );
};

export default ChangePassword;
