import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

// apis
import Api from "../../API/go-high-level";
import AppPageLoader from "../../common-components/AppPageLoader";

import {
  CommonNotificationBlue,
  CommonNotificationGreen,
  ErrorMessage,
  SaveButton,
  SyncLocationLabel,
} from "../OtherCrmCommonComponente/OtherCrmCommonComponents";
import AgencyKeyError from "./AgencyKeyError";

const SyncLocation = ({
  IsAgencyKeyError,
  credentials,
  LocationSyncId,
  LocationMappingData,
  GhlLocationList,
  AgencyKey,
  setFunction,
  GHLLocationApiKey,
}) => {
  // states==============
  const [locationSyncId, setLocationSyncId] = useState(LocationSyncId);
  const [selectedRLLocationName, setSelectedRLLocationName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedGhlLocation, setSelectedGhlLocation] = useState(LocationSyncId);
  const [sectionLoader, setSectionLoader] = useState(true);

  // get Rl location list=============
  const getSelectedRLLocationName = () => {
    setSectionLoader(true);
    Api.getIntegrationLocations({ IntegrationId: credentials.id })
      .then((resp) => {
        setSectionLoader(false);
        if (resp.data && resp.data.Result) {
          let location = resp.data.Result.find(
            (lctn) => lctn.LocationId === credentials.locationId
          );
          setSelectedRLLocationName(location.LocationName);
        }
      })
      .catch((e) => {
        setSectionLoader(false);
      })
      .finally(() => setSectionLoader(false));
  };

  // handle change location============
  const handleChange = (e) => setSelectedGhlLocation(e.target.value);

  // handle submit location=============
  const submitSelectedGhlLocation = () => {
    let selectedLocationFromGhlLocationList = GhlLocationList.find(
      (v) => v.id === selectedGhlLocation
    );

    const payLoad = {
      AccountId: credentials.accountId,
      LocationId: credentials.locationId,
      SyncId: selectedGhlLocation,
      CRMType: credentials.CRMType,
      Location: selectedGhlLocation === "0" ? "" : selectedLocationFromGhlLocationList,
    };

    if (selectedGhlLocation === "1") {
      toast.error(
        "Please select appropriate option for CRM Location to save location mapping settings."
      );
      return;
    }
    Api.InsertLocationMapping(payLoad)
      .then((res) => {
        if (res.data.IsSuccess === true) {
          setIsDisabled(true);
          payLoad.SyncId === "0"
            ? toast.success("We will create new location in CRM.")
            : toast.success("CRM location mapping saved successfully.");
          setLocationSyncId(payLoad.SyncId);
          setFunction(payLoad, AgencyKey);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.Message);
      });
  };

  useEffect(() => {
    getSelectedRLLocationName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setting apiData for this section
  useEffect(() => {
    setLocationSyncId(LocationSyncId);
    setSelectedGhlLocation(LocationSyncId);
    if (LocationSyncId !== "1") {
      if (
        GhlLocationList &&
        GhlLocationList.length > 0 &&
        GHLLocationApiKey &&
        GHLLocationApiKey.OldlocationApiKey.length > 0 &&
        GHLLocationApiKey.OldlocationApiKey.length < 37
      ) {
        let ghlLocation = GhlLocationList.find((v) => v.id === LocationSyncId);

        const payloadData = {
          AccountId: credentials.accountId,
          LocationId: credentials.locationId,
          SyncId: LocationSyncId,
          CRMType: credentials.CRMType,
          AllowDuplicatePatient: true,
          Location: ghlLocation,
        };

        Api.InsertLocationMapping(payloadData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LocationSyncId]);

  return (
    <div className="white-container padding-white-card ">
      <div className="row app-box-wrapper  pt-0  ">
        <div className="box-col col-xs-12 ">
          <h2 className="info-title-blue mb-2">Sync Locations </h2>
          <div className="box-col col-xs-12 locationInfo-col my-4">
            <p className="location-note text-info">
              Select an existing Custom CRM location or create a new one to map with this location
            </p>
          </div>
          <form>
            <div className="row  px-2">
              <div className="box-col col-xs-12 col-md-12 col-lg-6 col-xl-6 locationInfo-col">
                {sectionLoader && <AppPageLoader />}
                <div className="form-group">
                  <SyncLocationLabel label={"DrDDS Sync Location A"} />
                  <div className="w-100 multiselect-row pb-0 mb-1">
                    <div className=" multiselect-col-1">
                      <select className="form-control my-auto" disabled>
                        <option>{selectedRLLocationName}</option>
                      </select>
                    </div>
                    <div className="sync-text ">
                      <p>Sync To</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box-col col-xs-12 col-md-12 col-lg-6 col-xl-6 locationInfo-col">
                {sectionLoader && <AppPageLoader />}
                <div className="form-group">
                  <SyncLocationLabel label={"Custom CRM Location"} />

                  <div className="w-100 multiselect-row pb-0 mb-1">
                    <div className=" multiselect-col-1">
                      <select
                        disabled={locationSyncId !== "1"}
                        className="form-control my-auto"
                        name="CRMLocation"
                        onChange={handleChange}
                      >
                        <option value={"1"} selected={locationSyncId === "1"}>
                          Select the CRM Location
                        </option>
                        {!IsAgencyKeyError && (
                          <option value={"0"} selected={locationSyncId === "0"}>
                            Create New
                          </option>
                        )}
                        {!IsAgencyKeyError &&
                          GhlLocationList &&
                          GhlLocationList.map((e, key) => {
                            return (
                              <option
                                key={`location-${key}`}
                                value={e.id}
                                selected={locationSyncId === e.id}
                              >
                                {e.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {locationSyncId === "1" &&
                      !IsAgencyKeyError &&
                      selectedRLLocationName.length > 0 && (
                        <SaveButton
                          isDisabled={isDisabled}
                          SaveBtnOnClick={submitSelectedGhlLocation}
                        />
                      )}
                  </div>
                </div>
              </div>

              {IsAgencyKeyError && <AgencyKeyError />}

              {locationSyncId === "1" ? (
                <div className="d-none"></div>
              ) : locationSyncId === "0" ? (
                <CommonNotificationGreen>
                  We are creating a new location in CRM, you will be notified once it is done. Once
                  the location is ready, you will be able to sync users and appointment.
                </CommonNotificationGreen>
              ) : (
                locationSyncId !== "1" && (
                  <CommonNotificationBlue>
                    Location mapping cannot be changed once it is setup.
                  </CommonNotificationBlue>
                )
              )}

              {LocationMappingData && (
                <ErrorMessage>{LocationMappingData.ResponseError}</ErrorMessage>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SyncLocation;
