import React, { useState, useEffect } from "react";
// component imports
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import TopNavBar from "../common-components/TopNavbar";
import AppPageLoader from "../common-components/AppPageLoader";
import input from "react-number-format";

import Api from "../API/profile";

import { toast } from "react-toastify";
import { validZipCodeRegex, validPhoneRegex, validEmailRegex } from "../config/appRegex";
import { validateForm } from "../config/commonFun";
import { Spinner } from "reactstrap";
import RemoveLocation from "../models/RemoveLocation";
import { API_URL } from "../config/appConstatnts";
import ChangeLocations from "../models/ChangeLocations";

const Locations = () => {
  const [AddLocation, setAddLocation] = React.useState("");
  const [EditLocation, setEditLocation] = React.useState("");
  const [loaderForLocation, setLoaderForLocation] = useState(false);

  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [newBtnLoader, setNewBtnLoader] = useState(false);

  const [timeZone, setTimeZone] = useState([]);

  const [showLocationModal, setShowLocationModal] = useState(false);
  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  const [address, setAddress] = useState({
    Timezone: 0,
    State: {
      Id: 0,
      Name: "",
      Code: "",
    },
    Country: {
      Id: 0,
      Name: "",
      Code: "",
      ISOCode: "",
    },
    Id: 0,
    Name: "",
    Address1: "",
    Address2: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    ContactType: 0,
    NPINumber: "",
  });

  const [selectedAddress, setSelectedAddress] = useState({
    Timezone: 0,
    State: {
      Id: 0,
      Name: "",
      Code: "",
    },
    Country: {
      Id: 0,
      Name: "",
      Code: "",
      ISOCode: "",
    },
    Id: 0,
    Name: "",
    Address1: "",
    Address2: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    ContactType: 0,
    NPINumber: "",
  });

  const [selectedAddressErrors, setSelectedAddressErrors] = useState({
    Timezone: "",
    State: "",
    Country: "",
    Name: "",
    Address1: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    NPINumber: "",
  });

  const [newAddress, setNewAddress] = useState({
    Timezone: 0,
    State: {
      Id: 0,
      Name: "",
      Code: "",
    },
    Country: {
      Id: 0,
      Name: "",
      Code: "",
      ISOCode: "",
    },
    Id: 0,
    Name: "",
    Address1: "",
    Address2: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    ContactType: 0,
    NPINumber: "",
  });

  const [newAddressErrors, setNewAddressErrors] = useState({
    Timezone: "",
    State: "",
    Country: "",
    Name: "",
    Address1: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    NPINumber: "",
  });

  const [stateList, setStateList] = useState([
    {
      Id: 1,
      Name: "",
      Code: "",
    },
  ]);

  const [newAddressPrimaryCKB, setNewAddressPrimaryCKB] = useState(false);
  const [selectedAddressPrimaryCKB, setSelectedAddressPrimaryCKB] = useState(false);
  const [removeLocationToggle, setRemoveLocationToggle] = useState(false);

  const toggleNewPrimaryCKB = (event) => {
    setNewAddressPrimaryCKB(!newAddressPrimaryCKB);
  };

  const toggleSelectedPrimaryCKB = (event) => {
    event.preventDefault();
    if (selectedAddress.ContactType !== 1) {
      setSelectedAddressPrimaryCKB(!selectedAddressPrimaryCKB);
    }
  };

  const updateAddLocation = (stateList) => {
    setNewAddress({
      Timezone: 0,
      State: {
        Id: 0,
        Name: "",
        Code: "",
      },
      Country: {
        Id: 0,
        Name: "",
        Code: "",
        ISOCode: "",
      },
      Id: 0,
      Name: "",
      Address1: "",
      Address2: "",
      City: "",
      ZipCode: "",
      Email: "",
      Phone: "",
      Fax: "",
      ContactType: 0,
      NPINumber: "",
    });
    setNewAddressErrors({
      Timezone: "",
      State: "",
      Country: "",
      Name: "",
      Address1: "",
      City: "",
      ZipCode: "",
      Email: "",
      Phone: "",
      Fax: "",
      NPINumber: "",
    });
    setNewAddressPrimaryCKB(false);
    if (stateList) {
      setAddLocation("active");
      updateEditLocation(false, null);
    } else {
      setAddLocation("");
      setNewAddress({
        Timezone: 0,
        State: {
          Id: 0,
          Name: "",
          Code: "",
        },
        Country: {
          Id: 0,
          Name: "",
          Code: "",
          ISOCode: "",
        },
        Id: 0,
        Name: "",
        Address1: "",
        Address2: "",
        City: "",
        ZipCode: "",
        Email: "",
        Phone: "",
        Fax: "",
        ContactType: 0,
        NPINumber: "",
      });
      setNewAddressErrors({
        Timezone: "",
        State: "",
        Country: "",
        Name: "",
        Address1: "",
        City: "",
        ZipCode: "",
        Email: "",
        Phone: "",
        Fax: "",
        NPINumber: "",
      });
    }
  };

  const updateEditLocation = (stateList, id) => {
    setSelectedAddress({
      Timezone: 0,
      State: {
        Id: 0,
        Name: "",
        Code: "",
      },
      Country: {
        Id: 0,
        Name: "",
        Code: "",
        ISOCode: "",
      },
      Id: 0,
      Name: "",
      Address1: "",
      Address2: "",
      City: "",
      ZipCode: "",
      Email: "",
      Phone: "",
      Fax: "",
      ContactType: 0,
      NPINumber: "",
    });
    setSelectedAddressErrors({
      Timezone: "",
      State: "",
      Country: "",
      Name: "",
      Address1: "",
      City: "",
      ZipCode: "",
      Email: "",
      Phone: "",
      Fax: "",
      NPINumber: "",
    });
    setSelectedAddressPrimaryCKB(false);
    if (stateList && id) {
      setEditLocation("active");
      updateAddLocation(false);
      setSelectedAddress(address.find((a) => a.Id === id));
    } else {
      setEditLocation("");
    }
  };

  const removeLocationFromLocation = () => {
    setRemoveLocationToggle(true);
  };

  const onSubmitOKOfRemoveLocation = () => {
    if (selectedAddress.ContactType === 1) {
      toast.error("Primary location can not be delete.");
    } else {
      fetch(API_URL + `/Profile/RemoveAddress?Id=${selectedAddress.Id}`, {
        method: "POST",
        headers: {
          access_token: localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLoaderForLocation(true);
          if (data === 1) {
            let allAddresses = [...address];
            allAddresses.forEach((location, key) => {
              if (location.Id === selectedAddress.Id) {
                allAddresses.splice(key, 1);
              }
            });
            setTimeout(() => {
              toast.success("Location details deleted successfully.");
              setAddress(allAddresses);
              updateEditLocation(false, null);
              setLoaderForLocation(false);
            }, 800);
          } else if (data === 2) {
            setLoaderForLocation(false);
            toast.error(
              "Somethings is not correct while deleteing Location. Please try again after some time."
            );
          } else if (data === 0) {
            setLoaderForLocation(false);
            toast.warn(
              "Please disable all PMS connectors from Integration Settings to delete this location."
            );
          } else if (data === 3) {
            setLoaderForLocation(false);
            setShowLocationModal(true);
          } else {
            setLoaderForLocation(false);
            toast.error(
              "Somethings is not correct while deleteing location. Please try again after some time."
            );
          }
        })
        .catch((err) => {
          setLoaderForLocation(false);
          toast.error(
            "Somethings is not correct while deleteing location. Please try again after some time."
          );
        });
    }
  };

  const getAddress = (id) => {
    if (id > 0) {
      setLoaderForLocation(true);
      Api.getAddress(id)
        .then((res) => {
          let result = res.data.Result;
          if (result.length > 0) {
            result.sort((a, b) => b.ContactType - a.ContactType);
          }
          setAddress(result);
          setLoaderForLocation(false);
        })
        .catch((error) => {
          setLoaderForLocation(false);
        });
    }
  };

  const getState = () => {
    Api.getState("US")
      .then((res) => {
        setStateList(res.data.Result);
      })
      .catch((error) => {});
  };

  const getTimeZone = () => {
    setLoaderForLocation(true);
    Api.getTimeZone()
      .then((res) => {
        let result = res.data.Result;
        if (result.length > 0) {
          result.sort((a, b) => b.ContactType - a.ContactType);
        }
        setTimeZone(result);
        setLoaderForLocation(false);
      })
      .catch((error) => {
        setLoaderForLocation(false);
      });
  };

  const handelSelectedAddressChange = (event) => {
    event.persist();
    let { name, value } = event.target;
    let errorValue = "";
    if (name === "Timezone") {
      setSelectedAddress((address) => ({
        ...address,
        Timezone: value,
      }));
    }

    if (name !== "State" || name !== "Timezone") {
      setSelectedAddress((address) => ({
        ...address,
        [name]: value,
      }));
    }
    switch (name) {
      case "Name":
        errorValue = value.trim().length === 0 ? "Location Name is required." : "";
        break;
      case "Address1":
        errorValue = value.trim().length === 0 ? "Street Address is required." : "";
        break;
      case "Email":
        if (value.trim().length === 0) {
          errorValue = "Email is required.";
        } else {
          errorValue = validEmailRegex.test(value) ? "" : "Enter valid email.";
        }
        break;
      case "Phone":
        errorValue = value === "" || validPhoneRegex.test(value) ? "" : "Enter valid Phone Number.";
        break;
      case "ZipCode":
        if (value.trim().length === 0) {
          errorValue = "Zip Code is required.";
        } else {
          errorValue = validZipCodeRegex.test(value) ? "" : "Enter valid Zip Code.";
        }
        break;
      case "City":
        errorValue = value.trim().length === 0 ? "City is required." : "";
        break;
      case "State":
        let objState = { ...selectedAddress };
        if (objState.State == null || value === "") {
          objState.State = { Id: 0, Name: "", Code: "" };
          setSelectedAddress(objState);
        }
        if (objState.State != null && value !== "") {
          let selectedSt = stateList.filter((v) => v.Code === value);
          objState.State = selectedSt[0];
          setSelectedAddress(objState);
        }
        errorValue = value.trim().length === 0 ? "State is required." : "";
        break;
      case "Timezone":
        errorValue = value.trim().length === 0 ? "Timezone is required." : "";
        break;

      default:
        break;
    }
    setSelectedAddressErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelSelectedAddressSubmit = (event) => {
    let flagError = false;
    event.persist();
    event.preventDefault();
    if (selectedAddress.Name.length === 0) {
      setSelectedAddressErrors((errors) => ({
        ...errors,
        Name: "Location Name is required.",
      }));
      flagError = true;
    }
    if (selectedAddress.Address1.length === 0) {
      setSelectedAddressErrors((errors) => ({
        ...errors,
        Address1: "Street Address is required.",
      }));
      flagError = true;
    }
    if (selectedAddress.City.length === 0) {
      setSelectedAddressErrors((errors) => ({
        ...errors,
        City: "City is required.",
      }));
      flagError = true;
    }
    if (
      selectedAddress.State == null ||
      selectedAddress.State.Code == null ||
      // selectedAddress.State.Code == undefined ||
      selectedAddress.State.Code === 0
    ) {
      setSelectedAddressErrors((errors) => ({
        ...errors,
        State: "State is required.",
      }));
      flagError = true;
    }
    if (
      selectedAddress.Timezone === 0 ||
      selectedAddress.Timezone === "0" ||
      selectedAddress.Timezone == null
    ) {
      setSelectedAddressErrors((errors) => ({
        ...errors,
        Timezone: "Timezone is required.",
      }));
      flagError = true;
    }
    if (selectedAddress.ZipCode.length === 0) {
      setSelectedAddressErrors((errors) => ({
        ...errors,
        ZipCode: "ZipCode is required.",
      }));
      flagError = true;
    }
    if (selectedAddress.Email.length === 0) {
      setSelectedAddressErrors((errors) => ({
        ...errors,
        Email: "Email is required.",
      }));
      flagError = true;
    }
    if (validateForm(selectedAddressErrors) && !flagError) {
      setEditBtnLoader(true);
      let payLoad = { ...selectedAddress };
      if (selectedAddressPrimaryCKB) {
        payLoad.ContactType = 1;
      }
      Api.postAddress(payLoad)
        .then((res) => {
          getAddress(user.UserId);
          setTimeout(() => {
            toast.success("Location updated successfully!");
            setEditBtnLoader(false);
            updateEditLocation(false, null);
          }, 800);
        })
        .catch((error) => {
          setEditBtnLoader(false);
          if (error.response.status === 400 && error.response.data.Result) {
            toast.error(error.response.data.Message);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
    if (flagError) {
      toast.error("There is some error in page.");
    }
  };

  const handelNewAddressChange = (event) => {
    event.persist();
    let { name, value } = event.target;
    let errorValue = "";
    if (name === "Timezone") {
      setNewAddress((address) => ({
        ...address,
        [name]: value,
      }));
    }
    if (name !== "State" && name !== "Primary") {
      setNewAddress((address) => ({
        ...address,
        [name]: value,
      }));
    }
    switch (name) {
      case "Name":
        errorValue = value.trim().length === 0 ? "Location Name is required." : "";
        break;
      case "Address1":
        errorValue = value.trim().length === 0 ? "Street Address is required." : "";
        break;
      case "Email":
        if (value.trim().length === 0) {
          errorValue = "Email is required.";
        } else {
          errorValue = validEmailRegex.test(value) ? "" : "Enter valid email.";
        }
        break;
      case "Phone":
        errorValue = value === "" || validPhoneRegex.test(value) ? "" : "Enter valid Phone Number.";
        break;
      case "ZipCode":
        if (value.trim().length === 0) {
          errorValue = "Zip Code is required.";
        } else {
          errorValue = validZipCodeRegex.test(value) ? "" : "Enter valid Zip Code.";
        }
        break;
      case "City":
        errorValue = value.trim().length === 0 ? "City is required." : "";
        break;
      case "Timezone":
        errorValue = value.trim().length === 0 ? "Timezone is required." : "";
        break;
      case "State":
        let objState = newAddress.State;
        if (objState != null) {
          objState.Code = value;
          newAddress.State = objState;
        } else {
          let obj = {
            Id: 0,
            Name: "",
            Code: "",
          };
          obj.Code = value;
          newAddress.State = obj;
        }
        errorValue = value.trim().length === 0 ? "State is required." : "";
        break;

      default:
        break;
    }
    setNewAddressErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelNewAddressSubmit = (event) => {
    let flagError = false;
    event.persist();
    event.preventDefault();

    if (newAddress.Name.length === 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        Name: "Location Name is required.",
      }));
      flagError = true;
    }
    if (newAddress.Address1.length === 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        Address1: "Street Address is required.",
      }));
      flagError = true;
    }
    if (newAddress.City.length === 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        City: "City is required.",
      }));
      flagError = true;
    }
    if (newAddress.State == null || newAddress.State.Code == null || newAddress.State.Code === 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        State: "State is required.",
      }));
      flagError = true;
    }
    if (newAddress.Timezone === 0) {
      setNewAddressErrors((error) => ({
        ...error,
        Timezone: "Timezone is required.",
      }));
      flagError = true;
    }
    if (newAddress.ZipCode.length === 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        ZipCode: "ZipCode is required.",
      }));
      flagError = true;
    }
    if (newAddress.Email.length === 0) {
      setNewAddressErrors((errors) => ({
        ...errors,
        Email: "Email is required.",
      }));
      flagError = true;
    }
    if (validateForm(newAddressErrors) && !flagError) {
      setNewBtnLoader(true);
      let data = { ...newAddress };
      if (newAddressPrimaryCKB) {
        data.ContactType = 1;
      }
      Api.postAddress(data)
        .then((res) => {
          getAddress(user.UserId);
          setTimeout(() => {
            setNewBtnLoader(false);
            updateAddLocation(false);
            toast.success("Location updated successfully!");
          }, 800);
        })
        .catch((error) => {
          setNewBtnLoader(false);
          if (error.response.status === 400 && error.response.data.Result) {
            toast.error(error.response.data.Message);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userData")));
    getAddress(user.UserId);
    getState();
    getTimeZone();
  }, [user.UserId]);

  useEffect(() => {
    if (selectedAddress.ContactType && selectedAddress.ContactType === 1) {
      setSelectedAddressPrimaryCKB(true);
    }
  }, [selectedAddress.ContactType]);

  return (
    <div className="main-wrapper">
      <AppSidebarNavbar activeTabsBilling={true} />
      <main>
        <div className="app-container">
          <div className="app-content">
            <TopNavBar />
            <div className="location-page">
              <div className="grid-section">
                <div className="white-container position-relative">
                  <div className="location-list-row primary-location-row">
                    <div className="location-inner">
                      {address.length > 0 &&
                        address.map((add, i) =>
                          add.ContactType === 1 ? (
                            <div className="single-location" key={`location-${i}`}>
                              <div className="location-head">
                                <h4>{add.Name}</h4>
                                <button
                                  className="app-btn app-btn-clear edit-btn "
                                  onClick={() => updateEditLocation(true, add.Id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 16 17"
                                  >
                                    <path
                                      fill="#707070"
                                      d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                                    />
                                  </svg>
                                </button>
                              </div>
                              <div className="location-detail">
                                <div className="location-txt">
                                  <p>
                                    {add.Address1}, {add.Address2 ? add.Address2 + ", " : ""}{" "}
                                    {add.State !== "" &&
                                    add.State != null &&
                                    add.State.Code.length > 0
                                      ? add.State.Code + ", "
                                      : ""}
                                    {add.ZipCode}
                                  </p>
                                  <p>
                                    <a className="contact-link" href={`mailto:${add.Email}`}>
                                      {add.Email}
                                    </a>
                                  </p>
                                  <p>
                                    <a className="contact-link" href={`tel:${add.Phone}`}>
                                      {add.Phone}
                                    </a>
                                  </p>
                                  {add.NPINumber ? <p>NPI Number :- {add.NPINumber}</p> : null}
                                </div>
                                <button type="button" className="btn app-btn large-btn ">
                                  Primary
                                </button>
                              </div>
                            </div>
                          ) : null
                        )}
                    </div>
                    <div className="location-btn">
                      <button
                        type="button"
                        className={`btn app-btn large-btn ${
                          AddLocation === "active" ? "" : "active"
                        }`}
                        onClick={() => updateAddLocation(true)}
                      >
                        + add new location
                      </button>
                    </div>
                  </div>
                  <div className="location-list-row">
                    <div className="location-inner">
                      {address.length > 0 &&
                        address.map((add, i) =>
                          add.ContactType !== 1 ? (
                            <div className="single-location" key={`single-location-${i}`}>
                              <div className="location-head">
                                <h4>{add.Name}</h4>
                                <button
                                  className="app-btn app-btn-clear edit-btn "
                                  onClick={() => updateEditLocation(true, add.Id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 16 17"
                                  >
                                    <path
                                      fill="#707070"
                                      d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                                    />
                                  </svg>
                                </button>
                              </div>
                              <div className="location-detail">
                                <div className="location-txt">
                                  <p>
                                    {add.Address1}, {add.Address2 ? add.Address2 + ", " : ""}{" "}
                                    {add.State !== "" &&
                                    add.State != null &&
                                    add.State.Code.length > 0
                                      ? add.State.Code + ", "
                                      : ""}
                                    {add.ZipCode}
                                  </p>
                                  <p>
                                    <a className="contact-link" href={`mailto:${add.Email}`}>
                                      {add.Email}
                                    </a>
                                  </p>
                                  <p>
                                    <a className="contact-link" href={`tel:${add.Phone}`}>
                                      {add.Phone}
                                    </a>
                                  </p>
                                  {add.NPINumber ? <p>NPI Number :- {add.NPINumber}</p> : null}
                                </div>
                              </div>
                            </div>
                          ) : null
                        )}
                    </div>
                  </div>
                  {loaderForLocation && <AppPageLoader />}
                </div>

                <div
                  className={`white-container form-container ${
                    AddLocation === "active" ? "active" : ""
                  } `}
                >
                  <div className="inner-form-container">
                    <div className="form-head">
                      <h4>Add New Location</h4>
                    </div>
                    <div className="location-form">
                      <form onSubmit={handelNewAddressSubmit}>
                        <div className="row insurance-detail-sec">
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">Location Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={newAddress.Name}
                                name="Name"
                                onChange={handelNewAddressChange}
                              />
                              {newAddressErrors.Name.length > 0 && (
                                <div className="err-message">{newAddressErrors.Name}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">Street Address</label>
                              <input
                                type="text"
                                className="form-control"
                                value={newAddress.Address1}
                                name="Address1"
                                onChange={handelNewAddressChange}
                              />
                              {newAddressErrors.Address1.length > 0 && (
                                <div className="err-message">{newAddressErrors.Address1}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">City</label>
                              <input
                                type="text"
                                className="form-control"
                                value={newAddress.City}
                                name="City"
                                onChange={handelNewAddressChange}
                              />
                              {newAddressErrors.City.length > 0 && (
                                <div className="err-message">{newAddressErrors.City}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">State</label>
                              <select
                                className="form-control"
                                name="State"
                                onChange={handelNewAddressChange}
                                value={newAddress.State != null ? newAddress.State.Code : ""}
                              >
                                <option value="">Select State</option>
                                {stateList.length > 0 &&
                                  stateList.map((s) => (
                                    <option value={s.Code} key={`${s.Code}-option-${s.Name}`}>
                                      {s.Name}
                                    </option>
                                  ))}
                              </select>
                              {newAddressErrors.State.length > 0 && (
                                <div className="err-message">{newAddressErrors.State}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">Zip code</label>
                              <input
                                type="text"
                                className="form-control"
                                value={newAddress.ZipCode}
                                name="ZipCode"
                                onChange={handelNewAddressChange}
                              />
                              {newAddressErrors.ZipCode.length > 0 && (
                                <div className="err-message">{newAddressErrors.ZipCode}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">Timezone</label>
                              <select
                                className="form-control"
                                name="Timezone"
                                onChange={handelNewAddressChange}
                                value={newAddress.Timezone != null ? newAddress.Timezone : ""}
                              >
                                <option value="">Select Timezone</option>
                                {timeZone.length > 0 &&
                                  timeZone.map((t) => (
                                    <option
                                      value={t.TimeZoneId}
                                      key={`${t.TimeZoneId}-option-${t.TimeZoneText}`}
                                    >
                                      {t.TimeZoneText}
                                    </option>
                                  ))}
                              </select>
                              {newAddressErrors.Timezone.length > 0 && (
                                <div className="err-message">{newAddressErrors.Timezone}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">Email</label>
                              <input
                                type="text"
                                className="form-control"
                                value={newAddress.Email}
                                name="Email"
                                onChange={handelNewAddressChange}
                              />
                              {newAddressErrors.Email.length > 0 && (
                                <div className="err-message">{newAddressErrors.Email}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label>Phone Number</label>
                              <input
                                type="text"
                                className="form-control"
                                value={newAddress.Phone}
                                name="Phone"
                                onChange={handelNewAddressChange}
                              />
                              {newAddressErrors.Phone.length > 0 && (
                                <div className="err-message">{newAddressErrors.Phone}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label>Fax Number</label>
                              <input
                                type="text"
                                className="form-control"
                                value={newAddress.Fax}
                                name="Fax"
                                onChange={handelNewAddressChange}
                              />
                              {newAddressErrors.Fax.length > 0 && (
                                <div className="err-message">{newAddressErrors.Fax}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label>NPI Number</label>
                              <input
                                type="text"
                                className="form-control"
                                name="NPINumber"
                                value={newAddress.NPINumber}
                                onChange={handelNewAddressChange}
                              />
                              {newAddressErrors.NPINumber.length > 0 && (
                                <div className="err-message">{newAddressErrors.NPINumber}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <a href="https://npiregistry.cms.hhs.gov/">
                              {" "}
                              <i className="fa fa-search"> </i> Lookup NPI Number
                            </a>
                          </div>
                          <div className=" col-12 chk-col">
                            <div
                              className="custom-control custom-checkbox app-checkbox"
                              onClick={toggleNewPrimaryCKB}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={newAddressPrimaryCKB}
                              />
                              <label className="custom-control-label" htmlFor="chk2">
                                Set as Primary Location
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="btn-box d-flex">
                          <button
                            type="button"
                            className="btn app-btn lightgray-btn large-btn mr-2"
                            onClick={() => updateAddLocation(false)}
                          >
                            cancel
                          </button>

                          <button type="submit" className="btn app-btn large-btn ">
                            {newBtnLoader && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-2 location-spinner"
                              />
                            )}
                            Add location
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  className={`white-container form-container ${
                    EditLocation === "active" ? "active" : ""
                  } `}
                >
                  <div className="inner-form-container">
                    <div className="form-head">
                      <h4>Edit Location</h4>
                    </div>
                    <div className="location-form">
                      <form onSubmit={handelSelectedAddressSubmit}>
                        <div className="row insurance-detail-sec">
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">Location Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={selectedAddress.Name}
                                name="Name"
                                onChange={handelSelectedAddressChange}
                              />
                              {selectedAddressErrors.Name.length > 0 && (
                                <div className="err-message">{selectedAddressErrors.Name}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">Street Address</label>
                              <input
                                type="text"
                                className="form-control"
                                value={selectedAddress.Address1}
                                name="Address1"
                                onChange={handelSelectedAddressChange}
                              />
                              {selectedAddressErrors.Address1.length > 0 && (
                                <div className="err-message">{selectedAddressErrors.Address1}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">City</label>
                              <input
                                type="text"
                                className="form-control"
                                value={selectedAddress.City}
                                name="City"
                                onChange={handelSelectedAddressChange}
                              />
                              {selectedAddressErrors.City.length > 0 && (
                                <div className="err-message">{selectedAddressErrors.City}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">State</label>
                              <select
                                className="form-control"
                                name="State"
                                onChange={handelSelectedAddressChange}
                                value={
                                  selectedAddress.State != null ? selectedAddress.State.Code : ""
                                }
                              >
                                <option value="">Select State</option>
                                {stateList.length > 0 &&
                                  stateList.map((s) => (
                                    <option value={s.Code} key={`${s.Code}-optionEdit-${s.Name}`}>
                                      {s.Name}
                                    </option>
                                  ))}
                              </select>
                              {selectedAddressErrors.State.length > 0 && (
                                <div className="err-message">{selectedAddressErrors.State}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">Zip code</label>
                              <input
                                type="text"
                                className="form-control"
                                value={selectedAddress.ZipCode}
                                name="ZipCode"
                                onChange={handelSelectedAddressChange}
                              />
                              {selectedAddressErrors.ZipCode.length > 0 && (
                                <div className="err-message">{selectedAddressErrors.ZipCode}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">Timezone </label>
                              <select
                                className="form-control"
                                name="Timezone"
                                onChange={handelSelectedAddressChange}
                                value={
                                  selectedAddress.Timezone != null ? selectedAddress.Timezone : ""
                                }
                              >
                                <option value="">Select Timezone</option>
                                {timeZone.length > 0 &&
                                  timeZone.map((t) => (
                                    <option
                                      value={t.TimeZoneId}
                                      key={`${t.TimeZoneId}-optionEdit-${t.TimeZoneText}`}
                                    >
                                      {t.TimeZoneText}
                                    </option>
                                  ))}
                              </select>
                              {selectedAddressErrors.Timezone !== "" && (
                                <div className="err-message">{selectedAddressErrors.Timezone}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="required">Email</label>
                              <input
                                type="text"
                                className="form-control"
                                value={selectedAddress.Email}
                                name="Email"
                                onChange={handelSelectedAddressChange}
                              />
                              {selectedAddressErrors.Email.length > 0 && (
                                <div className="err-message">{selectedAddressErrors.Email}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label>Phone Number</label>
                              <input
                                type="text"
                                className="form-control"
                                value={selectedAddress.Phone}
                                name="Phone"
                                onChange={handelSelectedAddressChange}
                              />
                              {selectedAddressErrors.Phone.length > 0 && (
                                <div className="err-message">{selectedAddressErrors.Phone}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label className="">Fax Number</label>
                              <input
                                type="text"
                                className="form-control"
                                value={selectedAddress.Fax}
                                name="Fax"
                                onChange={handelSelectedAddressChange}
                              />
                              {selectedAddressErrors.Fax.length > 0 && (
                                <div className="err-message">{selectedAddressErrors.Fax}</div>
                              )}
                            </div>
                          </div>
                          <div className=" col-12 ">
                            <div className="form-group app-ctrl has-value">
                              <label>NPI Number</label>
                              <input
                                type="text"
                                className="form-control"
                                name="NPINumber"
                                value={selectedAddress.NPINumber}
                                onChange={handelSelectedAddressChange}
                              />
                              {selectedAddressErrors.NPINumber.length > 0 && (
                                <div className="err-message">{selectedAddressErrors.NPINumber}</div>
                              )}
                            </div>
                          </div>
                          {selectedAddress.NPINumber.length === 0 && (
                            <div className=" col-12 ">
                              <a href="https://npiregistry.cms.hhs.gov/">
                                {" "}
                                <i className="fa fa-search"> </i> Lookup NPI Number
                              </a>
                            </div>
                          )}
                          <div className=" col-12 chk-col">
                            <div
                              className="custom-control custom-checkbox app-checkbox"
                              onClick={toggleSelectedPrimaryCKB}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="chk3"
                                checked={selectedAddressPrimaryCKB}
                                disabled={selectedAddress.ContactType === 1}
                              />
                              <label className="custom-control-label" htmlFor="chk3">
                                Set as Primary Location
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="btn-box d-flex justify-content-around">
                          <button
                            type="button"
                            className="btn app-btn lightgray-btn large-btn"
                            style={{ padding: "12px 15px" }}
                            onClick={() => updateEditLocation(false, null)}
                          >
                            cancel
                          </button>
                          <button
                            type="button"
                            className="btn app-btn lightgray-btn large-btn"
                            style={{ padding: "12px 15px" }}
                            onClick={() => removeLocationFromLocation(false, selectedAddress)}
                          >
                            remove
                          </button>
                          <button
                            type="submit"
                            className="btn app-btn large-btn "
                            style={{ padding: "12px 10px" }}
                          >
                            {editBtnLoader && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-2 location-spinner"
                              />
                            )}
                            Save location
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <RemoveLocation
        show={removeLocationToggle}
        selectedLocation={selectedAddress.Id}
        onHandleOK={(selectedAddress) => onSubmitOKOfRemoveLocation(selectedAddress)}
        onHide={() => setRemoveLocationToggle(!removeLocationToggle)}
      />
      <ChangeLocations
        show={showLocationModal}
        message="Team members are allocated to this location so Please assign different location to the Team Members to delete this address."
        locationId={selectedAddress.Id}
      />
    </div>
  );
};
export default Locations;
