import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import Api from "../API/go-high-level";

const SyncPatientModal = (props) => {
  
  const [loader, setSectionLoader] = useState(false);
  const [IsAppointment, setIsAppointment] = useState(true);

  const SyncNote = "All the patient & their appointments will sync into GoHighLevel which is available in Recordlinc.";
  const NotSyncNote = "Only Appointment will sync into GoHighLevel with one dummy patient.";

  useEffect(() => {
    setIsAppointment(true);
     });

 const SyncPatientToGHL = (value) =>{
    props.onSuccess(value)
    props.onHide(); 
 }

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model integration-modal locationInfo-model"
        id="resyncAppt"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6" style={{textTransform:"unset"}}>
            Are you sure want to sync patient into GoHighLevel?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
                <lable className="text-info notes-font-size"><b>Note</b> : </lable>
            </div>
            <div className="row ">
                <b className=" text-justify notes-font-size">
                    SYNC : {SyncNote}
                </b>
                <b className=" text-justify notes-font-size pb-2">
                    NOT SYNC : {NotSyncNote}
                </b>
                <b className=" text-justify notes-font-size text-danger">
                    Please make sure do not delete any patient from GoHighLevel. Once the patient will delete all the 
                    Appointment of that patient will delete from GoHighLevel.
                </b>
            </div>

            <div className="mx-2 custom-control custom-checkbox app-checkbox primaryLocation-chk"
                style={{
                fontSize: "16px",
                lineHeight: "20px",
                wordSpacing: ".50px",
                textAlign: "justify",
            }}>
                
            </div>

            <div className="btn-box d-flex">
              <button title={NotSyncNote}
                type="button"
                className="btn app-btn large-btn mr-2"
                onClick={() => SyncPatientToGHL(false)}>
                Not Sync
              </button>
              <button title={SyncNote} type="button" className="btn app-btn large-btn" 
                onClick={() =>  SyncPatientToGHL(true)}>                
                Sync
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SyncPatientModal;
