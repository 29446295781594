import React from "react";

const TeamMemberTableTopSection = ({
  filters,
  locationChange,
  addresses,
  memberTypeChange,
  searchForTextValue,
  searchString,
  searchStringChange,
}) => {
  return (
    <div className="action-row d-flex pt-0">
      <div className="left-box d-flex">
        <div className="form-group app-select" style={{ width: "226px" }}>
          <select
            className="form-control"
            value={filters.LocationBy}
            name="Addressess"
            onChange={locationChange}
          >
            <option value="0">All Location</option>
            {addresses.length > 0 &&
              addresses.map((address) => (
                <option value={address.Id} key={`${address.Id}-option-${address.Name}`}>
                  {address.Name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group app-select" style={{ width: "226px" }}>
          <select
            className="form-control"
            value={filters.ProviderTypefilter}
            onChange={memberTypeChange}
          >
            <option value="0">Select Staff/Team Member</option>
            <option value="2">Only Staff</option>
            <option value="1">Only Team Member</option>
          </select>
        </div>
      </div>
      <div className="right-box ml-auto d-flex align-items-start">
        <form onSubmit={searchForTextValue}>
          <div className="form-group input-group app-search">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchString}
              onChange={searchStringChange}
            />
            <span className="search-icon">
              <i className="fa fa-search"></i>
            </span>
            <button className="btn search-btn" type="submit">
              <span>Search</span>
              <i className="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TeamMemberTableTopSection;
