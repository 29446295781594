import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
//component import
import Api from "../API/go-high-level";
import AppointmentReSyncType from "../OtherCrm/SyncAppointment/AppointmentReSyncType";

const ReSyncAppointmentModal = (props) => {
  
  const [loader, setSectionLoader] = useState(false);
  const [IsAppointment, setIsAppointment] = useState(true);
  const [IsAgree, setIsAgree] = useState(false);
  const [ModalNotes, setModalNotes] = useState([]);

  useEffect(() => {
    setIsAppointment(true);
    setIsAgree(false);
    if(props.show){
          getMessages();
      }
    }, [props.show]);

const getMessages = () =>
  {
        setSectionLoader(true);
        Api.getMessages(1)
        .then((res) => {
            if(res.data.IsSuccess === true)
            {
                let lstNotes = [];
                lstNotes = [...res.data.Result];
                setModalNotes(lstNotes)
            }
            setSectionLoader(false);
        })
        .catch((error)=>{
            toast.error(error?.response?.data?.Message ?? "Something went wrong");
            setSectionLoader(false);
        })
  }

  const onChangeReSyncType = (value) => {
    if (value) {
        setIsAppointment(!IsAppointment);
    }
    if (!value) {
      setIsAppointment(!IsAppointment);
    }
  };

  const onChangeAgree = () => {
    IsAgree ? setIsAgree(false) : setIsAgree(true);
  }

  const onClickReSync = () =>{
      const data = {
          LocationId: props.LocationId,
          IsAppointmentReSync: true,
          Type : IsAppointment ? 1 : 2       
        };
        
      setSectionLoader(true);
      Api.appointmentResync(data)
      .then((res) => {
        if(res.data.IsSuccess === true)
        {
            props.onHide(false, null, false);
            toast.success("Appointment re-sync submitted successfully. It will take upto 2 to 3 hrs to reflect into GHL");
            if(!IsAppointment)
                props.UpdateCalendarOperatoryList();
        }
        setSectionLoader(false);
      })
      .catch((error)=>{
        toast.error(error?.response?.data?.Message ?? "Something went wrong");
        setSectionLoader(false);
    })
  }


  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model integration-modal locationInfo-model"
        id="resyncAppt"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Re-Sync Future Appointments
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
                <lable className="text-info notes-font-size"><b>Note</b> : </lable>
            </div>
            <div className="row ">
                {ModalNotes && ModalNotes.map((note, index) =>
                    <tr key={index}>
                        <td className=" text-justify notes-font-size">{index + 1}.</td>
                        <td className="pl-2 text-justify notes-font-size">{note.Message}</td>
                    </tr>
                )}
            </div>
            <AppointmentReSyncType
                IsAppointment={IsAppointment}
                onChangeReSyncType={onChangeReSyncType}
              />

        
        <div className="mx-2 custom-control custom-checkbox app-checkbox primaryLocation-chk"
            style={{
            fontSize: "16px",
            lineHeight: "20px",
            wordSpacing: ".50px",
            textAlign: "justify",
            }}>
                <input type="checkbox"
                id="agreeResync"
                // name="processName"
                // value={false}
                className="custom-control-input"
                onChange={onChangeAgree}
                checked={IsAgree}
                />

                <label className="custom-control-label my-2"
                htmlFor="agreeResync"
                style={{ fontWeight: "normal" }}
                >
                    <span style={{ fontWeight: "bold" }}>Agree, I want to re-sync all the future appointments into 
                    GHL.</span>
                </label>
          </div>

            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={() => props.onHide(false, null, false)}
              >
                Cancel
              </button>
              <button type="button" disabled={!IsAgree} className={`btn app-btn large-btn ${!IsAgree ? 'disable-btn' : ''}`} onClick={onClickReSync}>
                {loader && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2 location-spinner"
                  />
                )}
                Refresh
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ReSyncAppointmentModal;
