import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
import { Spinner } from "reactstrap";
//component import
import * as inputFun from "../common-components/FormInputFunctions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Api from "../API/integration";
import { ToastContainer, toast } from "react-toastify";

export default function DisableCRMToggle(props) {
  function coppyMessage() {
    toast.info("Key copied.", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  const [currentKey, SetCurrentKey] = useState(null);
  const [loader, setLoader] = useState(false);

  const handleOk = () => {
    setLoader(true);
    let location = props.location;
    props.onHandleOK(location);
    props.onHide();
  };

  const onChangeHnadler = (event) => {
    SetCurrentKey(event.target.value);
  };
  useEffect(() => {
    SetCurrentKey(props.location.key);
  }, [props.location.key]);
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model integration-modal locationInfo-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            {props.location && props.location.LocationName
              ? props.location.LocationName
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className="col-12 locationInfo-col">
                <div className="form-group">
                  <div className="info-ctrl mb-3">
                    <p className="mb-3">
                      All patients and appointments data sync through DrDDs will
                      be deleted from your Other CRM account.
                      <br />
                    </p>
                    <p>
                      <strong className="mt-5">
                        Are you sure, you want to disable the integration?
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={() => props.onHide()}
              >
                No
              </button>
              <button
                type="button"
                className="btn app-btn large-btn "
                onClick={handleOk}
              >
                {loader && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2 location-spinner"
                  />
                )}
                Yes
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
