import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { toast } from "react-toastify";

import {
  CommonNotificationBlue,
  SaveButton,
  SectionWithCheckbox,
} from "../OtherCrmCommonComponente/OtherCrmCommonComponents";
import TopNotificationMessage from "./TopNotificationMessage";
import SyncOpportunityLayout from "./SyncOpportunityLayout";

import Api from "../../API/go-high-level";

const CalculationNote = () => {
  return(
    <>
      <i className="pl-2 fa fa-info-circle" title={`How we Calculate the lead value of Opportunity \nProduction : Sum of ADACode start from 0 to 9999, \nCollection : Sum of Standard Payment & Insurance Payment`}></i>
    </>
  );
};

const SyncOpportunity = ({
  opportunityMonitorValueToggle,
  IsSyncOpportunity,
  LocationSyncId,
  OpportunityMonitoryValueMapping,
  IsAgencyKeyError,
  credentials,
}) => {
  //  constants ==========
  const TreatmentOptions = [
    { typeId: 1, name: "Production" },
    { typeId: 2, name: "Hygiene" },
    { typeId: 3, name: "Collection" },
  ];

  // states =========
  const [isSyncOpportunity, setIsSyncOpportunity] = useState(false);
  const [opportunityMonitoryValueMapping, setOpportunityMonitoryValueMapping] = useState(
    OpportunityMonitoryValueMapping
  );
  const [submitOMV, setSubmitOMV] = useState({ Treatement: 0 });

  // handle change omv=========
  const handleChange = (e) => setSubmitOMV({ Treatement: Number(e.target.value) });

  // handle submit omv==========
  const handleSubmit = () => {
    if (submitOMV.Treatement === 0) {
      toast.error("Please Select Treatment");
      return;
    }
    const payLoadData = {
      RLLocationId: credentials.locationId,
      CRMType: credentials.CRMType,
      Treatment: submitOMV.Treatement,
    };
    Api.AddOpportunity(payLoadData)
      .then((resp) => {
        toast.success("Opportunity sync settings are saved successfully.");
        setOpportunityMonitoryValueMapping({ Treatement: submitOMV.Treatement });
      })
      .catch((err) => toast.error("Error in Sending data"));
  };

  useEffect(() => {
    setIsSyncOpportunity(IsSyncOpportunity);
  }, [IsSyncOpportunity]);

  useEffect(() => {
    setOpportunityMonitoryValueMapping(OpportunityMonitoryValueMapping);
    setSubmitOMV(OpportunityMonitoryValueMapping);
  }, [OpportunityMonitoryValueMapping]);

  return (
    <>
      <SectionWithCheckbox
        className={isSyncOpportunity ? "pb-2" : ""}
        title={"Sync Opportunity Lead Value "}
        formDisable={IsAgencyKeyError}
        onChangeCheckbox={opportunityMonitorValueToggle}
        checked={isSyncOpportunity}
        switchId={"custom-switch-opportunity"}
        />

      <Collapse className="panel-accordion additionalClassForContent" isOpen={IsSyncOpportunity}>
        <div className="collapse-content-scroll">
          {LocationSyncId === "1" ? (
            <TopNotificationMessage />
          ) : (
            <form className="mt-4">
              {opportunityMonitoryValueMapping && submitOMV && (
                <>
                  <SyncOpportunityLayout>
                    <p className="pl-2 icon-label">Update Lead Value field with sum of <CalculationNote/></p>
                    <div className="w-100 multiselect-row pb-0 mb-3">
                      <div className="w-75 multiselect-col-1">
                        <select
                          className="form-control my-auto pr-1"
                          name="Treatment"
                          disabled={opportunityMonitoryValueMapping.Treatement !== 0}
                          onChange={handleChange}
                        >
                          <option value={0} selected={submitOMV.Treatement === 0}>
                            Select Treatment
                          </option>
                          {TreatmentOptions.map((treatmentType) => {
                            return (
                              <option
                                key={`treatmentType-${treatmentType.typeId}`}
                                value={treatmentType.typeId}
                                selected={submitOMV.Treatement === treatmentType.typeId}
                              >
                                {treatmentType.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {opportunityMonitoryValueMapping.Treatement === 0 && (
                        <SaveButton SaveBtnOnClick={handleSubmit} />
                      )}
                    </div>
                  </SyncOpportunityLayout>
                </>
              )}

              {opportunityMonitoryValueMapping &&
                opportunityMonitoryValueMapping.Treatement !== 0 && (
                  <CommonNotificationBlue>
                    Mapping cannot be changed once it is setup.
                  </CommonNotificationBlue>
                )}
            </form>
          )}
        </div>
      </Collapse>
    </>
  );
};

export default SyncOpportunity;
