import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Api from "../API/profile";
//component import
import { validWebSiteRegex } from "../config/appRegex";
import { toast } from "react-toastify";
import AppPageLoader from "../common-components/AppPageLoader";

export default function AddWebsiteModal(props) {
  const [loader, setLoader] = useState(false);
  const [webSite, setWebSite] = useState({
    SecondaryWebsiteId: 0,
    UserId: 0,
    SecondaryWebsiteurl: "",
    IsPrimary: false,
  });
  const [errors, setErrors] = useState("");
  // const label = "Website Link " + (props.websiteNo > 0 ? props.websiteNo : "");
  useEffect(() => {
    if (
      props.SecondaryWebsiteId &&
      props.SecondaryWebsiteId !== undefined &&
      props.SecondaryWebsiteId !== 0
    ) {
      Api.getWebSiteDetail(props.SecondaryWebsiteId)
        .then((res) => {
          setWebSite(res.data);
        })
        .catch((error) => {});
    } else {
      setWebSite({
        SecondaryWebsiteId: 0,
        UserId: 0,
        SecondaryWebsiteurl: "",
        IsPrimary: false,
      });
      setErrors("");
    }
  }, [props.SecondaryWebsiteId]);

  const handelChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    let errorValue = "";
    setWebSite((webSite) => ({
      ...webSite,
      [name]: value,
    }));
    if (value.length === 0) {
      errorValue = "Website is required.";
    } else {
      errorValue = validWebSiteRegex.test(value) ? "" : "Enter valid website.";
    }
    setErrors(errorValue);
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    let errorValue = "";
    if (webSite.SecondaryWebsiteurl.length === 0) {
      errorValue = "Website is required.";
    } else {
      errorValue = validWebSiteRegex.test(webSite.SecondaryWebsiteurl)
        ? ""
        : "Enter valid website.";
    }
    setErrors(errorValue);
    if (errorValue.length === 0) {
      setLoader(true);
      Api.postWebSiteDetail(webSite)
        .then((res) => {
          props.onHide();
          setLoader(false);
          if (webSite.SecondaryWebsiteId > 0) {
            toast.success("Website updated successfully!");
          } else {
            toast.success("Website added successfully!");
          }
          props.reload();
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Something went wrong");
        });
    }
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Add Website
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          {loader && <AppPageLoader className={"position-fixed"} />}
          <form onSubmit={handelSubmit}>
            <div className="row ">
              <div className=" col-12 ">
                <div class="form-group app-ctrl has-value">
                  <label class="">Website Url</label>
                  <input
                    type="text"
                    name="SecondaryWebsiteurl"
                    value={webSite.SecondaryWebsiteurl}
                    onChange={handelChange}
                    className="form-control"
                    defaultValue=""
                  />
                </div>
                {errors.length > 0 && <span className="err-message">{errors}</span>}
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>

              <button type="submit" className="btn app-btn large-btn ">
                <div className="button-inner">
                  <span className="btn-text">
                    {webSite.SecondaryWebsiteId === 0 ? "Save" : "Update"}
                  </span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
