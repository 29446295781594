import React from "react";
import FeatherIcon from "feather-icons-react";

const TeamMemberTable = ({
  selectUnSelectAllRecords,
  isAllSelected,
  sortingColumn,
  setCurrentSortColumn,
  teamMeambers,
  selectUnSelectRecords,
  addEditBtnClicked,
  deletBtnClicked,
}) => {
  const TableHeader = ({ name, placeHolder, sorting }) => {
    return (
      <th className="shorting">
        {placeHolder}
        <button onClick={() => setCurrentSortColumn(`${name}`)} className="shorting-btn">
          <FeatherIcon icon={sorting === 2 ? "chevron-up" : "chevron-down"} size="20" />
        </button>
      </th>
    );
  };
  return (
    <div className="app-table">
      <div className="table-responsive">
        <table className="table table-hover table-borderless invoice-grid">
          <thead>
            <tr>
              <th className="chk-col">
                <div className="custom-control custom-checkbox app-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="chkallTeamMembers"
                    isChecked={
                      teamMeambers && teamMeambers.every((member) => member.isSelected === true)
                    }
                    onChange={selectUnSelectAllRecords}
                  />
                  <label className="custom-control-label" htmlFor="chkallTeamMembers"></label>
                </div>
              </th>
              <TableHeader
                name={"lastName"}
                placeHolder={"Last Name"}
                sorting={sortingColumn.lastName.sortDir}
              />
              <TableHeader
                name={"firstName"}
                placeHolder={"First Name"}
                sorting={sortingColumn.firstName.sortDir}
              />
              <TableHeader
                name={"email"}
                placeHolder={"Email"}
                sorting={sortingColumn.email.sortDir}
              />
              <TableHeader
                name={"speciality"}
                placeHolder={"Speciality"}
                sorting={sortingColumn.speciality.sortDir}
              />
              <TableHeader
                name={"location"}
                placeHolder={"Location"}
                sorting={sortingColumn.location.sortDir}
              />
              <th className="shorting">Type</th>
              <th className="shorting">Actions</th>
            </tr>
          </thead>
          <tbody>
            {teamMeambers.length > 0 ? (
              teamMeambers.map((item, index) => (
                <tr key={item.TeamMemberId}>
                  <td className="chk-col">
                    <div className="custom-control custom-checkbox app-checkbox">
                      <input
                        onClick={() => selectUnSelectRecords(index)}
                        type="checkbox"
                        className="custom-control-input"
                        id={item.TeamMemberId}
                        checked={item.isSelected}
                      />
                      <label className="custom-control-label" htmlFor={item.TeamMemberId}></label>
                    </div>
                  </td>
                  <td>{item.LastName}</td>
                  <td>{item.FirstName}</td>
                  <td>{item.Email}</td>
                  <td>{item.Specialty}</td>
                  <td>{item.Location}</td>
                  <td>{item.ProviderType === 2 ? "Staff" : "Provider"}</td>
                  <td>
                    <button
                      className="action-btn app-btn-clear"
                      onClick={() => addEditBtnClicked(item.TeamMemberId, "edit")}
                    >
                      <FeatherIcon icon="edit-2" size="20" />
                    </button>
                    {item.AccessOfDelete === true && (
                      <button
                        className="action-btn app-btn-clear"
                        onClick={() => deletBtnClicked(item.TeamMemberId)}
                      >
                        <FeatherIcon icon="trash-2" size="20" />
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" style={{ textAlign: "center" }}>
                  No record found!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamMemberTable;
