import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import Api from "../../API/go-high-level";
import { toast } from "react-toastify";
import { removeDeletedGhlId } from "../../config/commonFun";
import {
  CommonNotificationBlue,
  CommonNotificationGreen,
  SaveButton,
  SectionWithCheckbox,
} from "../OtherCrmCommonComponente/OtherCrmCommonComponents";
import AppointmentWritebackSection from "./AppointmentWritebackSection";
import AppPageLoader from "../../common-components/AppPageLoader";
import ProcessType from "./ProcessType";
import WriteBackDefault from "./WriteBackDefault";
import RowSection from "./RowSection";

const SyncAppointment = ({
  credentials,
  isSyncAppointment,
  pmsDoctorsList,
  AllowApptWriteBack,
  GhlCalendarList,
  appointmentToggle,
  LocationSyncId,
  CalendarOptMapData,
  IsAgencyKeyError,
  isOPSyncAutomatically,
  AutoCalendarOptMapData,
  WritebackSetting,
  SetIsOPSyncAutomatically,
}) => {
  const [IsSyncAppointment, setIsSyncAppointment] = useState(false);
  const [IsOPSyncAutomatically, setIsOPSyncAutomatically] = useState(false);
  const [CalendarOptMappingData, setCalendarOptMappingData] = useState([]);
  const [initialOperatoryOptionLength, setinitialOperatoryOptionLength] = useState(0);
  const [OptOptions, setOptOptions] = useState([]);
  const [IntOpOptn, setIntOpOptn] = useState([]);
  const [submitCalendarOpt, setSubmitCalendarOpt] = useState([]);
  const [doctorTreatmentRightback, setDoctorTreatmentRightback] = useState([]);
  const [allOptions, setallOptions] = useState([]);
  const [DoctorsOptions, setDoctorsOptions] = useState([]);

  const [sectionLoader, setSectionLoader] = useState(false);
  const [appointmentRightBack, setAppointmentRightBack] = useState(false);

  const [writeBackSettng, setwriteBackSettng] = useState({
    RLDoctorId: 0,
    Treatment: "",
    AllowApptWriteBack: false,
  });

  const [PreviouslySavedData, setPreviouslySavedData] = useState(null);

  const handleSubmitAppointmentSyncSelect = () => {
    let flag = false;
    let opt = [...OptOptions];

    if (!opt) {
      flag = false;
    } else {
      for (let i = 0; i < opt.length; i++) {
        if (opt[i].CalendarId === "" || opt[i].CalendarId == null) {
          opt[i].CalendarId = "1";
        }

        if (opt[i].OperatoryOptions.length === 0) {
          toast.error("Please select calendar operatory mapping to save settings.");
          return (flag = false);
        } else {
          for (let j = 0; j < opt.length; j++) {
            if (j !== i) {
              if (
                opt[i].CalendarId === opt[j].CalendarId &&
                opt[i].OperatoryOptions.length === opt[j].OperatoryOptions.length
              ) {
                flag =
                  JSON.stringify(opt[i].OperatoryOptions.sort((a, b) => a - b)) ===
                  JSON.stringify(opt[j].OperatoryOptions.sort((a, b) => a - b));
                if (flag === true) {
                  toast.error(
                    "You can not select same mapping for two rows, please change selection"
                  );
                  return flag;
                }
              }
            }
          }
        }

        if (opt[i].AllowApptWriteBack === true && appointmentRightBack && opt[i].RLDoctorId === 0) {
          toast.error("Please select doctor to write back appointment to PMS.");
          return (flag = false);
        }

        if (opt[i].AllowApptWriteBack === true && appointmentRightBack && opt[i].Treatment === "") {
          toast.error("Please select treatment to write back appointment to PMS.");
          return (flag = false);
        }
      }
    }

    if (appointmentRightBack === false) {
      opt.forEach((op) => {
        op.AllowApptWriteBack = false;
        op.RLDoctorId = 0;
        op.Treatment = "";
      });
      setOptOptions([...opt]);
    }

    const data = {
      AccountId: credentials.accountId,
      LocationId: credentials.locationId,
      CRMType: credentials.CRMType,
      OperatorySync: opt.map(
        ({ CalendarId, OperatoryOptions, RLDoctorId, Treatment, AllowApptWriteBack }) => ({
          CalendarId,
          Operatorys: OperatoryOptions,
          RLDoctorId:
            appointmentRightBack && AllowApptWriteBack === true ? JSON.parse(RLDoctorId) : 0,
          Treatment: appointmentRightBack && AllowApptWriteBack === true ? Treatment : "",
          AllowApptWriteBack: appointmentRightBack && AllowApptWriteBack === true ? true : false,
        })
      ),
      IsOPSyncAutomatically,
    };

    if (flag === false) {
      setSectionLoader(true);
      Api.InsertCalenderOpSync(data)
        .then((res) => {
          if (res.data.IsSuccess === true) {
            toast.success("Appointment sync settings are saved successfully");
            SetIsOPSyncAutomatically(data.IsOPSyncAutomatically);
            setOptOptions(getSavedOptOptions(opt));
            setinitialOperatoryOptionLength(opt.length);
            let calendar = opt.map(({ CalendarId, RLDoctorId, Treatment, AllowApptWriteBack }) => ({
              CalendarId,
              RLDoctorId:
                appointmentRightBack === true && AllowApptWriteBack === true
                  ? JSON.parse(RLDoctorId)
                  : 0,
              Treatment:
                appointmentRightBack === true && AllowApptWriteBack === true ? Treatment : "",
              AllowApptWriteBack,
            }));
            setSubmitCalendarOpt([...calendar]);

            let drTrtmntRb = opt.map(({ RLDoctorId, Treatment, AllowApptWriteBack }) => ({
              RLDoctorId:
                appointmentRightBack === true && AllowApptWriteBack === true
                  ? JSON.parse(RLDoctorId)
                  : 0,
              Treatment:
                appointmentRightBack === true && AllowApptWriteBack === true ? Treatment : "",
              AllowApptWriteBack: AllowApptWriteBack,
            }));
            setDoctorTreatmentRightback([...drTrtmntRb]);

            setIntOpOptn([...calendar]);
            data.OperatorySync.every((k) => k.AllowApptWriteBack === false)
              ? setAppointmentRightBack(false)
              : setAppointmentRightBack(true);

            setPreviouslySavedData({
              OptOptions: getSavedOptOptions(opt),
              submitCalendarOpt: calendar,
              doctorTreatmentRightback: drTrtmntRb,
              initialOperatoryOptionLength: opt.length,
            });
          }
          setSectionLoader(false);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.Message ?? "Something went wrong");
          setSectionLoader(false);
        });
    }
  };

  const handleChangeOperatorys = (e, index, nm) => {
    if (nm.name === "Operatorys") {
      let sub = [];
      sub = OptOptions.slice();
      sub[index].OperatoryOptions = Array.isArray(e) ? e.map((x) => x.value) : [];
      setOptOptions([...sub]);
    }
  };

  const handleAddNewRow = () => {
    if (
      OptOptions.length !== 0 &&
      OptOptions[OptOptions.length - 1].OperatoryOptions.length === 0
    ) {
      toast.error("Please select mapping for existing row before creating new one");
    } else {
      setOptOptions([
        ...OptOptions,
        {
          OperatoryOptions: [],
          CalendarId: "1",
          ...writeBackSettng,
        },
      ]);

      setSubmitCalendarOpt([
        ...submitCalendarOpt,
        {
          CalendarId: "1",
          ...writeBackSettng,
        },
      ]);

      setDoctorTreatmentRightback([...doctorTreatmentRightback, { ...writeBackSettng }]);
    }
  };

  const handleRemoveRow = (e, index) => {
    let removeValue = [...OptOptions];
    removeValue.splice(index, 1);

    if (removeValue.length === 0) {
      setinitialOperatoryOptionLength(0);
    }
    if (index < initialOperatoryOptionLength) {
      setinitialOperatoryOptionLength(initialOperatoryOptionLength - 1);
    }
    setOptOptions([...removeValue]);
    let cal = [...submitCalendarOpt];
    cal.splice(index, 1);
    setSubmitCalendarOpt([...cal]);

    let dctrTreatmentRb = [...doctorTreatmentRightback];
    dctrTreatmentRb.splice(index, 1);
    setDoctorTreatmentRightback([...dctrTreatmentRb]);
  };

  const handleChangeCalendar = (e, index, nm) => {
    if (nm === "CalendarId" && OptOptions) {
      let s = [];
      s = [...OptOptions];
      s[index].CalendarId = e.target.value !== null ? e.target.value : "";
      setOptOptions(s);
    }
  };

  const handleDoctorProcedure = (e, index, nm) => {
    if (nm === "Treatment") {
      let treatment = [];
      treatment = [...OptOptions];
      treatment[index].Treatment = e.target.value !== null ? e.target.value : "";
      setOptOptions(treatment);

      let apptWriteBackTreatment = [];
      apptWriteBackTreatment = [...doctorTreatmentRightback];
      apptWriteBackTreatment[index].Treatment =
        e.target.value != null || e.target.value !== "" ? e.target.value : "";
      setDoctorTreatmentRightback(apptWriteBackTreatment);
    }
    if (nm === "Doctors") {
      let rlDoctor = [];
      rlDoctor = [...OptOptions];
      rlDoctor[index].RLDoctorId = e.target.value !== null ? e.target.value : 0;
      setOptOptions(rlDoctor);

      let apptWriteBackDoctor = [];
      apptWriteBackDoctor = [...doctorTreatmentRightback];
      apptWriteBackDoctor[index].RLDoctorId =
        e.target.value != null || e.target.value !== "0" ? e.target.value : 0;
      setDoctorTreatmentRightback(apptWriteBackDoctor);
    }
    if (nm === "ApptWriteBackCheckbox") {
      let apptWriteBack = [];
      apptWriteBack = [...OptOptions];
      apptWriteBack[index].AllowApptWriteBack = !apptWriteBack[index].AllowApptWriteBack;
      setOptOptions(apptWriteBack);
    }
  };

  const errorMessageTag = (errorMessage) => (
    <div className="box-col col-xs-12 locationInfo-col">
      <p className="location-note text-danger" style={{ fontWeight: "normal" }}>
        {errorMessage}
      </p>
    </div>
  );

  // CommonFunctions=====================================
  const removeGhlApiCallCalendar = (apiPayload) => {
    Api.removeGhlMapping(apiPayload);
  };
  const getSavedOptOptions = (opt) => {
    return opt.map(
      ({ OperatoryOptions, CalendarId, RLDoctorId, Treatment, AllowApptWriteBack }) => ({
        OperatoryOptions,
        CalendarId,
        RLDoctorId: AllowApptWriteBack ? RLDoctorId : 0,
        AllowApptWriteBack,
        Treatment: AllowApptWriteBack ? Treatment : "",
      })
    );
  };
  const getSubmitCalendarOptData = (calOptdata) => {
    return calOptdata.map(({ CalendarId, RLDoctorId, Treatment, AllowApptWriteBack }) => ({
      CalendarId: CalendarId === "Not Sync" || CalendarId === "" ? "1" : CalendarId,
      RLDoctorId,
      Treatment,
      AllowApptWriteBack,
    }));
  };
  const getDoctorTreatmentRightbackData = (doctorTreatmentRb) => {
    return doctorTreatmentRb.map(({ RLDoctorId, Treatment, AllowApptWriteBack }) => ({
      RLDoctorId,
      Treatment,
      AllowApptWriteBack,
    }));
  };
  const getOptOptionsData = (OptOptions) => {
    return OptOptions.map(
      ({ CalendarId, Operatorys, RLDoctorId, Treatment, AllowApptWriteBack, ResponseError }) => ({
        OperatoryOptions: Operatorys.filter((v) => v.IsSync === true).map((val) => val.Id),
        CalendarId: CalendarId === "Not Sync" || CalendarId === "" ? "1" : CalendarId,
        RLDoctorId: RLDoctorId,
        Treatment: Treatment,
        ResponseError,
        AllowApptWriteBack,
      })
    );
  };

  //  Common Functions==========================================

  const getOptMappingForAutomatic = () => {
    setSectionLoader(true);
    Api.getOperatoryMappingForAutomatically(credentials.locationId)
      .then((resp) => {
        const newCalOptData = resp.data.Result;
        setinitialOperatoryOptionLength(newCalOptData.length);
        setSubmitCalendarOpt(getSubmitCalendarOptData(newCalOptData));
        setDoctorTreatmentRightback(getDoctorTreatmentRightbackData(newCalOptData));
        setOptOptions(getOptOptionsData(newCalOptData));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.Message ?? "Something went wrong");
      })
      .finally(() => setSectionLoader(false));
  };

  const onChangeProcessType = (value) => {
    if (value) {
      getOptMappingForAutomatic();
      setIsOPSyncAutomatically(!IsOPSyncAutomatically);
    }
    if (!value) {
      setSectionLoader(true);
      const calOptdata = CalendarOptMapData;
      setCalendarOptMappingData(calOptdata);
      if (!!PreviouslySavedData) {
        setinitialOperatoryOptionLength(PreviouslySavedData.initialOperatoryOptionLength);
        setOptOptions(PreviouslySavedData.OptOptions);
        setSubmitCalendarOpt(PreviouslySavedData.submitCalendarOpt);
        setDoctorTreatmentRightback(PreviouslySavedData.doctorTreatmentRightback);
      } else {
        setinitialOperatoryOptionLength(calOptdata.length);
        setSubmitCalendarOpt(getSubmitCalendarOptData(calOptdata));
        setDoctorTreatmentRightback(getDoctorTreatmentRightbackData(calOptdata));
        setOptOptions(getOptOptionsData(calOptdata));
      }
      setIsOPSyncAutomatically(!IsOPSyncAutomatically);
      setTimeout(() => {
        setSectionLoader(false);
      }, 500);
    }
  };

  useEffect(() => {
    setIsSyncAppointment(isSyncAppointment);
  }, [isSyncAppointment]);

  useEffect(() => {
    if (pmsDoctorsList && pmsDoctorsList.length > 0) {
      let doctors =
        pmsDoctorsList &&
        pmsDoctorsList.map(({ Name, DoctorId }) => ({
          name: Name,
          value: DoctorId,
        }));
      setDoctorsOptions([...doctors]);
    }
  }, [pmsDoctorsList]);

  useEffect(() => {
    setAppointmentRightBack(AllowApptWriteBack);
  }, [AllowApptWriteBack]);

  useEffect(() => {
    if (
      CalendarOptMapData.length > 1 &&
      CalendarOptMapData[CalendarOptMapData.length - 1].CalendarId === ""
    ) {
      CalendarOptMapData.pop();
    }
    if (PreviouslySavedData) return;
    setIsOPSyncAutomatically(isOPSyncAutomatically);
    const calendarOptList = CalendarOptMapData.map((calendarId) => calendarId.CalendarId);
    const ghlServiceList = GhlCalendarList && GhlCalendarList.map((ghlCalendar) => ghlCalendar.id);
    const pushDataIntoArray = ghlServiceList && removeDeletedGhlId(calendarOptList, ghlServiceList);
    const responseDataArray = [];
    for (const key in pushDataIntoArray) {
      const subjectData = pushDataIntoArray[key];
      for (const optmapKey in CalendarOptMapData) {
        if (CalendarOptMapData[optmapKey].CalendarId === subjectData) {
          responseDataArray.push(CalendarOptMapData[optmapKey]);
          CalendarOptMapData.splice(optmapKey, 1);
        }
      }
    }

    if (pushDataIntoArray && pushDataIntoArray.length > 0 && responseDataArray.length > 0) {
      const apiPayload = {
        AccountId: credentials.accountId,
        RLLocationId: credentials.locationId,
        Mapping: responseDataArray.map(({ CalendarId, Operatorys }) => ({
          CRMSyncedId: CalendarId,
          RecordlincId: Operatorys.filter((opt) => opt.IsSync === true).map((opId) => opId.Id),
        })),
        TableId: 4,
      };
      removeGhlApiCallCalendar(apiPayload);
    }
    setCalendarOptMappingData(CalendarOptMapData);
    const calOptdata = isOPSyncAutomatically ? AutoCalendarOptMapData : CalendarOptMapData;
    setIntOpOptn([]);
    let opOpt = calOptdata.map(({ CalendarId }) => ({
      CalendarId: CalendarId === "Not Sync" || CalendarId === "" ? "1" : CalendarId,
    }));
    setIntOpOptn(opOpt);
    setSubmitCalendarOpt(getSubmitCalendarOptData(calOptdata));
    setDoctorTreatmentRightback(getDoctorTreatmentRightbackData(calOptdata));
    setOptOptions(getOptOptionsData(calOptdata));

    const newIntitalOptOptnLength =
      calOptdata?.length === 1 && calOptdata[0]?.OperatoryOptions?.length === 0
        ? 0
        : calOptdata.length;
    setinitialOperatoryOptionLength(newIntitalOptOptnLength);

    let optionValues = [];
    const getCollections = (collection) => {
      let collectionList = [];
      collection.Operatorys.forEach((options) =>
        collectionList.push({
          label: options.Name,
          value: options.Id,
        })
      );
      return collectionList;
    };
    const getSelectedOptionsCollections = (selectedOptionCollection) => {
      let selectedOptnColArray = [];
      selectedOptionCollection.Operatorys.filter((operatory) => operatory.IsSync === true).forEach(
        (options) => {
          selectedOptnColArray.push({
            label: options.Name,
            value: options.Id,
          });
        }
      );
      return selectedOptnColArray;
    };
    CalendarOptMapData.map((calendarValue) =>
      optionValues.push({
        CalendarId: calendarValue.CalendarId === "Not Sync" ? 1 : calendarValue.CalendarId,
        operatoryOptionValue: {
          options: getCollections(calendarValue),
          selectedOptionValue: getSelectedOptionsCollections(calendarValue),
        },
      })
    );

    let optionss = CalendarOptMapData.map(({ CalendarId, Operatorys }) => ({
      CalendarId,
      OperatoryOptions: Operatorys.map(({ Id, Name }) => ({
        value: Id,
        label: Name,
      })),
    }));

    let allOpt = [];
    allOpt = optionss.map((v) => v.OperatoryOptions).reduce((acc, obj) => [...acc, ...obj], []);
    const arrayUniqueByKey = [...new Map(allOpt.map((item) => [item["value"], item])).values()];
    setallOptions(arrayUniqueByKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setwriteBackSettng(WritebackSetting);
  }, [WritebackSetting]);

  const UpdateCalendarOperatoryList = () => 
  {
    const data = {
      AccountId: credentials.accountId,
      LocationId: credentials.locationId
    }
    setSectionLoader(true);
    Api.getGHLCRMMappingDetails(data)
      .then((res) => {
        if (res.data.IsSuccess === true) {         
          res.data.Result.CalendarOptMapping.length > 0
          ? setCalendarOptMappingData(
            simplifyingCRMMappingResponseData(res.data.Result.CalendarOptMapping)
            )
            : setCalendarOptMappingData([]);
          }
          setOptOptions(getOptOptionsData(res.data.Result.CalendarOptMapping));
          setinitialOperatoryOptionLength(0);
          setSectionLoader(false);
      })
      .catch((e) => {
        setSectionLoader(false);        
        setCalendarOptMappingData([]);
      })
      .finally(() => setSectionLoader(false));
  }

  const simplifyingCRMMappingResponseData = (responseData) => {
    const checkResponseError = (error) => {
      let newError = error.includes("<html>") ? "Something went wrong in GHL" : error;
      return newError;
    };
    if (responseData)
      return responseData?.map((autoCal) => ({
        ...autoCal,
        ResponseError: checkResponseError(autoCal.ResponseError),
      }));
  };

  return (
    <>
      {/* Sync Appointment */}
      <SectionWithCheckbox
        className={IsSyncAppointment ? "pb-2" : ""}
        title={"Sync Appointment "}
        formDisable={IsAgencyKeyError}
        onChangeCheckbox={appointmentToggle}
        checked={IsSyncAppointment}
        switchId={"custom-switch-appointment"}
        IsSyncButton={IsSyncAppointment}
        LocationId = {credentials.locationId}
        UpdateCalendarOperatoryList = {UpdateCalendarOperatoryList}
      >
        <b>Note : </b>
        <span>
          Other CRM requires a user, a team and a calendar to sync appointments. If you already have
          calendars in other CRM and want to use the existing calendar no need to map user/doctor.
        </span>
      </SectionWithCheckbox>

      <Collapse className="panel-accordion additionalClassForContent" isOpen={IsSyncAppointment}>
        <div className="collapse-content-scroll">
          {LocationSyncId === "1" || CalendarOptMappingData.length === 0 ? (
            <div style={{ marginTop: "2rem", marginLeft: ".5rem" }}>
              <h5>
                There is no operatory associated with this location. Please create a new operatory
                for this location.
              </h5>
            </div>
          ) : (
            <form>
              <ProcessType
                IsOPSyncAutomatically={IsOPSyncAutomatically}
                onChangeProcessType={onChangeProcessType}
              />
              <AppointmentWritebackSection
                setAppointmentRightBack={setAppointmentRightBack}
                appointmentRightBack={appointmentRightBack}
              />
              <WriteBackDefault
                credentials={credentials}
                DoctorsOptions={DoctorsOptions}
                WritebackSetting={WritebackSetting}
                appointmentRightBack={appointmentRightBack}
                setwriteBackSettng={setwriteBackSettng}
              />

              {CalendarOptMappingData && (
                <RowSection
                  appointmentRightBack={appointmentRightBack}
                  IsOPSyncAutomatically={IsOPSyncAutomatically}
                  handleRemoveRow={handleRemoveRow}
                  handleDoctorProcedure={handleDoctorProcedure}
                  submitCalendarOpt={submitCalendarOpt}
                  initialOperatoryOptionLength={initialOperatoryOptionLength}
                  doctorTreatmentRightback={doctorTreatmentRightback}
                  DoctorsOptions={DoctorsOptions}
                  OptOptions={OptOptions}
                  CalendarOptMapData={CalendarOptMapData}
                  PreviouslySavedData={PreviouslySavedData}
                  allOptions={allOptions}
                  handleChangeOperatorys={handleChangeOperatorys}
                  errorMessageTag={errorMessageTag}
                  handleChangeCalendar={handleChangeCalendar}
                  GhlCalendarList={GhlCalendarList}
                />
              )}

              {CalendarOptMappingData.length > 0 && (
                <div className="sync-text box-col col-xs-12 col-md-12 col-lg-12 col-xl-12 locationInfo-col">
                  <SaveButton SaveBtnOnClick={handleSubmitAppointmentSyncSelect} />
                  {!IsOPSyncAutomatically && (
                    <SaveButton
                      isDisabled={false}
                      SaveBtnOnClick={handleAddNewRow}
                      label={"Add New Item"}
                    />
                  )}
                </div>
              )}

              {CalendarOptMappingData && IntOpOptn.some((opOptn) => opOptn.CalendarId !== "1") ? (
                IntOpOptn.some((opOptn) => opOptn.CalendarId === "0") ? (
                  <CommonNotificationGreen>
                    We are creating a new appointment in CRM, you will be notified once it is done.
                  </CommonNotificationGreen>
                ) : (
                  <CommonNotificationBlue>
                    Appointment mapping cannot be changed once it is setup.
                  </CommonNotificationBlue>
                )
              ) : (
                ""
              )}
            </form>
          )}
        </div>
      </Collapse>
      {sectionLoader && <AppPageLoader className="position-fixed" />}
      {/* endSync Appointment */}
    </>
  );
};

export default React.memo(SyncAppointment);
