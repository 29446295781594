import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

// External URLs
const billingUrl =
  "https://checkout.stripe.com/pay/cs_live_a1Ie9kwpsjy1OTR2sMSrW9UCB22xWRa7V64UmYIWAHexvXQTUVP8y5uzuh#fidkdWxOYHwnPyd1blppbHNgWndzZ3E3NEJ8bV9JT0dCY1d2bFw8YFI1TycpJ3VpbGtuQH11anZgYUxhJz8nYFNkMWJiN0Q1Z0NWYUBmZ0xMJyknd2BjYHd3YHcnPydtcXF1dj8qKmZtYGZuanBxK3Zxd2x1YCtmamgqJ3gl";
const supportUrl =
  "https://recordlinc.atlassian.net/servicedesk/customer/portals";

const TopNavBar = () => {
  const [integration, setIntegration] = useState(false);
  let url = window.location.href;

  //TODO: replace this with useLocation
  useEffect(() => {
    if (url.indexOf("manage") > -1 || url.indexOf("integration") > -1) {
      setIntegration(true);
    }
  }, [url]);

  return (
    <Nav className="setting-nav-menu">
      <NavItem>
        <NavLink
          exact
          to="/setting/integration"
          className={integration ? "active" : ""}
        >
          Integrations
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink exact to="/setting/profile">
          Profile
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink exact to="/setting/location">
          Locations
        </NavLink>
      </NavItem>
      <NavItem>
        <Link to={{ pathname: billingUrl }} target="_blank">
          Billing
        </Link>
      </NavItem>
      <NavItem>
        <NavLink exact to="/setting/accountsetting">
          Account Settings
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink exact to="/setting/teammember">
          Team Members
        </NavLink>
      </NavItem>
      <NavItem>
        <Link to={{ pathname: supportUrl }} target="_blank">
          Support
        </Link>
      </NavItem>
      <NavItem>
        <NavLink exact to="/setting/download-file">
          Download Excel
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default TopNavBar;
