import React from "react";
import { Input } from "reactstrap";
import parse from "html-react-parser";

const Description = ({ handelDes, onClickSaveDes, userDescription }) => {
  return (
    <div className="col-xl-6 col-lg-12">
      <div className="advance-list-profile full-width mb-5">
        <div className="advance-profile-row">
          <div className="sec-header ">
            <h4 className="mr-auto">Description</h4>
          </div>
          <div className="profile-form-group">
            <div className="form-group app-ctrl has-value  text-area-group">
              <Input
                type="textarea"
                name="text"
                id="exampleText"
                onChange={handelDes}
                onBlur={onClickSaveDes}
                value={parse(
                  userDescription != null && userDescription.length > 0
                    ? userDescription.replace(/<br\/>/gi, "\n")
                    : ""
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
