import React from "react";
import uploadIcon from "../assets/images/upload-img.svg";

const Gallery = ({ SetAddImageGalleryModal, userInfo, deleteGallaryImage }) => {
  return (
    <div className="advance-profile-row upload-img-sec">
      <div className="sec-header ">
        <h4 className="mr-auto">Gallery</h4>
      </div>
      <div className="image-upload-sec">
        <div className="image-upload-inner">
          <div
            className="single-img upload-img"
            style={{ backgroundImage: `url(${uploadIcon})` }}
            onClick={() => SetAddImageGalleryModal(true)}
          ></div>

          {userInfo.lstGallary &&
            userInfo.lstGallary.length > 0 &&
            userInfo.lstGallary.map((gall, index) => (
              <div
                key={`lst-gallery-${index}`}
                className="single-img"
                style={{
                  backgroundImage: `url(${userInfo.GallaryPath}/${gall.FileName})`,
                }}
              >
                <img
                  className="single-img"
                  src={`${userInfo.GallaryPath}/${gall.FileName}`}
                  alt="GalleryImage"
                />
                <button
                  onClick={() => deleteGallaryImage(gall.GallaryId)}
                  className="close-btn btn btn-secondary"
                ></button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
