import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
// component imports
import AppSidebarNavbar from "../common-components/AppSidebarNavbar";
import TopNavBar from "../common-components/TopNavbar";
import AddLocationModel from "../models/AddLocationModel";
//images import
import NoteEditIcon from "../assets/images/noteEdit-icon-black.svg";
import HIPPAlogo from "../assets/images/HIPAA-logo.png";

export default function CurveDentelLocation() {
  const [AddLocationModelShow, SetAddLocationModel] = React.useState(false);

  const [LocationAisActive, SetLocationAState] = React.useState(false);
  const [LocationBisActive, SetLocationBState] = React.useState(true);
  const [LocationCisActive, SetLocationCState] = React.useState(false);
  const [LocationDisActive, SetLocationDState] = React.useState(false);
  const [LocationEisActive, SetLocationEState] = React.useState(true);
  const [LocationFisActive, SetLocationFState] = React.useState(false);
  const [LocationGisActive, SetLocationGState] = React.useState(true);
  return (
    <div className="main-wrapper">
      <AppSidebarNavbar activeTabsIntegration={true} />
      <main>
        <div className="app-container">
          <TopNavBar />
          <div className="white-container ">
            <div className="page-info">
              <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link exact to="/setting/integration">
                      Integrations{" "}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Curve Dental
                  </li>
                </ol>
              </nav>
            </div>
            <div className="app-content">
              <div className="row app-box-wrapper">
                <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                  <div className="app-box add-location-box d-flex align-items-center justify-content-center">
                    <div className="add-location">
                      <button
                        className="btn add-btn-round"
                        onClick={() => SetAddLocationModel(true)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                          <path d="M22 9.8v2.4a1 1 0 01-1 1h-7.799V21a1 1 0 01-1 1h-2.4a1 1 0 01-1-1v-7.8H1a1 1 0 01-1-1V9.8a1 1 0 011-1h7.801V1a1 1 0 011-1h2.4a1 1 0 011 1v7.8H21a1 1 0 011 1z" />
                        </svg>
                      </button>
                      <span>ADD NEW LOCATION</span>
                    </div>
                  </div>
                </div>
                <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                  <div className="app-box">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      className="app-switch"
                      defaultChecked={LocationAisActive}
                      onChange={(e) => {
                        SetLocationAState(e.target.checked);
                      }}
                    />
                    <h4 className="box-title">Location A</h4>
                    <p>116 Watterson Parkway Trusville, AL 35173.</p>
                    <div className="form-group">
                      <label className="required mb-2">Key</label>
                      <div className="apikey-box form-group">
                        <input
                          type="text"
                          defaultValue="d2c09992-75a5-47ee-9fec-d6675712612e"
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="btn-box">
                      <Link
                        exact
                        to="#"
                        className={`btn app-btn text-uppercase ${
                          !LocationAisActive ? "gray-btn  disabled" : ""
                        }`}
                      >
                        Manage Connection
                      </Link>
                      <button className="btn app-btn text-uppercase">Generate Key</button>
                    </div>
                  </div>
                </div>
                <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                  <div className="app-box">
                    <Form.Check
                      type="switch"
                      id="custom-switch1"
                      className="app-switch"
                      defaultChecked={LocationBisActive}
                      onChange={(e) => {
                        SetLocationBState(e.target.checked);
                      }}
                    />
                    <h4 className="box-title">Location B</h4>
                    <p>116 Watterson Parkway Trusville, AL 35173.</p>
                    <div className="form-group">
                      <label className="required mb-2">Key</label>
                      <div className="apikey-box form-group">
                        <input
                          type="text"
                          defaultValue="d2c09992-75a5-47ee-9fec-d6675712612e"
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="btn-box">
                      <Link
                        exact
                        to="#"
                        className={`btn app-btn text-uppercase ${
                          !LocationBisActive ? "gray-btn  disabled" : ""
                        }`}
                      >
                        Manage Connection
                      </Link>
                      <button className="btn app-btn text-uppercase">Generate Key</button>
                    </div>
                  </div>
                </div>
                <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                  <div className="app-box">
                    <Form.Check
                      type="switch"
                      id="custom-switch2"
                      className="app-switch"
                      defaultChecked={LocationCisActive}
                      onChange={(e) => {
                        SetLocationCState(e.target.checked);
                      }}
                    />
                    <h4 className="box-title">Location C</h4>
                    <p>116 Watterson Parkway Trusville, AL 35173.</p>
                    <div className="form-group">
                      <label className="required mb-2">Key</label>
                      <div className="apikey-box form-group">
                        <input
                          type="text"
                          defaultValue="d2c09992-75a5-47ee-9fec-d6675712612e"
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="btn-box">
                      <Link
                        exact
                        to="#"
                        className={`btn app-btn text-uppercase ${
                          !LocationCisActive ? "gray-btn  disabled" : ""
                        }`}
                      >
                        Manage Connection
                      </Link>
                      <button className="btn app-btn text-uppercase">Generate Key</button>
                    </div>
                  </div>
                </div>
                <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                  <div className="app-box">
                    <Form.Check
                      type="switch"
                      id="custom-switch3"
                      className="app-switch"
                      defaultChecked={LocationDisActive}
                      onChange={(e) => {
                        SetLocationDState(e.target.checked);
                      }}
                    />
                    <h4 className="box-title">Location D</h4>
                    <p>116 Watterson Parkway Trusville, AL 35173.</p>
                    <div className="form-group">
                      <label className="required mb-2">Key</label>
                      <div className="apikey-box form-group">
                        <input
                          type="text"
                          defaultValue="d2c09992-75a5-47ee-9fec-d6675712612e"
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="btn-box">
                      <Link
                        exact
                        to="#"
                        className={`btn app-btn text-uppercase ${
                          !LocationDisActive ? "gray-btn  disabled" : ""
                        }`}
                      >
                        Manage Connection
                      </Link>
                      <button className="btn app-btn text-uppercase">Generate Key</button>
                    </div>
                  </div>
                </div>
                <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                  <div className="app-box">
                    <Form.Check
                      type="switch"
                      id="custom-switch4"
                      className="app-switch"
                      defaultChecked={LocationEisActive}
                      onChange={(e) => {
                        SetLocationEState(e.target.checked);
                      }}
                    />
                    <h4 className="box-title">Location E</h4>
                    <p>116 Watterson Parkway Trusville, AL 35173.</p>
                    <div className="form-group">
                      <label className="required mb-2">Key</label>
                      <div className="apikey-box form-group">
                        <input
                          type="text"
                          defaultValue="d2c09992-75a5-47ee-9fec-d6675712612e"
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="btn-box">
                      <Link
                        exact
                        to="#"
                        className={`btn app-btn text-uppercase ${
                          !LocationEisActive ? "gray-btn  disabled" : ""
                        }`}
                      >
                        Manage Connection
                      </Link>
                      <button className="btn app-btn text-uppercase">Generate Key</button>
                    </div>
                  </div>
                </div>
                <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                  <div className="app-box">
                    <Form.Check
                      type="switch"
                      id="custom-switch5"
                      className="app-switch"
                      defaultChecked={LocationFisActive}
                      onChange={(e) => {
                        SetLocationFState(e.target.checked);
                      }}
                    />
                    <h4 className="box-title">Location F</h4>
                    <p>116 Watterson Parkway Trusville, AL 35173.</p>
                    <div className="form-group">
                      <label className="required mb-2">Key</label>
                      <div className="apikey-box form-group">
                        <input
                          type="text"
                          defaultValue="d2c09992-75a5-47ee-9fec-d6675712612e"
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="btn-box">
                      <Link
                        exact
                        to="#"
                        className={`btn app-btn text-uppercase ${
                          !LocationFisActive ? "gray-btn  disabled" : ""
                        }`}
                      >
                        Manage Connection
                      </Link>
                      <button className="btn app-btn text-uppercase">Generate Key</button>
                    </div>
                  </div>
                </div>
                <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                  <div className="app-box">
                    <Form.Check
                      type="switch"
                      id="custom-switch6"
                      className="app-switch"
                      defaultChecked={LocationGisActive}
                      onChange={(e) => {
                        SetLocationGState(e.target.checked);
                      }}
                    />
                    <h4 className="box-title">Location G</h4>
                    <p>116 Watterson Parkway Trusville, AL 35173.</p>
                    <div className="form-group">
                      <label className="required mb-2">Key</label>
                      <div className="apikey-box form-group">
                        <input
                          type="text"
                          defaultValue="d2c09992-75a5-47ee-9fec-d6675712612e"
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="btn-box">
                      <Link
                        exact
                        to="#"
                        className={`btn app-btn text-uppercase ${
                          !LocationGisActive ? "gray-btn  disabled" : ""
                        }`}
                      >
                        Manage Connection
                      </Link>
                      <button className="btn app-btn text-uppercase">Generate Key</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row app-box-wrapper mt-2">
                <div className="box-col col-xs-12 col-sm-12 col-lg-12 col-xl-9">
                  <div className="app-box info-box">
                    <div className="left-box">
                      <h2 className="info-title">Pay for an expert! </h2>
                      <p>
                        Need a little assistance with installing the integration tool? No problem!
                        We have experts who can save your time, and get you up and going quickly!
                      </p>
                    </div>
                    <div className="right-box">
                      <div className="amt-info">
                        <img src={NoteEditIcon} alt="Icon" />
                        <strong>$149</strong>
                        <span>One time</span>
                      </div>
                      <div className="btn-box">
                        <button className="btn app-btn small-btn text-uppercase">Learn More</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-col col-xs-12 col-sm-12 col-lg-12 col-xl-3">
                  <div className="hippa-img-box d-flex align-items-center justify-content-center">
                    <img src={HIPPAlogo} alt="HIPPA Logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <AddLocationModel show={AddLocationModelShow} onHide={() => SetAddLocationModel(false)} />
    </div>
  );
}
