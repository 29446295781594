import React from "react";
import { Link } from "react-router-dom";
import { NotificationMessage } from "../OtherCrmCommonComponente/OtherCrmCommonComponents";

const AgencyKeyError = () => {
  return (
    <NotificationMessage className={"text-danger"}>
      There appears to be a problem with the agency API key entered. Please update the key or{" "}
      <Link
        title={"Go to Support Link"}
        to={{
          pathname:
            "https://recordlinc.atlassian.net/servicedesk/customer/user/login?destination=portals",
        }}
        target="_blank"
      >
        contact our support team{" "}
      </Link>
      and we will resolve this for you.
    </NotificationMessage>
  );
};

export default AgencyKeyError;
