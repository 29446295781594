import React from "react";

import { TabPane, Row, Form, Col, Spinner } from "reactstrap";
import AppPageLoader from "../common-components/AppPageLoader";

const UpdateAccount = ({
  handelAccountSettingSubmit,
  updatedUserInformation,
  handelAccountSettingChangeEvent,
  userInformationError,
  resetInformation,
  btnLoader,
  loaderForProfile,
}) => {
  return (
    <TabPane tabId="1">
      <Form className="account-tab position-relative" onSubmit={handelAccountSettingSubmit}>
        <div className="step-block">
          <Row>
            <Col md={12} xl={12}>
              <div className="form-group app-ctrl">
                <label className="required">User Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="User Name*"
                  name="UserName"
                  maxLength="100"
                  value={updatedUserInformation.UserName}
                  onChange={handelAccountSettingChangeEvent}
                />
                <div className="err-message">{userInformationError.UserName}</div>
              </div>
            </Col>
            <Col md={12} xl={6}>
              <div className="form-group app-ctrl">
                <label className="required">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name*"
                  name="FirstName"
                  value={updatedUserInformation.FirstName}
                  onChange={handelAccountSettingChangeEvent}
                />
                <div className="err-message">{userInformationError.FirstName}</div>
              </div>
            </Col>
            <Col md={12} xl={6}>
              <div className="form-group app-ctrl">
                <label className="d-none d-xl-block">&nbsp;</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name*"
                  name="LastName"
                  value={updatedUserInformation.LastName}
                  onChange={handelAccountSettingChangeEvent}
                />
                <div className="err-message">{userInformationError.LastName}</div>
              </div>
            </Col>

            <Col md={12} xl={6}>
              <div className="form-group app-ctrl">
                <label className="required">Primary Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email*"
                  name="Email"
                  maxLength="100"
                  value={updatedUserInformation.Email}
                  onChange={handelAccountSettingChangeEvent}
                />
                <div className="err-message">{userInformationError.Email}</div>
              </div>
            </Col>
            <Col md={12} xl={6}>
              <div className="form-group app-ctrl">
                <label>Secondary Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Secondary Email"
                  name="SecondaryEmail"
                  maxLength="100"
                  value={updatedUserInformation.SecondaryEmail}
                  onChange={handelAccountSettingChangeEvent}
                />
                <div className="err-message">{userInformationError.SecondaryEmail}</div>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={12}>
            <div className="btn-box d-flex ">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={resetInformation}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`btn app-btn large-btn ${btnLoader ? "btn-loading-state" : ""}`}
              >
                <div className="button-inner">
                  {btnLoader && <Spinner style={{ verticalAlign: "middle" }} />}
                  <span className="btn-text">Update</span>
                </div>
              </button>
            </div>
          </Col>
        </Row>
        {loaderForProfile && <AppPageLoader />}
      </Form>
    </TabPane>
  );
};

export default UpdateAccount;
