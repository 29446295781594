import axios from "axios";
import { API_URL, HEADER } from "../config/appConstatnts";

class Api {
  static getIntegration = () => {
    return axios.get(API_URL + "/Integration/Get", HEADER);
  };

  static getTimeZone = () => {
    return axios.get(API_URL + "/Address/GetTimeZone", HEADER);
  };

  // crmsync count
  static GetCRMSyncCount = () => {
    return axios.get(API_URL + "/Integration/GetCRMSyncCount", HEADER);
  };
  // crmmappingexcel
  static getCRMMappingExcel = (data) => {
    return axios.post(API_URL + "/Integration/GetCRMMappingExcel", data, HEADER);
  };

  static getIntegrationLocations = (data) => {
    return axios.post(API_URL + "/Integration/Locations", data, HEADER);
  };

  // get sync do-not-sync data
  static getIntegrationList = () => {
    return axios.get(API_URL + "/Integrationlist", HEADER);
  };

  static locationDisable = (id) => {
    return axios.get(API_URL + "/Settings/DisableConnector?id=" + id, HEADER);
  };

  static locationEnable = (id) => {
    return axios.get(API_URL + "/Settings/EnableConnector?id=" + id, HEADER);
  };

  static getIntegrationKey = () => {
    return axios.get(API_URL + "/GetPMSIntegrationKey", HEADER);
  };

  static saveIntegrationKey = (data) => {
    return axios.post(API_URL + "/Integration/SaveIntegrations", data, HEADER);
  };

  static sendEmail = (data) => {
    return axios.post(API_URL + "/Integration/SendEmail", data, HEADER);
  };

  static getAgencyAccountDetails = () => {
    return axios.get(API_URL + "/Integration/GetGoHighLevelAgencyDetails", HEADER);
  };
  static getIntegrationDataIntoExcel = (data) => {
    return axios.post(API_URL + "/Integration/GetIntegrationsExcel", data, HEADER);
  };
  static addUpdateAgencyKey = (data) => {
    return axios.post(API_URL + "/Integration/AddUpdateGoHighLevelAgencyKey", data, HEADER);
  };

  static enableDisableTypes = (isEnable, type) => {
    return axios.post(
      API_URL + "/Settings/GoHighLevelEnableDisable?isEnable=" + isEnable + "&type=" + type,
      {},
      HEADER
    );
  };

  static UpdateGoHighLevelKey = (data) => {
    return axios.post(API_URL + "/Settings/UpdateGoHighLevelKey", data, HEADER);
  };

  static GoHighLevelLocationEnableDisable = (isEnable, id, locationId) => {
    return axios.post(
      API_URL +
        "/Settings/GoHighLevelLocationEnableDisable?IsEnable=" +
        isEnable +
        "&Id=" +
        id +
        "&LocationId=" +
        locationId,
      {},
      HEADER
    );
  };

  static addUpdateConfigSetting = (data) => {
    return axios.post(API_URL + "/DentrixAscend/ConfigDentrixAscend", data, HEADER);
  };

  static GetConfigSetting = (accountId) => {
    return axios.get(API_URL + "/DentrixAscend/GetConfigSetting?accountId=" + accountId, HEADER);
  };

  static EnableDisableConfigSetting = (id, isEnable) => {
    return axios.post(API_URL + "/DentrixAscend/EnableDisableConfig?id=" + id + "&enable=" + isEnable,{}, HEADER);
  };

}

export default Api;
