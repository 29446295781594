function checkInputHasValue (event) {
    let parentEle = event.target.parentElement;
    if (event.target.value.length > 0) {
      addClass(parentEle, "has-value");
    }
    else{
      removeClass(parentEle, "has-value");
    }
  };

  function addClass(element, eleClass) {
    let getElement = element, getClass = eleClass, arr;
    arr = getElement.className.split(" ");
    if (arr.indexOf(getClass) == -1) {
      getElement.className += " " + getClass;
    }
  }

  function removeClass(element,eleClass) {
    element.classList.remove(eleClass);
  }

  export {checkInputHasValue , addClass, removeClass};