import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

//component import
import Api from "../API/profile";
import { toast } from "react-toastify";
import { validCharRegex, validEmailRegex } from "../config/appRegex";
import { validateForm } from "../config/commonFun";
import Select from "react-select";

export default function EditUserProfileModel(props) {
  const [loader, setLoader] = useState(false);

  const [userData, setUserData] = useState({
    Id: 0,
    Salutation: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Image: "",
    Email: "",
    Title: "",
    WebSiteURL: "",
    Gender: "",
    Description: "",
    NpiNumber: "",
    Username: "",
    SpecialtyId: [],
    PracticeName: "",
  });

  const [errors, setErrors] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Username: "",
    Specialty: "",
    PracticeName: "",
  });

  const [selectedSpecialty, setSelectedSpecialty] = useState({
    Id: 0,
    Name: "",
  });

  const [specialty, setSpecialty] = useState([
    {
      Id: 0,
      Description: "",
    },
  ]);

  const [option, setOption] = useState({
    value: 0,
    label: "",
  });

  const [selectedOption, setSelectedOption] = useState({
    value: 0,
    label: "",
  });

  const getSpecialities = () => {
    Api.getSpecialities()
      .then((res) => {
        setSpecialty(res.data.Result);
      })
      .catch((error) => {});
  };

  const getSelectedSpecialty = (id) => {
    if (id > 0) {
      Api.getSelectedSpecialty(id)
        .then((res) => {
          setSelectedSpecialty(res.data.Result);
          setUserData((userData) => ({
            ...userData,
            SpecialtyId: res.data.Result.map((a) => a.Id),
          }));
        })
        .catch((error) => {});
    }
  };

  const getProfileDetails = (id) => {
    if (id > 0) {
      // SetLoaderForProfile(true);
      Api.getProfileDetails(id)
        .then((res) => {
          setUserData(res.data.Result);
        })
        .catch((error) => {});
    }
  };

  // set user obj
  useEffect(() => {
    getProfileDetails(props.UserId);
    getSelectedSpecialty(props.UserId);
    getSpecialities();
  }, [props.UserId]);

  useEffect(() => {
    let ops = [];
    for (let i = 0; i < specialty.length; i++) {
      let obj = { value: 0, label: "" };
      obj.value = specialty[i].Id;
      obj.label = specialty[i].Description;
      ops.push(obj);
    }
    setOption(ops);
  }, [specialty.length]);

  useEffect(() => {
    let selOps = [];
    for (let i = 0; i < selectedSpecialty.length; i++) {
      let obj = { value: 0, label: "" };
      obj.value = selectedSpecialty[i].Id;
      obj.label = selectedSpecialty[i].Name;
      selOps.push(obj);
    }
    setSelectedOption(selOps);
  }, [selectedSpecialty.length]);

  const onSpeSelect = (obj, newObj) => {
    setSelectedOption(obj);
    setErrors((errors) => ({
      ...errors,
      Specialty: "",
    }));

    setUserData((userData) => ({
      ...userData,
      SpecialtyId: obj.map((a) => a.value),
    }));
  };

  //form control change event
  const handelChange = (event) => {
    event.persist();
    let { name, value } = event.target;
    let errorValue = "";
    setUserData((userData) => ({
      ...userData,
      [name]: value,
    }));
    switch (name) {
      case "FirstName":
        if (value.length === 0) {
          errorValue = "First Name is required.";
        } else {
          errorValue = validCharRegex.test(value) ? "" : "Enter only characters.";
        }
        break;
      case "LastName":
        if (value.length === 0) {
          errorValue = "Last Name is required.";
        } else {
          errorValue = validCharRegex.test(value) ? "" : "Enter only characters.";
        }
        break;
      case "Email":
        if (value.length === 0) {
          errorValue = "Email is required.";
        } else {
          errorValue = validEmailRegex.test(value) ? "" : "Enter valid email.";
        }
        break;
      case "Username":
        if (value.length === 0) {
          errorValue = "Username is required.";
        }
        break;
      case "PracticeName":
        if (value.length === 0) {
          errorValue = "Practice Name is required.";
        }
        break;
      default:
        break;
    }
    setErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelSubmit = (event) => {
    let flagError = false;
    if (userData.FirstName.length === 0) {
      setErrors((errors) => ({
        ...errors,
        FirstName: "First Name is required.",
      }));
      flagError = true;
    }
    if (userData.LastName.length === 0) {
      setErrors((errors) => ({
        ...errors,
        LastName: "Last Name is required.",
      }));
      flagError = true;
    }

    if (selectedOption.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Specialty: "Specialty is required.",
      }));
      flagError = true;
    } else {
      setUserData((userData) => ({
        ...userData,
        SpecialtyId: selectedOption.map((a) => a.value),
      }));
    }
    if (userData.Email.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Email: "Email is required.",
      }));
      flagError = true;
    }
    if (userData.Username.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Username: "Username is required.",
      }));
      flagError = true;
    }

    if (userData.PracticeName.length === 0) {
      setErrors((errors) => ({
        ...errors,
        PracticeName: "Practic eName is required.",
      }));
      flagError = true;
    }
    event.preventDefault();
    if (validateForm(errors) && !flagError) {
      setLoader(true);
      Api.postUserData(userData)
        .then((res) => {
          let user = JSON.parse(localStorage.getItem("userData"));
          user.FirstName = userData.FirstName;
          user.LastName = userData.LastName;
          user.SpecialtyId = userData.SpecialtyId;
          user.Username = userData.Username;
          user.Email = userData.Email;
          localStorage.setItem("userData", JSON.stringify(user));
          props.reload();
          toast.success("Profile updated successfully!");
          props.onHide();
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.Message
          ) {
            toast.error(error.response.data.Message);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model location-profile-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Edit Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form onSubmit={handelSubmit}>
            <div className="row ">
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">First Name </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="John"
                    name="FirstName"
                    value={userData.FirstName}
                    onChange={handelChange}
                  />
                  {errors.FirstName.length > 0 && (
                    <div className="err-message">{errors.FirstName}</div>
                  )}
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Doe"
                    name="LastName"
                    value={userData.LastName}
                    onChange={handelChange}
                  />
                  {errors.LastName.length > 0 && (
                    <div className="err-message">{errors.LastName}</div>
                  )}
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Johndoe@gmail.com"
                    maxLength="100"
                    value={userData.Email}
                    onChange={handelChange}
                  />
                  {errors.Email.length > 0 && <div className="err-message">{errors.Email}</div>}
                </div>
              </div>

              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <div className="app-custom-select">
                    <label class="required">Specialty</label>
                    <Select
                      options={option}
                      placeholder="Select Dental Specialty"
                      isMulti
                      onChange={onSpeSelect}
                      value={selectedOption}
                      closeMenuOnSelect={false}
                    />
                  </div>

                  {errors.Specialty.length > 0 && (
                    <div className="err-message">{errors.Specialty}</div>
                  )}
                </div>
              </div>
              <div className=" col-sm-6 ">
                <div class="form-group app-ctrl has-value">
                  <label class="required">Practice Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="Star Dental Clinic"
                    maxLength="100"
                    name="PracticeName"
                    value={userData.PracticeName}
                    onChange={handelChange}
                  />
                </div>
                {errors.PracticeName.length > 0 && (
                  <div className="err-message">{errors.PracticeName}</div>
                )}
              </div>
            </div>
            <div className="btn-box d-flex justify-content-center">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button
                type="submit"
                className={`btn app-btn large-btn ${loader ? "btn-loading-state" : ""}`}
              >
                <div className="button-inner">
                  <span className="btn-text">Update</span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
