import { Row, Col } from "reactstrap";

const LayoutRow = ({ children }) => {
  return (
    <Row>
      <Col sm="12">
        <div className="grid-section">
          <div className="white-container p-0">{children}</div>
        </div>
      </Col>
    </Row>
  );
};

export default LayoutRow;
