import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TextareaCounter from "react-textarea-counter";

import Api from "../API/profile";
//component import
import { toast } from "react-toastify";
import AppPageLoader from "../common-components/AppPageLoader";

export default function AddProfessionalMembershipModal(props) {
  const [loader, setLoader] = useState(false);

  const [membership, setMembership] = useState("");

  const [profeMembership, setProfeMembership] = useState({
    Id: 0,
    Membership: "",
  });

  const [error, setError] = useState("");

  const handleChange = (event) => {
    setMembership(event.target.value);
    setProfeMembership((proMeb) => ({
      ...proMeb,
      Membership: event.target.value,
    }));
    setError(event.target.value.length === 0 ? "Membership is required!" : "");
    setError(event.target.value.length > 100 ? "Membership allows maximum 100 characters." : "");
  };
  useEffect(() => {
    setProfeMembership(props.profeMembership);
  }, [props.profeMembership.Id]);

  const handelSubmit = (event) => {
    setError("");
    event.preventDefault();
    const obj = {
      UserId: props.UserId,
      Membership: membership,
      IsDelete: false,
      MembershipId: profeMembership.Id,
    };
    if (obj.Membership.length === 0) {
      setError("Membership is required!");
    }
    if (obj.Membership.length > 100) {
      setError("Membership allows maximum 100 characters.");
    }
    if (obj.UserId > 0 && obj.Membership.length > 0 && obj.Membership.length <= 100) {
      setLoader(true);
      Api.postMembership(obj)
        .then((res) => {
          setLoader(false);
          props.onHide();
          toast.success(
            "Professional Membership" +
              (obj.MembershipId > 0 ? "updated" : "Insert") +
              "successfully"
          );
          props.reload();
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.data.StatusCode === 400) {
            toast.warn(error.response.data.Result);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Add Professional Memberships
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          {loader && <AppPageLoader className={"position-fixed"} />}
          <form onSubmit={handelSubmit}>
            <div className="row">
              <div className="col-sm-12">
                <div class="form-group app-ctrl ">
                  <label class="required">
                    After saving this association, click add button to enter new association{" "}
                  </label>
                  <div className="app-textcounter">
                    <TextareaCounter
                      countLimit={100}
                      showCount={true}
                      rows={4}
                      value={profeMembership.Membership}
                      onChange={handleChange}
                    />
                  </div>
                  {error.length > 0 && <span className="error">{error}</span>}
                </div>
              </div>
            </div>
            <p className="text-danger note-text mb-2">
              Type your own association if it is not listed in auto complete. e.g.: American
              Geriatric Society.
            </p>
            <div className="btn-box d-flex">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn large-btn ">
                <div className="button-inner">
                  <span className="btn-text">Save</span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
