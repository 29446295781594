import React from "react";
import ListGroup from "react-bootstrap/ListGroup";

const ProcedureList = ({ SetAddProcedureDetailModel, userInfo }) => {
  return (
    <div className="col-xl-6 col-lg-12">
      <div className="edu-section">
        <div className="sec-header mb-0">
          <h4 className="mr-auto  ">Procedure List</h4>
          <button
            className="app-btn app-btn-clear sec-action-btn "
            onClick={() => SetAddProcedureDetailModel(true)}
          >
            {userInfo.lstProcedure && userInfo.lstProcedure.length > 0 ? (
              <>
                {" "}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17">
                  <path
                    fill="#707070"
                    d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                  />
                </svg>{" "}
                Edit
              </>
            ) : (
              <>
                <i className="fa fa-plus"></i> Add
              </>
            )}
          </button>
        </div>
        <div className="user-basic-info pt-3">
          <div className="basic-info-scroll-wrapper">
            <div className="info-list-outer-multicol">
              <ListGroup className="info-list with-3-col">
                {userInfo.lstProcedure &&
                  userInfo.lstProcedure.length > 0 &&
                  userInfo.lstProcedure.map((proc, index) => (
                    <ListGroup.Item key={`lstProcedure-${index}`}>
                      <p className="mb-0">{proc.ProcedureName}</p>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcedureList;
