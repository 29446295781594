import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { validEmailRegex } from "../config/appRegex";

import Api from "../API/login";

import { Spinner } from "reactstrap";

import Icon4 from "../assets/images/icon-1.png";
import { toast } from "react-toastify";

export default function ForgotPasswordPage() {
  // Email field
  const [email, setEmail] = useState("");
  // Email error
  const [emailError, setEmailError] = useState("");
  // Response message
  const [rMessage, setRMessage] = useState("");
  // Loader
  const [loader, setLoader] = useState(false);

  let history = useHistory();

  // Form element chnage event handler
  const handelChange = (event) => {
    event.persist();

    let { value } = event.target;
    value = value.trim();

    setEmail(value);

    if (value.length === 0) {
      setEmailError("Email is required.");
    } else {
      setEmailError(validEmailRegex.test(value) ? "" : "Enter valid email.");
    }
  };

  const redirectToSignin = () => {
    history.push("/signin");
  };
  // On Form submit
  const handelSubmit = (event) => {
    event.preventDefault();
    setRMessage("");
    // Validate email
    if (email.length === 0) {
      setEmailError("Email is required.");
    } else if (!validEmailRegex.test(email)) {
      setEmailError("Enter valid email.");
    } else {
      setLoader(true);
      Api.forgotPassword(email)
        .then((res) => {
          setLoader(false);
          setRMessage(res.data.Message);
          toast.success(res.data.Message);
          setTimeout(() => redirectToSignin(), 5000);
        })
        .catch((error) => {
          setLoader(false);
          setEmailError(error.response.data.Message);
        });
    }
  };

  return (
    <section className="common-section vh-100">
      <div className="container-fluid h-100">
        <div className="main-block align-items-center justify-content-center h-100 d-flex">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>Forgot Password</h1>
                      <p className="mb-2"> You will get reset password link on your e-mail.</p>
                    </div>
                    <div className="text-success">{rMessage}</div>
                    <form onSubmit={handelSubmit}>
                      <div className="content">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="email"
                            name="email"
                            placeholder="Email*"
                            maxLength="100"
                            value={email}
                            onChange={handelChange}
                          />
                          <span className="input-icon1">
                            <img src={Icon4} alt="views" className="img-fluid" />
                          </span>
                        </div>
                        <div className="err-message">{emailError}</div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button type="submit" className="btn btn-outline-primary blue-btn-bg ">
                            {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                            Request Password Reset
                          </button>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account ?
                            <button className="h5 f-600 pl-2" onClick={redirectToSignin}>
                              Sign in
                            </button>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  <h1>Welcome</h1>
                  <p>To keep connected with us please login with your personal info</p>
                  <div className="btn-block">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={redirectToSignin}
                    >
                      sign In
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
