import { useState, useEffect } from "react";
import Api from "../API/download-file";
import AppPageLoader from "../common-components/AppPageLoader";
import { localStorageUserData, RecordLincURL } from "../config/appConstatnts";
import Pagination from "../common-components/Pagination";
import Layout from "./Layout";
import { defaultFromDate, defaultToDate, getDifferenceInDays } from "../config/commonFun";
import NoDataTable from "./NoDataTable";
import DownloadBtn from "./DownloadBtn";
import SearchData from "./SearchData";
import LayoutRow from "./LayoutRow";
import GridTable from "./GridTable";

const DownloadFiles = () => {
  const itemsPerPage = 100;

  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(0);
  const [showPageNo, setShowPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableLocationDetails, setTableLocationDetails] = useState({
    Location: [],
    Tables: [],
  });
  const [sendSearchData, setSendSearchData] = useState({
    fromDate: defaultFromDate(),
    toDate: defaultToDate(),
    Table: "",
    Location: "",
  });
  const [sendSearchDataError, setSendSearchDataError] = useState({ commonError: "" });
  const [showTableData, setShowTableData] = useState([]);

  const downloadExcelFile = () => {
    let error = false;
    const data = {
      FromDate: sendSearchData.fromDate,
      ToDate: sendSearchData.toDate,
      LocationId:
        Number(sendSearchData.Location) === 0
          ? Number(tableLocationDetails.Location[0].LocationId)
          : Number(sendSearchData.Location),
      TableId:
        Number(sendSearchData.Table) === 0
          ? tableLocationDetails.Tables[0].Key
          : Number(sendSearchData.Table),
      AccountId: localStorageUserData.AccountId,
    };
    setSendSearchDataError({ commonError: "" });
    if (
      getDifferenceInDays(new Date(sendSearchData.fromDate), new Date(sendSearchData.toDate)) > 365
    ) {
      error = true;
      setSendSearchDataError({ commonError: "Date range should not be greater than a year" });
    }
    if (error === false) {
      setLoader(true);
      Api.downloadDentrixDetails(data)
        .then((resp) => {
          if (resp.data.StatusCode === 200) {
            window.location.href = RecordLincURL + "IntegrationExcelFiles\\" + resp.data.Result;
            setSendSearchDataError({ commonError: "" });
          } else {
            sendSearchDataError.commonError = "Please select appropriate field to download";
            setSendSearchDataError(sendSearchDataError);
          }
          setLoader(false);
        })
        .catch((err) => {
          sendSearchDataError.commonError = "Error Occured while downloading excel file";
          setSendSearchDataError(sendSearchDataError);
          setLoader(false);
        });
    }
  };

  const getTableLocationApi = () => {
    Api.getTableAndLocationDetails(localStorageUserData.UserId)
      .then((res) => {
        setTableLocationDetails({
          ...tableLocationDetails,
          Location: res.data.Result.Location,
          Tables: res.data.Result.Tables,
        });
        setLoader(false);
      })
      .catch((err) => {
        setTableLocationDetails({
          ...tableLocationDetails,
          Location: [],
          Tables: [],
        });
        setLoader(false);
      });
  };

  const handleChange = (event) => {
    setSendSearchData({ ...sendSearchData, [event.target.name]: event.target.value });
  };

  const getDentrixDetailsList = (pageIndex, pageSize = itemsPerPage) => {
    setShowPageNo(pageIndex);
    const data = {
      FromDate: sendSearchData.fromDate,
      ToDate: sendSearchData.toDate,
      LocationId:
        Number(sendSearchData.Location) === 0
          ? Number(tableLocationDetails.Location[0].LocationId)
          : Number(sendSearchData.Location),
      TableId:
        Number(sendSearchData.Table) === 0
          ? tableLocationDetails.Tables[0].Key
          : Number(sendSearchData.Table),
      PageIndex: pageIndex,
      PageSize: pageSize,
      AccountId: localStorageUserData.AccountId,
    };
    setLoader(true);
    Api.getDentrixDetails(data)
      .then((res) => {
        if (res.data.StatusCode === 200) {
          const resultData = res.data.Result;
          setShowPageNo(resultData.DentrixDetail === null ? 0 : 1);
          setShowTableData(resultData.DentrixDetail === null ? [] : resultData.DentrixDetail);
          setTotalRecords(resultData.TotalRecords);
          setPages(
            resultData.TotalRecords > 0 ? Math.ceil(resultData.TotalRecords / itemsPerPage) : 0
          );
        } else {
          sendSearchDataError.commonError = `${res.data.Message}`;
          setSendSearchDataError(sendSearchDataError);
          setTotalRecords(0);
          setShowTableData([]);
          setPages(0);
        }
        setLoader(false);
      })
      .catch((err) => {
        sendSearchDataError.commonError = `${err?.response?.data?.Message ?? "Something went wrong"}`;
        setSendSearchDataError(sendSearchDataError);
        setShowTableData([]);
        setPages(0);
        setTotalRecords(0);
        setLoader(false);
      });
  };

  const onSubmitSearch = () => {
    let error = false;
    if (
      getDifferenceInDays(new Date(sendSearchData.fromDate), new Date(sendSearchData.toDate)) > 365
    ) {
      error = true;
      sendSearchDataError.commonError = "Date range should not be greater than a year";
    }
    if (error === false) {
      sendSearchDataError.commonError = "";
      getDentrixDetailsList(1, itemsPerPage);
    }
    setSendSearchDataError({ ...sendSearchDataError });
  };

  useEffect(() => {
    getTableLocationApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {loader && <AppPageLoader />}
      <LayoutRow>
        {sendSearchDataError.commonError !== "" && (
          <div className="text-danger" style={{ fontSize: "14px" }}>
            <b>{sendSearchDataError.commonError}</b>
          </div>
        )}
        <SearchData
          sendSearchData={sendSearchData}
          sendSearchDataError={sendSearchDataError}
          tableLocationDetails={tableLocationDetails}
          handleChange={handleChange}
          onSubmitSearch={onSubmitSearch}
        />
        {pages !== 0 && <DownloadBtn downloadExcelFile={downloadExcelFile} />}
        {showTableData && showTableData.length > 0 && <GridTable tableDataObject={showTableData} />}
        {showPageNo === 0 && <NoDataTable colSpan={8} text="No record found!" />}
        {pages !== 0 && (
          <Pagination
            totalRecords={totalRecords}
            pages={pages}
            onPageClickFunction={getDentrixDetailsList}
            itemsPerPage={100}
          />
        )}
      </LayoutRow>
    </Layout>
  );
};

export default DownloadFiles;
