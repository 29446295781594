import axios from "axios";
import { API_URL, HEADER } from "../config/appConstatnts";
// main api class
class Api {

  // Api for save User Data.
  static getTeamMembers = (model) => {
    return axios.post(API_URL + "/Settings/GetTeamMember", model, HEADER);
  };

  static addEditTeamMemer = (model) => {
    return axios.post(API_URL + "/Settings/AddEditTeamMemer", model, HEADER);
  };

  static removeTeamMemer = (model) => {
    return axios.post(API_URL + "/Settings/RemoveTeamMember", model, HEADER);
  };

  static getAddress = (userId) => {
    return axios.get(API_URL + "/Address/Get/" + userId, HEADER);
  }

  static getTeamMemberDetails = (userId) => {
    return axios.get(API_URL + "/Settings/GetTeamMemberDetails?id=" + userId, HEADER);
  }

  static getSpecialities = () => {
    return axios.get(
      API_URL + "/Verident/GetSpecialities?IsSignUP=false",
      HEADER
    )
  }

  static getSelectedSpecialty = (userId) => {
    return axios.get(API_URL + "/Specialty/Get/" + userId, HEADER);
}


}
export default Api;
