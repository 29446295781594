import React, { useState, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import Avtar from "../assets/images/Doctor_no_image.gif";
import { localStorageUserData } from "../config/appConstatnts";
//images import
import { goToSignin } from "../config/commonFun";

const AppSidebarNavbar = (props) => {
  const userImageUrl = localStorageUserData.ImageUrl;

  const [dropdownOpenUser, setOpenUser] = useState(false);
  const toggleUser = () => setOpenUser(!dropdownOpenUser);

  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("userData"));
    if (userInfo != null && userInfo.UserId && userInfo.AccountId > 0) {
      setUser(userInfo);
    }
  }, [userImageUrl]);

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.removeItem("sectionList");
    // localStorage.removeItem("SessionTimeout");
    setTimeout(() => {
      goToSignin();
    }, 2000);
  };

  return (
    <>
      <Navbar collapseOnSelect expand="md" variant="dark" className="navbar fixed-top">
        <div className="nav-item">
          <Link to="/setting/integration" className="sidebar-logo">
            {" "}
            <div className="logo-block"></div>
          </Link>
        </div>

        <ul className="navbar-nav  align-items-center ml-auto">
          <li className="nav-item"></li>
          <li className="nav-item dropdown user-info">
            <ButtonDropdown isOpen={dropdownOpenUser} toggle={toggleUser}>
              <DropdownToggle className="nav-link dropdown-toggle">
                <img
                  src={encodeURI(user.ImageUrl.length > 0 ? user.ImageUrl : Avtar)}
                  width={38}
                  height={38}
                  className="user-img"
                  alt="Use Thumb"
                />
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: (data) => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          right: "-30px",
                          left: "auto",
                          top: "10px",
                        },
                      };
                    },
                  },
                }}
              >
                <li>
                  <NavLink to={"/#"} onClick={logOut} className="dropdown-item border-bottom">
                    <div className="icon-wrapper">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 15">
                        <path
                          fill="#888"
                          d="M10.549 11.455v1.169a2.34 2.34 0 01-2.338 2.337H2.338A2.34 2.34 0 010 12.624V2.338A2.34 2.34 0 012.338 0H8.21a2.34 2.34 0 012.338 2.338v1.169a.584.584 0 11-1.169 0v-1.17A1.17 1.17 0 008.211 1.17H2.338a1.17 1.17 0 00-1.17 1.169v10.286a1.17 1.17 0 001.17 1.169H8.21a1.17 1.17 0 001.17-1.17v-1.168a.584.584 0 111.168 0zm3.985-4.978l-1.31-1.309a.584.584 0 10-.826.827l.931.93H6.312a.584.584 0 100 1.17h7.017l-.93.93a.584.584 0 10.826.827l1.309-1.309c.57-.57.57-1.496 0-2.066z"
                        />
                      </svg>
                    </div>
                    Logout
                  </NavLink>
                </li>
              </DropdownMenu>
            </ButtonDropdown>
          </li>
        </ul>
      </Navbar>
    </>
  );
};
export default AppSidebarNavbar;
