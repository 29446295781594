import React from "react";

const AppointmentReSyncType = ({ IsAppointment, onChangeReSyncType }) => {
  return (
    <div className={"d-flex mb-12 mt-3 mb-3"}>
      <div
        className="mx-2 custom-control custom-checkbox app-checkbox primaryLocation-chk"
        style={{
          fontSize: "16px",
          lineHeight: "20px",
          wordSpacing: ".50px",
          textAlign: "justify",
        }}
      >
        <input
          type="radio"
          id="appointmentSyncType"
          name="processName"
          // value={true}
          className="custom-control-input"
          checked={IsAppointment}
          onChange={() => onChangeReSyncType(true)}
        />
        <label
          className="custom-control-label my-2"
          htmlFor="appointmentSyncType"
          style={{ fontWeight: "normal" }}
        >
          <span style={{ fontWeight: "bold" }}>Only Appointments</span>{" "}
        </label>
      </div>
      <div
        className="mx-2 custom-control custom-checkbox app-checkbox primaryLocation-chk"
        style={{
          fontSize: "16px",
          lineHeight: "20px",
          wordSpacing: ".50px",
          textAlign: "justify",
        }}
      >
        <input
          type="radio"
          id="appointmentOperatorySyncType"
          name="processName"
          // value={false}
          className="custom-control-input"
          onChange={() => onChangeReSyncType(false)}
          checked={!IsAppointment}
        />
        <label
          className="custom-control-label my-2"
          htmlFor="appointmentOperatorySyncType"
          style={{ fontWeight: "normal" }}
        >
          <span style={{ fontWeight: "bold" }}>Appointments with Operatory</span>{" "}
        </label>
      </div>
    </div>
  );
};

export default React.memo(AppointmentReSyncType);
