import React from "react";
import ReactDOM from 'react-dom';
import { Modal, Button } from "react-bootstrap";
//component import
import { Form } from 'react-bootstrap';
import { FormGroup, Label, Input, FormText } from 'reactstrap';
export default function AddInsuranceDetailModal(props) {
    const [LocationAisActive, SetLocationAState] = React.useState(false);
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Add/Edit Insurance Detail
        </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row insurance-detail-sec mb-3">
                <div className=" col-6 chk-col">
                  <div className="custom-control custom-checkbox app-checkbox">
                      <input type="checkbox" className="custom-control-input" id="chk1" />
                      <label className="custom-control-label" htmlFor="chk1">AETNA</label>
                  </div>
              </div>
              <div className=" col-6 chk-col">
                  <div className="custom-control custom-checkbox app-checkbox">
                      <input type="checkbox" className="custom-control-input" id="chk2" />
                      <label className="custom-control-label" htmlFor="chk2">GEHA</label>
                  </div>
              </div>
              <div className=" col-6 chk-col">
                  <div className="custom-control custom-checkbox app-checkbox">
                      <input type="checkbox" className="custom-control-input" id="chk3" />
                      <label className="custom-control-label" htmlFor="chk3">AMERITAS</label>
                  </div>
              </div>
              <div className=" col-6 chk-col">
                  <div className="custom-control custom-checkbox app-checkbox">
                      <input type="checkbox" className="custom-control-input" id="chk4" />
                      <label className="custom-control-label" htmlFor="chk4">GUARDAIAN</label>
                  </div>
              </div>
              <div className=" col-6 chk-col">
                  <div className="custom-control custom-checkbox app-checkbox">
                      <input type="checkbox" className="custom-control-input" id="chk5" />
                      <label className="custom-control-label" htmlFor="chk5">ASSURANT/SUNLIFE</label>
                  </div>
              </div>
              <div className=" col-6 chk-col">
                  <div className="custom-control custom-checkbox app-checkbox">
                      <input type="checkbox" className="custom-control-input" id="chk6" />
                      <label className="custom-control-label" htmlFor="chk6">MET LIFE</label>
                  </div>
              </div>
              <div className=" col-6 chk-col">
                  <div className="custom-control custom-checkbox app-checkbox">
                      <input type="checkbox" className="custom-control-input" id="chk7" />
                      <label className="custom-control-label" htmlFor="chk7">BCBS</label>
                  </div>
              </div>
              <div className=" col-6 chk-col">
                  <div className="custom-control custom-checkbox app-checkbox">
                      <input type="checkbox" className="custom-control-input" id="chk8" />
                      <label className="custom-control-label" htmlFor="chk8">PRINCIPAL</label>
                  </div>
              </div>
              <div className=" col-6 chk-col">
                  <div className="custom-control custom-checkbox app-checkbox">
                      <input type="checkbox" className="custom-control-input" id="chk9" />
                      <label className="custom-control-label" htmlFor="chk9">BCBS 104/105</label>
                  </div>
              </div>
              <div className=" col-6 chk-col">
                  <div className="custom-control custom-checkbox app-checkbox">
                      <input type="checkbox" className="custom-control-input" id="chk10" />
                      <label className="custom-control-label" htmlFor="chk10">UNITED CONCORDIA FEDVIP/COMMERCIAL</label>
                  </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button type="button" className="btn app-btn lightgray-btn large-btn mr-2" onClick={props.onHide}>cancel</button>
              <button type="button" className="btn app-btn large-btn ">Save</button>
            </div>

          </form>
        </Modal.Body>

      </Modal>
    </div>
  );
}


