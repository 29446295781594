import React, { useState, useEffect } from "react";
import {
    SectionWithCheckbox,
} from "../OtherCrmCommonComponente/OtherCrmCommonComponents";

const SyncDentrixEvent = ({ IsAgencyKeyError,
    credentials,
    LocationSyncId,
    IsSyncDentrixEvents,
    dentrixEventToggle,
    MappedConnectors,
    MappedDentrix}) => {

    const [IsSyncDentrixEvent, setSyncDentrixEvent] = useState(false);
    const [IsDentrix, setDentrix] = useState(false);

    useEffect(() => {
        setSyncDentrixEvent(IsSyncDentrixEvents);
        //setDentrix(MappedConnectors.filter(x => x.IntegrationId == 1).length > 0);
    }, [IsSyncDentrixEvents]);

    useEffect(() => {
        setDentrix(MappedDentrix);
    }, [MappedDentrix]);

    return (
        <>
            { IsDentrix ? <SectionWithCheckbox
                className={"mt-1"}
                title={"Sync Dentrix Event"}
                formDisable={IsAgencyKeyError}
                onChangeCheckbox={dentrixEventToggle}
                checked={IsSyncDentrixEvent}
                switchId={"custom-switch-event"}>
                <p><b>Note :</b> Sync all the appointment event into GHL. </p>

            </SectionWithCheckbox>
            : <></>}
        </>
    );

};

export default SyncDentrixEvent;