import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Api from "../API/profile";
//component import

import { validWebSiteRegex, validateForm } from "../config/appRegex";
import { toast } from "react-toastify";
import AppPageLoader from "../common-components/AppPageLoader";
export default function EditSocialMediaModel(props) {
  const [loader, setLoader] = useState(false);
  const [socialLink, setSocialLink] = useState({
    FacebookUrl: "",
    LinkedinUrl: "",
    TwitterUrl: "",
    GoogleplusUrl: "",
    YoutubeUrl: "",
    PinterestUrl: "",
    BlogUrl: "",
    InstagramUrl: "",
    YelpUrl: "",
  });

  const [errors, setErrors] = useState({
    FacebookUrl: "",
    LinkedinUrl: "",
    TwitterUrl: "",
    GoogleplusUrl: "",
    YoutubeUrl: "",
    PinterestUrl: "",
    BlogUrl: "",
    InstagramUrl: "",
    YelpUrl: "",
  });
  useEffect(() => {
    if (props.UserId && props.UserId > 0) {
      Api.getSocialMedia()
        .then((res) => {
          setSocialLink(res.data[0]);
        })
        .catch((error) => {});
    } else {
      setSocialLink({
        FacebookUrl: "",
        LinkedinUrl: "",
        TwitterUrl: "",
        GoogleplusUrl: "",
        YoutubeUrl: "",
        PinterestUrl: "",
        BlogUrl: "",
        InstagramUrl: "",
        YelpUrl: "",
      });
    }
    setErrors({
      FacebookUrl: "",
      LinkedinUrl: "",
      TwitterUrl: "",
      GoogleplusUrl: "",
      YoutubeUrl: "",
      PinterestUrl: "",
      BlogUrl: "",
      InstagramUrl: "",
      YelpUrl: "",
    });
  }, [props.UserId]);

  const handelChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    let errorValue = "";
    setSocialLink((socialLink) => ({
      ...socialLink,
      [name]: value,
    }));
    if (value.trim().length > 0) {
      errorValue = validWebSiteRegex.test(value) ? "" : "Enter valid link.";
    }
    setErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;
    if (socialLink.FacebookUrl.length > 0 && !validWebSiteRegex.test(socialLink.FacebookUrl)) {
      flagError = true;
      setErrors((errors) => ({
        ...errors,
        FacebookUrl: "Enter valid link.",
      }));
    }
    if (socialLink.LinkedinUrl.length > 0 && !validWebSiteRegex.test(socialLink.LinkedinUrl)) {
      flagError = true;
      setErrors((errors) => ({
        ...errors,
        LinkedinUrl: "Enter valid link.",
      }));
    }
    if (socialLink.TwitterUrl.length > 0 && !validWebSiteRegex.test(socialLink.TwitterUrl)) {
      flagError = true;
      setErrors((errors) => ({
        ...errors,
        TwitterUrl: "Enter valid link.",
      }));
    }
    if (socialLink.GoogleplusUrl.length > 0 && !validWebSiteRegex.test(socialLink.GoogleplusUrl)) {
      flagError = true;
      setErrors((errors) => ({
        ...errors,
        GoogleplusUrl: "Enter valid link.",
      }));
    }
    if (socialLink.YoutubeUrl.length > 0 && !validWebSiteRegex.test(socialLink.YoutubeUrl)) {
      flagError = true;
      setErrors((errors) => ({
        ...errors,
        YoutubeUrl: "Enter valid link.",
      }));
    }
    if (socialLink.PinterestUrl.length > 0 && !validWebSiteRegex.test(socialLink.PinterestUrl)) {
      flagError = true;
      setErrors((errors) => ({
        ...errors,
        PinterestUrl: "Enter valid link.",
      }));
    }
    if (socialLink.BlogUrl.length > 0 && !validWebSiteRegex.test(socialLink.BlogUrl)) {
      flagError = true;
      setErrors((errors) => ({
        ...errors,
        BlogUrl: "Enter valid link.",
      }));
    }
    if (socialLink.InstagramUrl.length > 0 && !validWebSiteRegex.test(socialLink.InstagramUrl)) {
      flagError = true;
      setErrors((errors) => ({
        ...errors,
        InstagramUrl: "Enter valid link.",
      }));
    }
    if (socialLink.YelpUrl.length > 0 && !validWebSiteRegex.test(socialLink.YelpUrl)) {
      flagError = true;
      setErrors((errors) => ({
        ...errors,
        YelpUrl: "Enter valid link.",
      }));
    }

    if (validateForm(errors) && !flagError) {
      setLoader(true);
      Api.postSocialMedia(socialLink)
        .then((res) => {
          setLoader(false);
          props.onHide();
          toast.success("SocialLink updated successfully!");
          props.reload();
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Something went wrong");
        });
    }
  };
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model location-profile-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Edit Social Media
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          {loader && <AppPageLoader className={"position-fixed"} />}
          <form onSubmit={handelSubmit}>
            <div className="row">
              <div className="col-sm-6 ">
                <div class="form-group app-ctrl ">
                  <label>Facebook </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    value={socialLink.FacebookUrl}
                    name="FacebookUrl"
                    onChange={handelChange}
                  />
                </div>
                {errors.FacebookUrl.length > 0 && (
                  <span className="err-message">{errors.FacebookUrl}</span>
                )}
              </div>
              <div className="col-sm-6 ">
                <div class="form-group app-ctrl ">
                  <label>Twitter </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    value={socialLink.TwitterUrl}
                    name="TwitterUrl"
                    onChange={handelChange}
                  />
                </div>
                {errors.TwitterUrl.length > 0 && (
                  <span className="err-message">{errors.TwitterUrl}</span>
                )}
              </div>
              <div className="col-sm-6 ">
                <div class="form-group app-ctrl ">
                  <label>Instagram </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    value={socialLink.InstagramUrl}
                    name="InstagramUrl"
                    onChange={handelChange}
                  />
                  {errors.InstagramUrl.length > 0 && (
                    <span className="err-message">{errors.InstagramUrl}</span>
                  )}
                </div>
              </div>
              <div className="col-sm-6 ">
                <div class="form-group app-ctrl ">
                  <label>Linkedin </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    value={socialLink.LinkedinUrl}
                    name="LinkedinUrl"
                    onChange={handelChange}
                  />
                </div>
                {errors.LinkedinUrl.length > 0 && (
                  <span className="err-message">{errors.LinkedinUrl}</span>
                )}
              </div>
              <div className="col-sm-6 ">
                <div class="form-group app-ctrl ">
                  <label>Google Plus </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    value={socialLink.GoogleplusUrl}
                    name="GoogleplusUrl"
                    onChange={handelChange}
                  />
                </div>
                {errors.GoogleplusUrl.length > 0 && (
                  <span className="err-message">{errors.GoogleplusUrl}</span>
                )}
              </div>
              <div className="col-sm-6 ">
                <div class="form-group app-ctrl ">
                  <label>Pinterest </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    value={socialLink.PinterestUrl}
                    name="PinterestUrl"
                    onChange={handelChange}
                  />
                </div>
                {errors.PinterestUrl.length > 0 && (
                  <span className="err-message">{errors.PinterestUrl}</span>
                )}
              </div>
              <div className="col-sm-6 ">
                <div class="form-group app-ctrl ">
                  <label>YouTube </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    value={socialLink.YoutubeUrl}
                    name="YoutubeUrl"
                    onChange={handelChange}
                  />
                </div>
                {errors.YoutubeUrl.length > 0 && (
                  <span className="err-message">{errors.YoutubeUrl}</span>
                )}
              </div>
              <div className="col-sm-6 ">
                <div class="form-group app-ctrl ">
                  <label>Blog </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    value={socialLink.BlogUrl}
                    name="BlogUrl"
                    onChange={handelChange}
                  />
                </div>
                {errors.BlogUrl.length > 0 && <span className="err-message">{errors.BlogUrl}</span>}
              </div>
              <div className="col-sm-6 ">
                <div class="form-group app-ctrl ">
                  <label>Yelp </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    value={socialLink.YelpUrl}
                    name="YelpUrl"
                    onChange={handelChange}
                  />
                </div>
                {errors.YelpUrl.length > 0 && <span className="err-message">{errors.YelpUrl}</span>}
              </div>
            </div>
            <div className="btn-box d-flex justify-content-center">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={props.onHide}
              >
                cancel
              </button>
              <button type="submit" className="btn app-btn large-btn ">
                <div className="button-inner">
                  <span className="btn-text">Update</span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
