import React from "react";
import ReactDOM from 'react-dom';
import { Modal, Button } from "react-bootstrap";
//component import
import { Form } from 'react-bootstrap';
import { FormGroup, Label, Input, FormText } from 'reactstrap';
export default function AddMembershipModal(props) {
    const [LocationAisActive, SetLocationAState] = React.useState(false);
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            Add Professional Membership
        </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
                <div className=" col-12 ">
                    <FormGroup>
                        <Label for="exampleText">After saving this association, click add button to enter new association</Label>
                        <Input type="textarea" name="text" id="exampleText" />
                    </FormGroup>
              </div>
            </div>
            <p className="highlite-txt mt-2 mb-3">Type your own association if it is not listed in auto complete: e.g: American Geriatric Society</p>
            <div className="btn-box d-flex">
              <button type="button" className="btn app-btn lightgray-btn large-btn mr-2" onClick={props.onHide}>cancel</button>
              <button type="button" className="btn app-btn large-btn ">Save</button>
            </div>

          </form>
        </Modal.Body>

      </Modal>
    </div>
  );
}


