import React from "react";

const AppointmentWritebackSection = ({ setAppointmentRightBack, appointmentRightBack }) => {
  return (
    <div className={"d-flex"}>
      <div
        className="mx-2 custom-control custom-checkbox app-checkbox primaryLocation-chk"
        style={{
          fontSize: "16px",
          lineHeight: "20px",
          wordSpacing: ".50px",
          textAlign: "justify",
        }}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id="apptWritebackCheckbox"
          checked={appointmentRightBack}
          onChange={() => {
            setAppointmentRightBack(!appointmentRightBack);
          }}
        />
        <label
          className="custom-control-label my-2"
          htmlFor="apptWritebackCheckbox"
          style={{ fontWeight: "normal" }}
        >
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Write back appointments from the Custom CRM Calendar into the practice management system
          </span>{" "}
          (only available with Opendental at this time).
        </label>
      </div>
    </div>
  );
};

export default React.memo(AppointmentWritebackSection);
