import React from "react";

import { Nav, NavItem, NavLink } from "reactstrap";

const AccountSettingsNavTab = ({ classnames, activeTab, toggle }) => {
  return (
    <Nav tabs>
      <NavItem>
        <NavLink
          className={classnames({
            active: activeTab === "1",
          })}
          onClick={() => {
            toggle("1");
          }}
        >
          Account Setting
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({
            active: activeTab === "2",
          })}
          onClick={() => {
            toggle("2");
          }}
        >
          Change Password
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default AccountSettingsNavTab;
