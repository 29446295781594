import React from "react";
import ReactDOM from 'react-dom';
import { Modal, Button } from "react-bootstrap";
//component import
import * as inputFun from '../common-components/FormInputFunctions';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
export default function AddDescriptionModal(props) {
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
           Add Description
        </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className=" col-12 ">
              {/* <p className="mb-3 mt-2">Copy and paste HTML to display widget on your site</p> */}
              <FormGroup>
                <Label for="exampleText">Description</Label>
                <Input type="textarea" name="text" id="exampleText" />
              </FormGroup>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button type="button" className="btn app-btn lightgray-btn large-btn mr-2" onClick={props.onHide}>cancel</button>
              <button type="button" className="btn app-btn large-btn ">Save</button>
            </div>

          </form>
        </Modal.Body>

      </Modal>
    </div>
  );
}


