import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
// component imports
import AppSidebarNavbar from '../common-components/AppSidebarNavbar';
import AddLocationModel from '../models/AddLocationModel';
import NewLocationEnabledModel from '../models/NewLocationEnabledModel';
import LocationKeyInfoModel from '../models/LocationKeyInfoModel';
//images import
import NoteEditIcon from '../assets/images/noteEdit-icon-black.svg';
import HIPPAlogo from '../assets/images/HIPAA-logo.png';
import TopNavBar from "../common-components/TopNavbar";
import {
    Nav,
    NavItem,
    // NavLink,
  } from "reactstrap";

export default function OpendentalLocation() {
    const [AddLocationModelShow, SetAddLocationModel] = React.useState(false);
    const [NewLocationEnabledModelShow, SetNewLocationEnabledModel] = React.useState(false);
    const [LocationKeyInfoModelShow, SetLocationKeyInfoModel] = React.useState(false);
    
    const [LocationAisActive, SetLocationAState] = React.useState(true);
    const [LocationBisActive, SetLocationBState] = React.useState(false);
    const [LocationCisActive, SetLocationCState] = React.useState(false);
    const [LocationDisActive, SetLocationDState] = React.useState(false);
    const [LocationEisActive, SetLocationEState] = React.useState(false);
    const [LocationFisActive, SetLocationFState] = React.useState(false);
    const [LocationGisActive, SetLocationGState] = React.useState(false);
    return (
        <div className="main-wrapper">
            <AppSidebarNavbar activeTabsIntegration={true} />
            <main>
                <div className="app-container">
                <TopNavBar />
                    <div className="white-container ">

                        <div className="page-info">
                            <nav aria-label="breadcrumb" className="app-breadcrumb req-mar">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link exact to="/setting/integration" >Integrations </Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Opendental</li>
                                </ol>
                            </nav>
                        </div>

                    <div className="app-content">
                        <div className="row app-box-wrapper">
                            <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                                <div className="app-box add-location-box d-flex align-items-center justify-content-center">
                                    <div className="add-location">
                                        <button className="btn add-btn-round" onClick={() => SetAddLocationModel(true)}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><path d="M22 9.8v2.4a1 1 0 01-1 1h-7.799V21a1 1 0 01-1 1h-2.4a1 1 0 01-1-1v-7.8H1a1 1 0 01-1-1V9.8a1 1 0 011-1h7.801V1a1 1 0 011-1h2.4a1 1 0 011 1v7.8H21a1 1 0 011 1z"/></svg>
</button>
                                        <span>ADD NEW LOCATION</span>
                                    </div>
                                </div>
                            </div>
                            <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                                <div className="app-box">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        className="app-switch"
                                        defaultChecked={LocationAisActive}
                                        onChange={e => {
                                            if(!LocationAisActive){
                                                SetNewLocationEnabledModel(true);
                                            }
                                            SetLocationAState(e.target.checked);
                                          }}
                                    />
                                    <h4 className="box-title">Location A</h4>
                                    <p>Last sync: <span>10/10/21 12:00:00</span></p>
                                    <div className={`key-info ${!LocationAisActive ? 'not-visible': ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon key-icon">
                                            <path  d="M.152 9.585a4.412 4.412 0 007.538 3.122 4.405 4.405 0 001.293-3.122c0-.745-.188-1.475-.544-2.125l5.286-5.281a.433.433 0 00.085-.484.309.309 0 00-.069-.099L12.558.412a.336.336 0 00-.1-.068.43.43 0 00-.483.085l-.732.732L10.19.108a.337.337 0 00-.099-.069.43.43 0 00-.484.085L8.44 1.291a.338.338 0 00-.068.1.43.43 0 00.085.483l1.037 1.037-2.8 2.8a4.408 4.408 0 00-2.125-.543A4.412 4.412 0 00.152 9.585zm2.868-1.55a2.177 2.177 0 011.55-.64c.583 0 1.134.227 1.548.64.413.414.643.965.643 1.55 0 .586-.228 1.135-.641 1.548a2.178 2.178 0 01-1.55.642 2.177 2.177 0 01-1.548-.642 2.177 2.177 0 01-.641-1.548 2.155 2.155 0 01.64-1.55z"/>
                                        </svg>
                                        <span className="lbl-text">Key</span>
                                        <button className="btn small-ico-btn copy-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon">
                                            <defs/>
                                            <g  clip-path="url(#clip0)">
                                                <path d="M2.333 3.938a2.774 2.774 0 012.77-2.771h5.328A1.6 1.6 0 008.896 0H2.187C1.302 0 .583.72.583 1.604v9.042c0 .885.72 1.604 1.604 1.604h.146V3.937z"/>
                                                <path d="M11.813 2.333H5.104c-.885 0-1.604.72-1.604 1.604v8.459C3.5 13.28 4.22 14 5.104 14h6.708c.885 0 1.605-.72 1.605-1.604V3.937c0-.885-.72-1.604-1.604-1.604zm-1.167 9.917H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.041H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875z"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                <path  d="M0 0h14v14H0z"/>
                                                </clipPath>
                                            </defs>
                                            </svg>
                                        </button>
                                        <button className="btn small-ico-btn info-btn" onClick={ () => SetLocationKeyInfoModel(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon tooltip-icon">
                                                <path d="M6.023 0C2.728 0 0 2.681 0 5.977 0 9.272 2.728 12 6.023 12 9.32 12 12 9.272 12 5.977A5.983 5.983 0 006.023 0zm.704 9.188a.704.704 0 01-1.407 0V5.624a.704.704 0 011.407 0v3.563zm-.704-5.672a.704.704 0 11.002-1.408.704.704 0 01-.002 1.408z"/>
                                            </svg>
                                        </button>
                                    </div> 
                                    <p className="location-info">
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 12 16" className="info-icon">
                                            <path  d="M6 0c3.308 0 6 2.71 6 6.043 0 4.736-5.436 9.625-5.667 9.83a.5.5 0 01-.666.001C5.436 15.668 0 10.779 0 6.044 0 2.71 2.692 0 6 0zm0 9.333A3.337 3.337 0 009.333 6 3.337 3.337 0 006 2.667 3.337 3.337 0 002.667 6 3.337 3.337 0 006 9.333z"/>
                                        </svg>
                                        2824  Pinewood Avenue, Powers, New York, 49874.
                                    </p>   
                                    <div className="btn-box">
                                        <Link exact to="/opendental/manage" className={`btn btn-block app-btn text-uppercase ${!LocationAisActive ? 'lightgray-btn  disabled': ''}`}>
                                            connect
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                                <div className="app-box">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch2"
                                        className="app-switch"
                                        defaultChecked={LocationBisActive}
                                        onChange={e => {
                                            if(!LocationBisActive){
                                                SetNewLocationEnabledModel(true);
                                            }
                                            SetLocationBState(e.target.checked);
                                          }}
                                    />
                                    <h4 className="box-title">Location B</h4>
                                    <p>Last sync: <span>10/10/21 12:00:00</span></p>
                                    <div className={`key-info ${!LocationBisActive ? 'not-visible': ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon key-icon">
                                            <path  d="M.152 9.585a4.412 4.412 0 007.538 3.122 4.405 4.405 0 001.293-3.122c0-.745-.188-1.475-.544-2.125l5.286-5.281a.433.433 0 00.085-.484.309.309 0 00-.069-.099L12.558.412a.336.336 0 00-.1-.068.43.43 0 00-.483.085l-.732.732L10.19.108a.337.337 0 00-.099-.069.43.43 0 00-.484.085L8.44 1.291a.338.338 0 00-.068.1.43.43 0 00.085.483l1.037 1.037-2.8 2.8a4.408 4.408 0 00-2.125-.543A4.412 4.412 0 00.152 9.585zm2.868-1.55a2.177 2.177 0 011.55-.64c.583 0 1.134.227 1.548.64.413.414.643.965.643 1.55 0 .586-.228 1.135-.641 1.548a2.178 2.178 0 01-1.55.642 2.177 2.177 0 01-1.548-.642 2.177 2.177 0 01-.641-1.548 2.155 2.155 0 01.64-1.55z"/>
                                        </svg>
                                        <span className="lbl-text">Key</span>
                                        <button className="btn small-ico-btn copy-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon">
                                            <defs/>
                                            <g  clip-path="url(#clip0)">
                                                <path d="M2.333 3.938a2.774 2.774 0 012.77-2.771h5.328A1.6 1.6 0 008.896 0H2.187C1.302 0 .583.72.583 1.604v9.042c0 .885.72 1.604 1.604 1.604h.146V3.937z"/>
                                                <path d="M11.813 2.333H5.104c-.885 0-1.604.72-1.604 1.604v8.459C3.5 13.28 4.22 14 5.104 14h6.708c.885 0 1.605-.72 1.605-1.604V3.937c0-.885-.72-1.604-1.604-1.604zm-1.167 9.917H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.041H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875z"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                <path  d="M0 0h14v14H0z"/>
                                                </clipPath>
                                            </defs>
                                            </svg>
                                        </button>
                                        <button className="btn small-ico-btn info-btn" onClick={ () => SetLocationKeyInfoModel(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon tooltip-icon">
                                                <path d="M6.023 0C2.728 0 0 2.681 0 5.977 0 9.272 2.728 12 6.023 12 9.32 12 12 9.272 12 5.977A5.983 5.983 0 006.023 0zm.704 9.188a.704.704 0 01-1.407 0V5.624a.704.704 0 011.407 0v3.563zm-.704-5.672a.704.704 0 11.002-1.408.704.704 0 01-.002 1.408z"/>
                                            </svg>
                                        </button>
                                    </div> 
                                    <p className="location-info">
                                        <svg xmlns="http:// www.w3.org/2000/svg"  viewBox="0 0 12 16" className="info-icon">
                                            <path  d="M6 0c3.308 0 6 2.71 6 6.043 0 4.736-5.436 9.625-5.667 9.83a.5.5 0 01-.666.001C5.436 15.668 0 10.779 0 6.044 0 2.71 2.692 0 6 0zm0 9.333A3.337 3.337 0 009.333 6 3.337 3.337 0 006 2.667 3.337 3.337 0 002.667 6 3.337 3.337 0 006 9.333z"/>
                                        </svg>
                                        2824  Pinewood Avenue, Powers, New York, 49874.
                                    </p>   
                                    <div className="btn-box">
                                        <Link exact to="/opendental/manage" className={`btn btn-block app-btn text-uppercase ${!LocationBisActive ? 'lightgray-btn  disabled': ''}`}>
                                            connect
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                                <div className="app-box">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch3"
                                        className="app-switch"
                                        defaultChecked={LocationCisActive}
                                        onChange={e => {
                                            if(!LocationCisActive){
                                                SetNewLocationEnabledModel(true);
                                            }
                                            SetLocationCState(e.target.checked);
                                        }}
                                    />
                                    <h4 className="box-title">Location C</h4>
                                    <p>Last sync: <span>10/10/21 12:00:00</span></p>
                                    <div className={`key-info ${!LocationCisActive ? 'not-visible': ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon key-icon">
                                            <path  d="M.152 9.585a4.412 4.412 0 007.538 3.122 4.405 4.405 0 001.293-3.122c0-.745-.188-1.475-.544-2.125l5.286-5.281a.433.433 0 00.085-.484.309.309 0 00-.069-.099L12.558.412a.336.336 0 00-.1-.068.43.43 0 00-.483.085l-.732.732L10.19.108a.337.337 0 00-.099-.069.43.43 0 00-.484.085L8.44 1.291a.338.338 0 00-.068.1.43.43 0 00.085.483l1.037 1.037-2.8 2.8a4.408 4.408 0 00-2.125-.543A4.412 4.412 0 00.152 9.585zm2.868-1.55a2.177 2.177 0 011.55-.64c.583 0 1.134.227 1.548.64.413.414.643.965.643 1.55 0 .586-.228 1.135-.641 1.548a2.178 2.178 0 01-1.55.642 2.177 2.177 0 01-1.548-.642 2.177 2.177 0 01-.641-1.548 2.155 2.155 0 01.64-1.55z"/>
                                        </svg>
                                        <span className="lbl-text">Key</span>
                                        <button className="btn small-ico-btn copy-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon">
                                            <defs/>
                                            <g  clip-path="url(#clip0)">
                                                <path d="M2.333 3.938a2.774 2.774 0 012.77-2.771h5.328A1.6 1.6 0 008.896 0H2.187C1.302 0 .583.72.583 1.604v9.042c0 .885.72 1.604 1.604 1.604h.146V3.937z"/>
                                                <path d="M11.813 2.333H5.104c-.885 0-1.604.72-1.604 1.604v8.459C3.5 13.28 4.22 14 5.104 14h6.708c.885 0 1.605-.72 1.605-1.604V3.937c0-.885-.72-1.604-1.604-1.604zm-1.167 9.917H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.041H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875z"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                <path  d="M0 0h14v14H0z"/>
                                                </clipPath>
                                            </defs>
                                            </svg>
                                        </button>
                                        <button className="btn small-ico-btn info-btn" onClick={ () => SetLocationKeyInfoModel(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon tooltip-icon">
                                                <path d="M6.023 0C2.728 0 0 2.681 0 5.977 0 9.272 2.728 12 6.023 12 9.32 12 12 9.272 12 5.977A5.983 5.983 0 006.023 0zm.704 9.188a.704.704 0 01-1.407 0V5.624a.704.704 0 011.407 0v3.563zm-.704-5.672a.704.704 0 11.002-1.408.704.704 0 01-.002 1.408z"/>
                                            </svg>
                                        </button>
                                    </div> 
                                    <p className="location-info">
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 12 16" className="info-icon">
                                            <path  d="M6 0c3.308 0 6 2.71 6 6.043 0 4.736-5.436 9.625-5.667 9.83a.5.5 0 01-.666.001C5.436 15.668 0 10.779 0 6.044 0 2.71 2.692 0 6 0zm0 9.333A3.337 3.337 0 009.333 6 3.337 3.337 0 006 2.667 3.337 3.337 0 002.667 6 3.337 3.337 0 006 9.333z"/>
                                        </svg>
                                        2824  Pinewood Avenue, Powers, New York, 49874.
                                    </p>   
                                    <div className="btn-box">
                                        <Link exact to="/opendental/manage" className={`btn btn-block app-btn text-uppercase ${!LocationCisActive ? 'lightgray-btn  disabled': ''}`}>
                                            connect
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                                <div className="app-box">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch4"
                                        className="app-switch"
                                        defaultChecked={LocationDisActive}
                                        onChange={e => {
                                            if(!LocationDisActive){
                                                SetNewLocationEnabledModel(true);
                                            }
                                            SetLocationDState(e.target.checked);
                                          }}
                                    />
                                    <h4 className="box-title">Location D</h4>
                                    <p>Last sync: <span>10/10/21 12:00:00</span></p>
                                    <div className={`key-info ${!LocationDisActive ? 'not-visible': ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon key-icon">
                                            <path  d="M.152 9.585a4.412 4.412 0 007.538 3.122 4.405 4.405 0 001.293-3.122c0-.745-.188-1.475-.544-2.125l5.286-5.281a.433.433 0 00.085-.484.309.309 0 00-.069-.099L12.558.412a.336.336 0 00-.1-.068.43.43 0 00-.483.085l-.732.732L10.19.108a.337.337 0 00-.099-.069.43.43 0 00-.484.085L8.44 1.291a.338.338 0 00-.068.1.43.43 0 00.085.483l1.037 1.037-2.8 2.8a4.408 4.408 0 00-2.125-.543A4.412 4.412 0 00.152 9.585zm2.868-1.55a2.177 2.177 0 011.55-.64c.583 0 1.134.227 1.548.64.413.414.643.965.643 1.55 0 .586-.228 1.135-.641 1.548a2.178 2.178 0 01-1.55.642 2.177 2.177 0 01-1.548-.642 2.177 2.177 0 01-.641-1.548 2.155 2.155 0 01.64-1.55z"/>
                                        </svg>
                                        <span className="lbl-text">Key</span>
                                        <button className="btn small-ico-btn copy-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon">
                                            <defs/>
                                            <g  clip-path="url(#clip0)">
                                                <path d="M2.333 3.938a2.774 2.774 0 012.77-2.771h5.328A1.6 1.6 0 008.896 0H2.187C1.302 0 .583.72.583 1.604v9.042c0 .885.72 1.604 1.604 1.604h.146V3.937z"/>
                                                <path d="M11.813 2.333H5.104c-.885 0-1.604.72-1.604 1.604v8.459C3.5 13.28 4.22 14 5.104 14h6.708c.885 0 1.605-.72 1.605-1.604V3.937c0-.885-.72-1.604-1.604-1.604zm-1.167 9.917H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.041H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875z"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                <path  d="M0 0h14v14H0z"/>
                                                </clipPath>
                                            </defs>
                                            </svg>
                                        </button>
                                        <button className="btn small-ico-btn info-btn" onClick={ () => SetLocationKeyInfoModel(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon tooltip-icon">
                                                <path d="M6.023 0C2.728 0 0 2.681 0 5.977 0 9.272 2.728 12 6.023 12 9.32 12 12 9.272 12 5.977A5.983 5.983 0 006.023 0zm.704 9.188a.704.704 0 01-1.407 0V5.624a.704.704 0 011.407 0v3.563zm-.704-5.672a.704.704 0 11.002-1.408.704.704 0 01-.002 1.408z"/>
                                            </svg>
                                        </button>
                                    </div> 
                                    <p className="location-info">
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 12 16" className="info-icon">
                                            <path  d="M6 0c3.308 0 6 2.71 6 6.043 0 4.736-5.436 9.625-5.667 9.83a.5.5 0 01-.666.001C5.436 15.668 0 10.779 0 6.044 0 2.71 2.692 0 6 0zm0 9.333A3.337 3.337 0 009.333 6 3.337 3.337 0 006 2.667 3.337 3.337 0 002.667 6 3.337 3.337 0 006 9.333z"/>
                                        </svg>
                                        2824  Pinewood Avenue, Powers, New York, 49874.
                                    </p>   
                                    <div className="btn-box">
                                        <Link exact to="/opendental/manage" className={`btn btn-block app-btn text-uppercase ${!LocationDisActive ? 'lightgray-btn  disabled': ''}`}>
                                            connect
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                                <div className="app-box">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch5"
                                        className="app-switch"
                                        defaultChecked={LocationEisActive}
                                        onChange={e => {
                                            if(!LocationEisActive){
                                                SetNewLocationEnabledModel(true);
                                            }
                                            SetLocationEState(e.target.checked);
                                          }}
                                    />
                                    <h4 className="box-title">Location E</h4>
                                    <p>Last sync: <span>10/10/21 12:00:00</span></p>
                                    <div className={`key-info ${!LocationEisActive ? 'not-visible': ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon key-icon">
                                            <path  d="M.152 9.585a4.412 4.412 0 007.538 3.122 4.405 4.405 0 001.293-3.122c0-.745-.188-1.475-.544-2.125l5.286-5.281a.433.433 0 00.085-.484.309.309 0 00-.069-.099L12.558.412a.336.336 0 00-.1-.068.43.43 0 00-.483.085l-.732.732L10.19.108a.337.337 0 00-.099-.069.43.43 0 00-.484.085L8.44 1.291a.338.338 0 00-.068.1.43.43 0 00.085.483l1.037 1.037-2.8 2.8a4.408 4.408 0 00-2.125-.543A4.412 4.412 0 00.152 9.585zm2.868-1.55a2.177 2.177 0 011.55-.64c.583 0 1.134.227 1.548.64.413.414.643.965.643 1.55 0 .586-.228 1.135-.641 1.548a2.178 2.178 0 01-1.55.642 2.177 2.177 0 01-1.548-.642 2.177 2.177 0 01-.641-1.548 2.155 2.155 0 01.64-1.55z"/>
                                        </svg>
                                        <span className="lbl-text">Key</span>
                                        <button className="btn small-ico-btn copy-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon">
                                            <defs/>
                                            <g  clip-path="url(#clip0)">
                                                <path d="M2.333 3.938a2.774 2.774 0 012.77-2.771h5.328A1.6 1.6 0 008.896 0H2.187C1.302 0 .583.72.583 1.604v9.042c0 .885.72 1.604 1.604 1.604h.146V3.937z"/>
                                                <path d="M11.813 2.333H5.104c-.885 0-1.604.72-1.604 1.604v8.459C3.5 13.28 4.22 14 5.104 14h6.708c.885 0 1.605-.72 1.605-1.604V3.937c0-.885-.72-1.604-1.604-1.604zm-1.167 9.917H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.041H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875z"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                <path  d="M0 0h14v14H0z"/>
                                                </clipPath>
                                            </defs>
                                            </svg>
                                        </button>
                                        <button className="btn small-ico-btn info-btn" onClick={ () => SetLocationKeyInfoModel(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon tooltip-icon">
                                                <path d="M6.023 0C2.728 0 0 2.681 0 5.977 0 9.272 2.728 12 6.023 12 9.32 12 12 9.272 12 5.977A5.983 5.983 0 006.023 0zm.704 9.188a.704.704 0 01-1.407 0V5.624a.704.704 0 011.407 0v3.563zm-.704-5.672a.704.704 0 11.002-1.408.704.704 0 01-.002 1.408z"/>
                                            </svg>
                                        </button>
                                    </div> 
                                    <p className="location-info">
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 12 16" className="info-icon">
                                            <path  d="M6 0c3.308 0 6 2.71 6 6.043 0 4.736-5.436 9.625-5.667 9.83a.5.5 0 01-.666.001C5.436 15.668 0 10.779 0 6.044 0 2.71 2.692 0 6 0zm0 9.333A3.337 3.337 0 009.333 6 3.337 3.337 0 006 2.667 3.337 3.337 0 002.667 6 3.337 3.337 0 006 9.333z"/>
                                        </svg>
                                        2824  Pinewood Avenue, Powers, New York, 49874.
                                    </p>   
                                    <div className="btn-box">
                                        <Link exact to="/opendental/manage" className={`btn btn-block app-btn text-uppercase ${!LocationEisActive ? 'lightgray-btn  disabled': ''}`}>
                                            connect
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                                <div className="app-box">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch6"
                                        className="app-switch"
                                        defaultChecked={LocationFisActive}
                                        onChange={e => {
                                            if(!LocationFisActive){
                                                SetNewLocationEnabledModel(true);
                                            }
                                            SetLocationFState(e.target.checked);
                                          }}
                                    />
                                    <h4 className="box-title">Location F</h4>
                                    <p>Last sync: <span>10/10/21 12:00:00</span></p>
                                    <div className={`key-info ${!LocationFisActive ? 'not-visible': ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon key-icon">
                                            <path  d="M.152 9.585a4.412 4.412 0 007.538 3.122 4.405 4.405 0 001.293-3.122c0-.745-.188-1.475-.544-2.125l5.286-5.281a.433.433 0 00.085-.484.309.309 0 00-.069-.099L12.558.412a.336.336 0 00-.1-.068.43.43 0 00-.483.085l-.732.732L10.19.108a.337.337 0 00-.099-.069.43.43 0 00-.484.085L8.44 1.291a.338.338 0 00-.068.1.43.43 0 00.085.483l1.037 1.037-2.8 2.8a4.408 4.408 0 00-2.125-.543A4.412 4.412 0 00.152 9.585zm2.868-1.55a2.177 2.177 0 011.55-.64c.583 0 1.134.227 1.548.64.413.414.643.965.643 1.55 0 .586-.228 1.135-.641 1.548a2.178 2.178 0 01-1.55.642 2.177 2.177 0 01-1.548-.642 2.177 2.177 0 01-.641-1.548 2.155 2.155 0 01.64-1.55z"/>
                                        </svg>
                                        <span className="lbl-text">Key</span>
                                        <button className="btn small-ico-btn copy-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon">
                                            <defs/>
                                            <g  clip-path="url(#clip0)">
                                                <path d="M2.333 3.938a2.774 2.774 0 012.77-2.771h5.328A1.6 1.6 0 008.896 0H2.187C1.302 0 .583.72.583 1.604v9.042c0 .885.72 1.604 1.604 1.604h.146V3.937z"/>
                                                <path d="M11.813 2.333H5.104c-.885 0-1.604.72-1.604 1.604v8.459C3.5 13.28 4.22 14 5.104 14h6.708c.885 0 1.605-.72 1.605-1.604V3.937c0-.885-.72-1.604-1.604-1.604zm-1.167 9.917H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.041H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875z"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                <path  d="M0 0h14v14H0z"/>
                                                </clipPath>
                                            </defs>
                                            </svg>
                                        </button>
                                        <button className="btn small-ico-btn info-btn" onClick={ () => SetLocationKeyInfoModel(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon tooltip-icon">
                                                <path d="M6.023 0C2.728 0 0 2.681 0 5.977 0 9.272 2.728 12 6.023 12 9.32 12 12 9.272 12 5.977A5.983 5.983 0 006.023 0zm.704 9.188a.704.704 0 01-1.407 0V5.624a.704.704 0 011.407 0v3.563zm-.704-5.672a.704.704 0 11.002-1.408.704.704 0 01-.002 1.408z"/>
                                            </svg>
                                        </button>
                                    </div> 
                                    <p className="location-info">
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 12 16" className="info-icon">
                                            <path  d="M6 0c3.308 0 6 2.71 6 6.043 0 4.736-5.436 9.625-5.667 9.83a.5.5 0 01-.666.001C5.436 15.668 0 10.779 0 6.044 0 2.71 2.692 0 6 0zm0 9.333A3.337 3.337 0 009.333 6 3.337 3.337 0 006 2.667 3.337 3.337 0 002.667 6 3.337 3.337 0 006 9.333z"/>
                                        </svg>
                                        2824  Pinewood Avenue, Powers, New York, 49874.
                                    </p>   
                                    <div className="btn-box">
                                        <Link exact to="/opendental/manage" className={`btn btn-block app-btn text-uppercase ${!LocationFisActive ? 'lightgray-btn  disabled': ''}`}>
                                            connect
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="box-col col-xs-12 col-sm-6 col-lg-6 col-xl-3">
                                <div className="app-box">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch7"
                                        className="app-switch"
                                        defaultChecked={LocationGisActive}
                                        onChange={e => {
                                            if(!LocationGisActive){
                                                SetNewLocationEnabledModel(true);
                                            }
                                            SetLocationGState(e.target.checked);
                                          }}
                                    />
                                    <h4 className="box-title">Location G</h4>
                                    <p>Last sync: <span>10/10/21 12:00:00</span></p>
                                    <div className={`key-info ${!LocationGisActive ? 'not-visible': ''}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon key-icon">
                                            <path  d="M.152 9.585a4.412 4.412 0 007.538 3.122 4.405 4.405 0 001.293-3.122c0-.745-.188-1.475-.544-2.125l5.286-5.281a.433.433 0 00.085-.484.309.309 0 00-.069-.099L12.558.412a.336.336 0 00-.1-.068.43.43 0 00-.483.085l-.732.732L10.19.108a.337.337 0 00-.099-.069.43.43 0 00-.484.085L8.44 1.291a.338.338 0 00-.068.1.43.43 0 00.085.483l1.037 1.037-2.8 2.8a4.408 4.408 0 00-2.125-.543A4.412 4.412 0 00.152 9.585zm2.868-1.55a2.177 2.177 0 011.55-.64c.583 0 1.134.227 1.548.64.413.414.643.965.643 1.55 0 .586-.228 1.135-.641 1.548a2.178 2.178 0 01-1.55.642 2.177 2.177 0 01-1.548-.642 2.177 2.177 0 01-.641-1.548 2.155 2.155 0 01.64-1.55z"/>
                                        </svg>
                                        <span className="lbl-text">Key</span>
                                        <button className="btn small-ico-btn copy-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon">
                                            <defs/>
                                            <g  clip-path="url(#clip0)">
                                                <path d="M2.333 3.938a2.774 2.774 0 012.77-2.771h5.328A1.6 1.6 0 008.896 0H2.187C1.302 0 .583.72.583 1.604v9.042c0 .885.72 1.604 1.604 1.604h.146V3.937z"/>
                                                <path d="M11.813 2.333H5.104c-.885 0-1.604.72-1.604 1.604v8.459C3.5 13.28 4.22 14 5.104 14h6.708c.885 0 1.605-.72 1.605-1.604V3.937c0-.885-.72-1.604-1.604-1.604zm-1.167 9.917H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.041H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875z"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                <path  d="M0 0h14v14H0z"/>
                                                </clipPath>
                                            </defs>
                                            </svg>
                                        </button>
                                        <button className="btn small-ico-btn info-btn" onClick={ () => SetLocationKeyInfoModel(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" className="info-icon tooltip-icon">
                                                <path d="M6.023 0C2.728 0 0 2.681 0 5.977 0 9.272 2.728 12 6.023 12 9.32 12 12 9.272 12 5.977A5.983 5.983 0 006.023 0zm.704 9.188a.704.704 0 01-1.407 0V5.624a.704.704 0 011.407 0v3.563zm-.704-5.672a.704.704 0 11.002-1.408.704.704 0 01-.002 1.408z"/>
                                            </svg>
                                        </button>
                                    </div> 
                                    <p className="location-info">
                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 12 16" className="info-icon">
                                            <path  d="M6 0c3.308 0 6 2.71 6 6.043 0 4.736-5.436 9.625-5.667 9.83a.5.5 0 01-.666.001C5.436 15.668 0 10.779 0 6.044 0 2.71 2.692 0 6 0zm0 9.333A3.337 3.337 0 009.333 6 3.337 3.337 0 006 2.667 3.337 3.337 0 002.667 6 3.337 3.337 0 006 9.333z"/>
                                        </svg>
                                        2824  Pinewood Avenue, Powers, New York, 49874.
                                    </p>   
                                    <div className="btn-box">
                                        <Link exact to="/opendental/manage" className={`btn btn-block app-btn text-uppercase ${!LocationGisActive ? 'lightgray-btn  disabled': ''}`}>
                                            connect
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                        </div>
                        <div className="row app-box-wrapper mt-2">
                            <div className="box-col col-xs-12 col-sm-12 col-lg-12 col-xl-9">
                                <div className="app-box info-box">
                                    <div className="left-box">
                                        <h2 className="info-title">Pay for an expert! </h2>
                                        <p>Need a little assistance with installing the integration tool? No problem! We have experts who can save your time, and get you up and going quickly!</p>
                                    </div>
                                    <div className="right-box">
                                        <div className="amt-info">
                                            <img src={NoteEditIcon} alt="Icon" />
                                            <strong>$149</strong>
                                            <span>One time</span>
                                        </div>
                                        <div className="btn-box">
                                            <button className="btn app-btn small-btn text-uppercase">
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-col col-xs-12 col-sm-12 col-lg-12 col-xl-3">
                                <div className="hippa-img-box d-flex align-items-center justify-content-center">
                                    <img src={HIPPAlogo} alt="HIPPA Logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </main>
            <AddLocationModel show={AddLocationModelShow} onHide={() => SetAddLocationModel(false)} />
            <NewLocationEnabledModel show={NewLocationEnabledModelShow} onHide={() => SetNewLocationEnabledModel(false)} />
            <LocationKeyInfoModel show={LocationKeyInfoModelShow} onHide={() => SetLocationKeyInfoModel(false)} />
        </div>
    );
}