import React, { useLayoutEffect } from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import "./App.scss";
// component imports
import IntegrationDirectoryPage from "./app-pages/IntegrationDirectoryPage";
import HighlevelPage from "./app-pages/HighlevelPage";
import IntegrationLocationPage from "./app-pages/IntegrationLocationPage";
import ManageConnectionPage from "./app-pages/ManageConnectionPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import SignInPage from "../src/auth-pages/SignInPage";
import SignUpPage from "../src/auth-pages/SignUpPage/index";
import ResetPasswordPage from "../src/auth-pages/ResetPasswordPage";
import ForgotPasswordPage from "../src/auth-pages/ForgotPasswordPage";
import ErrorPage from "./app-pages/ErrorPage";
import AccountSetup from "./auth-pages/AccountSetup";
import AccountSetting from "./AccountSettings/AccountSetting";
import OpendentalLocation from "../src/app-pages/OpendentalLocation";
import CurveDentelLocation from "../src/app-pages/CurveDentelLocation";
import TDOLocation from "../src/app-pages/TDOLocation";
import InFusionSoftLocation from "../src/app-pages/InFusionSoftLocation";
import TeamMember from "./TeamMembers/TeamMember";
import Location from "./Locations/Locations";

import { ToastContainer } from "react-toastify";

import Api from "./API/login";
import "react-toastify/dist/ReactToastify.min.css";

import AuthKey from "./auth-pages/AuthKey";

import { domain, ProductName, publicUrl, url_link } from "./config/appConstatnts";
import DownloadFiles from "./DownloadFile/DownloadFiles";
// import OtherCrmMappingPage from "./OtherCrm/OtherCrmMappingPage";
import CustomeLoader from "./common-components/CustomeLoader";
import OtherCrmMappingPage from "./OtherCrm/CRMMappingPage";
//import { goToSignin } from "./../src/config/commonFun";

const App = () => {
  //const history = useHistory() 
  const [appLoaderState, setappLoaderState] = React.useState(true);

  const appThemeColor = (url, companyId) => {
    document.getElementById("favicon").href = `${url}/${companyId}/images/Favicon.png`;
    document.getElementById("custom-style").href = `${url}/${companyId}/css/style.css`;
    document.getElementById("flag-style").href = `${url}/${companyId}/css/flag.css`;
    document.getElementById(
      "custom-responsive-style"
    ).href = `${url}/${companyId}/css/responsive.css`;
  };

//   useEffect(() => {
//     return history.listen((location) => { 
//       SessionTimeout();
//     }) 
//  },[history,""]); 

//  const SessionTimeout = () =>
//  {
//     if(new Date(localStorage.getItem("SessionTimeout")) < new Date())
//     {
//       localStorage.removeItem("token");
//       localStorage.removeItem("userData");
//       localStorage.removeItem("sectionList");
//       localStorage.removeItem("SessionTimeout");

//       setTimeout(() => {
//         goToSignin();
//       }, 2000);
//     }
//  }

  useLayoutEffect(() => {
    //SessionTimeout();
    if (localStorage.getItem("userData") === null) {
      window.localStorage.clear();
    }
    if (
      JSON.parse(localStorage.getItem("CompanyId")) == null ||
      JSON.parse(localStorage.getItem("CompanyDetails")) == null ||
      (JSON.parse(localStorage.getItem("CompanyDetails")) &&
        domain !== JSON.parse(localStorage.getItem("CompanyDetails")).DomainName)
    ) {
      Api.getCompanyDetail(domain)
        .then((res) => {
          localStorage.setItem("CompanyId", res.data.Result.CompanyId);
          localStorage.setItem("CompanyDetails", JSON.stringify(res.data.Result));
          document.title = res.data.Result.CompanyName;
          appThemeColor(url_link, res.data.Result.CompanyId);
          document.querySelector("#custom-style").onload = () => {
            setappLoaderState(false);
          };
          return res.data.CompanId;
        })
        .catch((error) => {
          setappLoaderState(false);
          return error;
        });
    } else {
      let companyId = localStorage.getItem("CompanyId");
      document.title = ProductName.CompanyName;
      appThemeColor(publicUrl, companyId);
      document.querySelector("#custom-style").onload = () => {
        setappLoaderState(false);
      };
      return setappLoaderState;
    }
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") != null && localStorage.getItem("token").length > 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );

  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") == null || localStorage.getItem("token").length === 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/setting/profile" />
        )
      }
    />
  );

  return (
    <>
      {appLoaderState && <CustomeLoader />}
      {!appLoaderState && (
        <Switch>
          <PublicRoute path="/" component={SignInPage} exact />
          <PublicRoute path="/signin" component={SignInPage} exact />
          <PublicRoute path="/signup" component={SignUpPage} exact />
          <Route path="/authKey/:token" component={AuthKey} exact />
          <PrivateRoute path="/setting/accountsetting" component={AccountSetting} exact />
          <PrivateRoute path="/setting/teammember" component={TeamMember} exact />
          <PrivateRoute path="/setting/location" component={Location} exact />
          <PublicRoute path="/resetpassword" component={ResetPasswordPage} exact />
          <PublicRoute path="/forgot-password" component={ForgotPasswordPage} exact />
          <PrivateRoute exact path="/setting/integration" component={IntegrationDirectoryPage} />
          <PrivateRoute
            exact
            path="/integration/location/:id/:name"
            component={IntegrationLocationPage}
          />

          <PrivateRoute
            exact
            path="/integration/location/:id/:name/:location"
            component={OtherCrmMappingPage}
          />

          <PrivateRoute exact path="/highlevel" component={HighlevelPage} />

          <PublicRoute path="/activation" component={AccountSetup} exact />
          <PrivateRoute exact path="/opendentallocation" component={OpendentalLocation} />

          <PrivateRoute exact path="/curvedentellocation" component={CurveDentelLocation} />
          <PrivateRoute exact path="/tdolocation" component={TDOLocation} />
          <PrivateRoute exact path="/infusionsoftlocation" component={InFusionSoftLocation} />

          <PrivateRoute exact path="/:id/manage" component={ManageConnectionPage} />
          <PrivateRoute exact path="/setting/profile" component={ProfilePage} />
          <PrivateRoute exact path="/setting/download-file" component={DownloadFiles} />
          <Route component={ErrorPage} />
        </Switch>
      )}
      <ToastContainer hideProgressBar />
    </>
  );
};
export default App;
