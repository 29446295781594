import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { toast } from "react-toastify";
import { components, default as ReactSelect } from "react-select";
import {
    CommonNotificationBlue,
    CommonNotificationGreen,
    ErrorMessage,
    SaveButton,
    SectionWithCheckbox,
} from "../OtherCrmCommonComponente/OtherCrmCommonComponents";
import Api from "../../API/go-high-level";

const SyncBlockout = ({ IsAgencyKeyError,
    credentials,
    LocationSyncId,
    IsSyncBlockoutType,
    blockoutToggle,
    BlockoutTypeList,
    MappedConnectors,
    MappedOpenDental }) => {

    const [IsSyncBlockoutTypes, setIsSyncBlockoutType] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [allOptions, setallOptions] = useState([]);
    const [IsOpenDental, setOpenDental] = useState(false);

    const handleChangeBlockoutType = (e) => {
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
      };
    
    let options = BlockoutTypeList.map(({ Id, Name, IsSelected }, index) => ({
        value: Id,
        label: Name,
        IsSelected : IsSelected,
        Index : index
    }));

    useEffect(() => {
        setIsSyncBlockoutType(IsSyncBlockoutType);
        //setOpenDental(MappedConnectors.filter(x => x.IntegrationId == 5).length > 0);
        const arrayUniqueByKey = [...new Map(options.map((item) => [item["value"], item])).values()];
        setallOptions(arrayUniqueByKey);
        setSelectedValue(options.filter(function(item) {return item.IsSelected == true}).map(x => x.value));
    }, [IsSyncBlockoutType]);

    useEffect(() => {
        setOpenDental(MappedOpenDental);        
    }, [MappedOpenDental]);

    // handle submit omv==========
    const handleSubmit = () => {
        
        const payLoadData = {
            LocationId: credentials.locationId,
            BlockoutTypeIds: selectedValue != null && selectedValue.length > 0 ? selectedValue.join() : BlockoutTypeList.map(x => x.Id).join(),
            IsSelectAll : selectedValue.length == BlockoutTypeList.length ? true : false
        };
        Api.UpdateBlockoutType(payLoadData)
        .then((resp) => {
            toast.success("Blockout types are saved successfully.");
        })
        .catch((err) => toast.error("Error in Sending data"));
    };


    return (        
        <>
            {IsOpenDental ? <>
            <SectionWithCheckbox
                className={"mt-1"}
                title={"Sync Blockout "}
                formDisable={IsAgencyKeyError}
                onChangeCheckbox={blockoutToggle}
                checked={IsSyncBlockoutTypes}
                switchId={"custom-switch-blockout"}>
                <p><b>Note :</b> Only available with Opendental at this time. If the toggle button is turned OFF / remove all the type from dropdown then no any blockout will transfer to the GHL. </p>

            </SectionWithCheckbox>

            <Collapse className="panel-accordion additionalClassForContent" isOpen={IsSyncBlockoutTypes}>
                <div className="collapse-content-scroll">
                    {LocationSyncId === "1" || BlockoutTypeList.length === 0 ? (
                        <div style={{ marginLeft: ".5rem" }}>
                            <h5>
                                There is no Blockout Types available for this location.
                            </h5>
                        </div>
                    ) : (LocationSyncId &&
                        (
                            <form> 
                                <p className="pl-2 icon-label">Select Blockout Type</p>
                                <div className="w-100 multiselect-row pb-0 mb-3 ">
                                    <div className={"multiselect-col-1"}>
                                        <ReactSelect
                                            placeholder={"Select Blockout Type"}
                                            className="my-2"
                                            options={allOptions}
                                            name="BlockoutType"
                                            value={allOptions.filter(obj => selectedValue.includes(obj.value))}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{
                                                Option
                                              }}
                                            onChange={(e) => handleChangeBlockoutType(e)}
                                            allowSelectAll={false}
                                            isMulti={true}
                                        />
                                    </div>
                                    <SaveButton SaveBtnOnClick={handleSubmit} />
                                </div>
                            </form>
                        ))}
                </div>
            </Collapse>
            </> : <></>}
        </>
    );

};

const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} /> <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

export default SyncBlockout;