import React from "react";

const EducationAndTraining = ({ userInfo, oneducationLink }) => {
  return (
    <div className="inner-container mb-4 education-training-box">
      <div className="edu-section">
        <div className="sec-header mb-2">
          <h4 className="mr-auto  mb-0">Education And Training</h4>
          <button
            className="app-btn app-btn-clear sec-action-btn "
            onClick={() => oneducationLink(0)}
          >
            <i className="fa fa-plus"></i>
            Add
          </button>
        </div>

        <div className="user-basic-info ">
          <div className="basic-info-scroll-wrapper mb-0">
            {userInfo.lstEducationandTraining &&
              userInfo.lstEducationandTraining.length > 0 &&
              userInfo.lstEducationandTraining.map((edu, index) => (
                <div className="inner-card" key={`lstEducationandTraining-${index}`}>
                  <div className="d-flex justify-content-between">
                    <button
                      className="app-btn app-btn-clear action-btn"
                      onClick={() => oneducationLink(edu.Id)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17">
                        <path
                          fill="#707070"
                          d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="info-row">
                    <span>Institution Attended</span>
                    <label>{edu.Institute}</label>
                  </div>
                  <div className="info-row">
                    <span>Field of Study/Degree</span>
                    <label> {edu.Specialisation} </label>
                  </div>
                  <div className="info-row">
                    <span>Last Year Attended/ Graduated</span>
                    <label> {edu.YearAttended} </label>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationAndTraining;
