const NoDataTable = ({ colSpan, text }) => {
  return (
    <div className="app-table">
      <div className="table-responsive">
        <table className="table table-hover table-borderless invoice-grid">
          <tbody>
            <tr>
              <td colSpan={colSpan} className="text-center">
                {text}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NoDataTable;
