import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
//component import
import Api from "../API/integration";

const LocationKeyInfoModel = (props) => {
  const [keyObject, setKeyObject] = useState({
    LocationId: 0,
    APIKey: "",
    Id: 0,
  });

  const handelChange = (event) => {
    let obj = keyObject;
    obj.LocationId = props.location.LocationId;
    obj.APIKey = event.target.value;
    obj.Id = props.location.DentrixConnectorId;
    setKeyObject(obj);
  };

  const updateKey = (event) => {
    event.preventDefault();
    if (keyObject.APIKey.length > 0 && keyObject.APIKey != null) {
      Api.UpdateGoHighLevelKey(keyObject)
        .then((resp) => {
          toast.success("Key updated.");
          props.onHide();
          props.reloadLocation();
        })
        .catch((e) => {
          toast.error("Something went wrong.");
        });
    } else {
      toast.error("Please add key");
    }
  };

  function coppyMessage() {
    toast.info("Key copied.", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model integration-modal locationInfo-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
            {props.location && props.location.LocationName ? props.location.LocationName : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className="col-12 locationInfo-col">
                <div className="form-group location-info">
                  <p className="label-text"> Your Key for the Location: </p>
                  <p className="location-text">
                    {props.location && props.location.Address ? props.location.Address : ""}
                  </p>
                </div>
              </div>
              <div className="col-12 locationInfo-col">
                <div className="form-group">
                  <p class="icon-label">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <defs />
                      <path
                        fill="#0A2753"
                        d="M.152 9.585a4.412 4.412 0 007.538 3.122 4.405 4.405 0 001.292-3.122c0-.745-.187-1.475-.544-2.125l5.286-5.281a.433.433 0 00.086-.484.309.309 0 00-.07-.099L12.558.412a.336.336 0 00-.099-.068.43.43 0 00-.484.085l-.731.732L10.19.108a.339.339 0 00-.1-.069.43.43 0 00-.483.085L8.44 1.291a.338.338 0 00-.069.1.43.43 0 00.086.483l1.036 1.037-2.8 2.8a4.408 4.408 0 00-2.124-.543A4.412 4.412 0 00.152 9.585zm2.868-1.55a2.177 2.177 0 011.549-.64c.584 0 1.135.227 1.548.64.414.414.643.965.643 1.55 0 .586-.227 1.135-.64 1.548a2.178 2.178 0 01-1.55.642 2.177 2.177 0 01-1.549-.642 2.177 2.177 0 01-.64-1.548 2.155 2.155 0 01.639-1.55z"
                      />
                    </svg>
                    Key
                  </p>
                  <div className="info-ctrl mb-3">
                    <input
                      type="text"
                      onChange={handelChange}
                      className="form-control"
                      placeholder="Agency Key"
                      defaultValue={
                        props.location && props.location.AccountKey ? props.location.AccountKey : ""
                      }
                    />
                    <CopyToClipboard
                      text={
                        props.location && props.location.AccountKey ? props.location.AccountKey : ""
                      }
                    >
                      <button
                        className="btn small-ico-btn copy-btn"
                        type="button"
                        onClick={() => coppyMessage()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 14 14"
                          className="info-icon"
                        >
                          <defs />
                          <g clip-path="url(#clip0)">
                            <path d="M2.333 3.938a2.774 2.774 0 012.77-2.771h5.328A1.6 1.6 0 008.896 0H2.187C1.302 0 .583.72.583 1.604v9.042c0 .885.72 1.604 1.604 1.604h.146V3.937z" />
                            <path d="M11.813 2.333H5.104c-.885 0-1.604.72-1.604 1.604v8.459C3.5 13.28 4.22 14 5.104 14h6.708c.885 0 1.605-.72 1.605-1.604V3.937c0-.885-.72-1.604-1.604-1.604zm-1.167 9.917H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.041H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875zm0-2.334H6.27a.438.438 0 010-.875h4.375a.438.438 0 010 .875z" />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <path d="M0 0h14v14H0z" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box d-flex justify-content-center pb-0 pt-4">
              {props.intGrId == 14 ? (
                <button type="button" className="btn app-btn large-btn w-100" onClick={updateKey}>
                  Update
                </button>
              ) : (
                <button
                  type="button"
                  className="btn app-btn large-btn w-100"
                  onClick={props.onHide}
                >
                  ok
                </button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
};
export default LocationKeyInfoModel;
